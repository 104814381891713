// core action
import { signOut } from "actions/auth.jsx";
import { findAllNotifications } from "actions/notifications.jsx";
// core components
import AdminNavbar from "components/Shared/Navbar/Navbar.jsx";
import Sidebar from "components/Shared/Sidebar/Sidebar.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react library for routing
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
// routes
import AdminRoutes from "routes/routes.jsx";
// core selectors
import {
  getModulesUserAccess,
  getTeamsUserAccess,
} from "selectors/adminLayout.jsx";
import { getNotifications } from "selectors/notifications.jsx";
// nodejs library Sweetalert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signoutTime: 1000 * 60 * 15,
      sidenavOpen: window.innerWidth < 1200 ? true : false,
      routes: [],
      alert: null,
    };
  }

  componentDidMount = () => {
    document.body.classList.remove("g-sidenav-show");
    const {
      modules,
      // teams
    } = this.props;
    // this.props.findAllNotifications().then((res) => {
    //   if (res.payload.isAxiosError) {
    //     if (res.payload.response) {
    //       const { data: { payload } } = res.payload.response;
    //       this.notify("danger", "Falló", payload.message);
    //     } else {
    //       this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
    //     }
    //   }
    // });
    const allowedRoutes = [];
    for (const ele of modules) {
      const { routes } = ele;
      for (const element of routes) {
        for (const route of AdminRoutes) {
          if (route.collapse) {
            if (allowedRoutes.find((row) => row.name === route.name)) {
              for (const allow of allowedRoutes) {
                if (allow.collapse) {
                  if (allow.name === route.name) {
                    const { views } = route;
                    for (const view of views) {
                      if (
                        element.name === view.name &&
                        !allow.views.find((e) => e.name === view.name)
                      ) {
                        if (view.parent) {
                          const newRouteParent = { ...view };
                          newRouteParent.views = [];
                          for (const viewParent of view.views) {
                            let flag = false;
                            for (const module of modules) {
                              const routesNew = module.routes;
                              if (
                                routesNew.some(
                                  (ele) => viewParent.name === ele.name
                                )
                              ) {
                                flag = true;
                              }
                            }
                            if (flag) {
                              newRouteParent.views.push(viewParent);
                            }
                          }
                          if (
                            element.name === newRouteParent.name &&
                            !allow.views.find(
                              (e) => e.name === newRouteParent.name
                            )
                          ) {
                            allow.views.push(newRouteParent);
                          }
                        } else {
                          allow.views.push(view);
                        }
                      }
                    }
                  }
                }
              }
            } else {
              const { views } = route;
              const newRoute = { ...route };
              newRoute.views = [];
              for (const view of views) {
                if (element.name === view.name && !view.parent) {
                  newRoute.views.push(view);
                } else if (view.parent) {
                  const newRouteParent = { ...view };
                  newRouteParent.views = [];
                  for (const viewParent of view.views) {
                    if (element.name === viewParent.name) {
                      newRouteParent.views.push(viewParent);
                    }
                  }
                  if (newRouteParent.views.length) {
                    newRoute.views.push(newRouteParent);
                  }
                }
              }
              if (newRoute.views.length) {
                allowedRoutes.push(newRoute);
              }
            }
          } else {
            if (
              element.name === route.name &&
              !allowedRoutes.find((row) => row.name === route.name)
            ) {
              allowedRoutes.push(route);
            }
          }
        }
      }
    }
    this.setState({
      routes: allowedRoutes,
    });
  };

  componentDidMount() {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    // for (var i in this.events) {
    //   window.addEventListener(this.events[i], this.resetTimeout);
    // }

    // this.setTimeout();
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
    // if (!this.props.notifications.length) {
    //   this.props.findAllNotifications().then((res) => {
    //     if (res.payload.isAxiosError) {
    //       if (res.payload.response) {
    //         const { data: { payload } } = res.payload.response;
    //         this.notify("danger", "Falló", payload.message);
    //       } else {
    //         this.notify("danger", "Falló", "No se logro establecer conexion con el servidor.");
    //       }
    //     }
    //   });
    // }
  }

  clearTimeoutFunc = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  logout = () => {
    // Send a logout request to the API
    const Toast = MySwal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 15000,
      timerProgressBar: true,
    });
    Toast.fire({
      type: "warning",
      title: `¡Sesión cerrada por inactividad!`,
    });
    this.destroy();
  };

  destroy = () => {
    //clear the session
    localStorage.clear();
    this.props.history.push("/");
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < AdminRoutes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          AdminRoutes[i].layout + AdminRoutes[i].path
        ) !== -1
      ) {
        return AdminRoutes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (
      document.body.classList.contains("g-sidenav-pinned") ||
      this.state.sidenavOpen
    ) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/notifications/usability-record-se"
    ) === -1
      ? "dark"
      : "alternative";
  };

  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Funcion para ocultar el alert, donde setea el state
   * @memberof Resourses
   */
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleOnMyProfile = () => {
    this.props.history.push(`${this.props.match.url}/mis/profile`);
  };

  handleOnExtraHours = () => {
    this.props.history.push(`/admin/notifications/extra-hours-user`);
  };

  handleOnCOEMaintenance = () => {
    this.props.history.push(`/admin/coe/maintenance`);
  };

  handleOnRecordsMaintenance = () => {
    this.props.history.push(`/admin/medical-record/maintenance`);
  };

  handleOnMySignatures = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/notifications/my-signatures`);
  };

  handleOnMyTargetLetters = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/notifications/my-target-letters`);
  };

  handleOnUsabilityRecord = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/notifications/usability-record`);
  };

  handleOnPositionAdmin = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/new-position/maintenance`);
  };

  handleOnDigitalSignatureAdmin = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(
      `/admin/notifications/digital-signature-maintenance`
    );
  };

  handleOnExtraHoursAdmin = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/notifications/extra-hours-maintenance`);
  };

  handleOnKnowEmployee = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(
      `/admin/notifications/my-form-know-employee-complete`
    );
  };

  handleOnConflictInsterest = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/notifications/my-form-conflict-interest`);
  };
  handleOnAdminBS = () => {
    this.props.history.push(`/admin/business-system/maintenance`);
  };
  handleOnAdminExitInterview = () => {
    this.props.history.push(`/admin/exit-interview/maintenance`);
  };
  handleOnAdminSalaryApprovers = () => {
    this.props.history.push(`/admin/salary/others-approvers`);
  };
  handleOnSelectFilter = (row) => {
    const { type } = row;
    if (type === "vacant-position")
      this.props.history.push(`/admin/new-position/requests/work-flow`);
    else if (type === "extra-hours")
      this.props.history.push(`/admin/extra-hours/information`);
    else if (type === "inventory-price")
      this.props.history.push(`/admin/inventories-management/quotes`);
    else if (type === "exit-interview")
      this.props.history.push(`/admin/notifications/exit-interview-completion`);
    else if (type === "planners-flow")
      this.props.history.push(`/admin/planners/assignment-manager`);
    else
      this.props.history.push(`${this.props.match.url}/notifications/${type}`);
  };

  handleOnSignOut = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Está seguro de cerrar la sesión?"
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={() => {
            this.props.signOut();
            this.props.history.push("/");
            localStorage.clear();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, cerrar"
          cancelBtnText="No, continuar"
          btnSize="md"
        />
      ),
    });
  };

  handleOnCriticalPartsAdmin = () => {
    // console.log("Ver mis politicas");
    this.props.history.push(`/admin/parts-management/users-maintenance`);
  };

  handleOnReportHours = () => {
    this.props.history.push(`/admin/hours-report/home`);
  };

  render() {
    const { routes, alert } = this.state;

    const { notifications, teams } = this.props;

    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/admin",
            imgSrc: require("assets/img/icons/common/logoBlancoSS.png"),
            imgAlt: "Logo",
          }} 
        />
        <div
          className="main-content pb-6 header-background"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            isCEO={teams.some((row) => row === "CEO")}
            isCOEAdmin={teams.some((row) => row === "COE Admin")}
            isBSAdmin={teams.some((row) => row === "BS Admin")}
            isExitInterviewAdmin={teams.some(
              (row) => row === "ExitInterview Admin"
            )}
            isSalaryApprovalAdmin={teams.some(
              (row) => row === "HC Salary Approval Admin"
            )}
            knowEmployee={teams.some(
              (row) => row === "User Know Employee Form"
            )}
            isRecordsAdmin={teams.some((row) => row === "Medical Record Admin")}
            isSuperAdmin={teams.some((row) => row === "General MIS AM")}
            userWithLetters={teams.some((row) => row === "User With Letters")}
            userPositionsAdmin={teams.some(
              (row) => row === "New Position Admin"
            )}
            digitalSignatureAdmin={teams.some(
              (row) => row === "Digital Signature Admin"
            )}
            extraHoursAdmin={teams.some(
              (row) => row === "Extra Hours Managers"
            )}
            conflictInsterestForm={teams.some(
              (row) => row === "Conflict Interest Form"
            )}
            criticalPartsAdmin={teams.some(
              (row) => row === "CriticalParts Super Admin"
            )}
            theme={this.getNavbarTheme()}
            notifications={notifications}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
            onMyProfile={this.handleOnMyProfile}
            onExtraHours={this.handleOnExtraHours}
            onCOEMaintenance={this.handleOnCOEMaintenance}
            onRecordsMaintenance={this.handleOnRecordsMaintenance}
            onMySignatures={this.handleOnMySignatures}
            onTargetLetter={this.handleOnMyTargetLetters}
            onUsabilityRecord={this.handleOnUsabilityRecord}
            onPositionAdmin={this.handleOnPositionAdmin}
            onDigitalSignatureAdmin={this.handleOnDigitalSignatureAdmin}
            onExtraHoursAdmin={this.handleOnExtraHoursAdmin}
            onSelectNotification={this.handleOnSelectFilter}
            onSignOut={this.handleOnSignOut}
            onKnowEmployee={this.handleOnKnowEmployee}
            onConflictInsterest={this.handleOnConflictInsterest}
            onCriticalPartsAdmin={this.handleOnCriticalPartsAdmin}
            handleOnAdminBS={this.handleOnAdminBS}
            handleOnAdminExitInterview={this.handleOnAdminExitInterview}
            handleOnAdminSalaryApprovers={this.handleOnAdminSalaryApprovers}
            onReportHours={this.handleOnReportHours}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="/admin" to="/admin/notifications" />
          </Switch>
          {/* <Footer /> */}
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

Admin.defaultProps = {
  modules: [],
  teams: [],
  notifications: [],
};

Admin.propTypes = {
  modules: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  modules: getModulesUserAccess(),
  teams: getTeamsUserAccess(),
  notifications: getNotifications(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllNotifications,
    signOut,
  })(Admin)
);
