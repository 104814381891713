import React, { useState, useEffect } from 'react';
import FormUser from "components/UserCloudRequest/Forms";
import { Card, CardBody, Col, CardHeader, Button, Row, CardFooter } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const CancellationExternalRequest = ({ info, backPage, handleOnChangeInfo, createNewRequest, data }) => {
    const [alert, setAlert] = useState("");


    const itemsForms = [
        { label: "Cloud Vendor", type: "select", options: data.clouds, required: true, multi: true, colWidth: 4, value: "Cloud Vendor", valueInsert: 37 },
        { label: "País", type: "select", required: true, multi: false, colWidth: 4, value: "País", valueInsert: 38, options: data.countries },
        { label: "Nombre de la cuenta", type: "input", required: true, colWidth: 4, value: "Nombre de la cuenta", valueInsert: 39 },
        { label: "Numero de la cuenta de Tenant", type: "input", required: true, colWidthmd: 4, value: "Nombre de la cuenta de Tenant", valueInsert: 40 },
        { label: "Motivo de baja", type: "input", required: true, colWidth: 4, value: "Motivo de baja", valueInsert: 49 },
        { label: "Nombre del usuario", type: "input", required: true, colWidth: 4, value: "Nombre del usuario", valueInsert: 42 },
        // { label: "Rol del usuario en la empresa", type: "input", required: true, colWidth: 6, value: "Rol del usuario en la empresa", valueInsert: 43 },
        { label: "Correo electronico del usuario", type: "input", required: true, colWidth: 6, value: "Correo electronico del usuario", valueInsert: 44 },
        // { label: "Teléfono del usuario", type: "input", required: true, colWidth: 4, value: "Teléfono del usuario", valueInsert: 45 },
        // { label: "ZIP/Postal Code", type: "input", required: true, colWidth: 4, value: "ZIP/Postal Code", valueInsert: 46 },
        // { label: "Rol requerido", type: "textarea", required: true, colWidth: 4, value: "Rol requerido", valueInsert: 47 },
        // { label: "Comentarios", type: "textarea", required: true, colWidth: 6, value: "Comentarios", valueInsert: 48 },
        // { label: "Razón para la baja", type: "textarea", required: true, colWidth: 6 , value:"Razón para la baja", valueInsert:49},
        { label: "Adjuntos", type: "file", required: true, colWidth: 12, value: "file", valueInsert: 51 },

    ];

    const createUser = () => {
        createNewRequest(5, 3);
        setAlert("")
    }
    const createConfirmation = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`¿Desea proceder con la baja del usuario (Cliente) ?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => createUser()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, crear"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }

    return (
        <div>
            {alert}
            <Row className="d-flex justify-content-end">
                <Col sm="12" md="1">
                    <Button
                        className="btn  btn-icon"
                        color="warning"
                        block
                        type="button"
                        onClick={() => backPage()}
                    >
                        <span className="btn-inner--icon mr-">
                            <i className="fas fa-chevron-left"></i>
                        </span>
                        <span className="btn-inner--text">Inicio </span>
                    </Button>
                </Col>
            </Row>

            <Card className="mt-3">
                <CardHeader>
                    <h6 className="surtitle">Formulario</h6>
                    <h5 className="h3 mb-0">Solicitud de Baja</h5>
                </CardHeader>
                <CardBody>
                    <FormUser
                        itemForm={itemsForms}
                        itemInfo={info}
                        handleOnChangeInfo={handleOnChangeInfo}
                    />
                </CardBody>
                <CardFooter>
                    <Row className="d-flex justify-content-end">

                        <Col sm="12" md="3">
                            <Button
                                className="btn  btn-icon"
                                color="success"
                                block
                                type="button"
                                onClick={() => createConfirmation()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-share"></i>
                                </span>
                                <span className="btn-inner--text">Finalizar </span>
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </div>
    );
};



export default CancellationExternalRequest;