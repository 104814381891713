import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const getData = createAction(constants.BS_GET_DATA, method.get(urls.BUSINESSSYSTEM.getData));
export const getOrdersAssign = createAction(constants.BS_GET_ORDERS_ASSIGN, method.get(urls.BUSINESSSYSTEM.getOrdersAssign));
export const getReportUS = createAction(constants.BS_GET_REPORT_US, method.get(urls.BUSINESSSYSTEM.getReportUS));
export const getReportFlow = createAction(constants.BS_GET_REPORT_FLOW, (id) => method.put(`${urls.BUSINESSSYSTEM.getReportFlow}/${id}`)());
export const updateRequest = createAction(constants.BS_UPDATE_REQUEST, (id, body) => method.put(`${urls.BUSINESSSYSTEM.updateRequest}/${id}`, body)());
export const insertRequest = createAction(constants.BS_INSERT_REQUEST, (body) => method.post(`${urls.BUSINESSSYSTEM.insertRequest}`, body)());
export const getCountriesByHCM = createAction(constants.GETCOUNTRIESBYHCM, (body) => method.post(urls.BUSINESSSYSTEM.getCountriesByHCM, body)());
export const newUserRole = createAction("INSERT_USER_ROLE", (body) => method.post(urls.BUSINESSSYSTEM.inserUserRole, body)());
export const inactiveRoleByUser = createAction("INACTIVE_USER_ROLE", (body) => method.post(urls.BUSINESSSYSTEM.inactiveRole, body)());
export const updateUserRoleByUser = createAction("UPDATE_USER_ROLE", (body) => method.post(urls.BUSINESSSYSTEM.updateUserRole, body)());


