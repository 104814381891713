import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const authUser = createAction(constants.AUTH_USER, (user) =>
  method.post(urls.SIGNIN.auth, user)()
);

/**
 * Accion para limpiar todo el state cuando ocurre un cerrar sesion
 */
export const signOut = createAction(
  constants.SIGN_OUT,
  method.put(urls.SIGNIN.signOut)
);
export const generateSignature = createAction(constants.SIGNATURE, (body) =>
  method.downloadDataTables(urls.SIGNIN.generateSignature, body)()
);
export const getUrlCisco = createAction(constants.URL_CISCO, (body) =>
  method.post(urls.SIGNIN.getUrlCisco, body)()
);
export const getUUID = createAction(constants.UUID, (id) =>
  method.get(`${urls.SIGNIN.getSession}/${id}`)()
);
