/*!

=========================================================
*ItRequests
=========================================================

*Este componente es la vista general de la solicitud
=========================================================

* Coded by Sergio Marin  

*/

import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardHeader, CardSubtitle, CardText, Label, Button, Col, Row, Input, FormGroup } from "reactstrap";
import ConfirmModal from 'components/ItRequests/ConfirmationModal';
import CancellationModal from 'components/ItRequests/modals/CancellationModal';

import RequestDate from "components/ItRequests/RequestDate";
import GenData from "components/ItRequests/GenData";
import GenDataFreelance from "components/ItRequests/GenDataFreelance";
import ExclusiveHC from 'components/ItRequests/ExclusiveHC';
import SystemAccesses from 'components/ItRequests/SystemAccesses';
import PhysicalAccesses from 'components/ItRequests/PhysicalAccesses';
import AdditionalSoftware from 'components/ItRequests/AdditionalSoftware';
import ServerSupportAdministrator from 'components/ItRequests/ServerSupportAdministrator';
import ServerSupportFreelance from 'components/ItRequests/ServerSupportFreelance';
import AppSupportAdministrator from 'components/ItRequests/AppSupportAdministrator';
import AppSupportFreelance from 'components/ItRequests/AppSupportFreelance';
import CommunicationSupportAdministrator from 'components/ItRequests/CommunicationSupportAdministrator';
import GenericModal from 'components/ItRequests/modals/GenericModal';
import IsNewColab from 'components/ItRequests/IsNewColab';
import CancellationInfo from 'components/ItRequests/requestSections/CancellationInfo';

import { checkColabId, newRequest, cancelRequest } from 'actions/ItRequests'
import { useDispatch } from "react-redux";

const Request = ({ configJson, optionsJson, data, setData, step, setShowMenu, setLoading }) => {

    //FUNCIONES

    const componentConfigJson = configJson[step];//nombre del json en configJson
    const dispatch = useDispatch(); //dispatch para el action
    const [requiredJson, setRequiredJson] = useState([""]); //datos de los campos requeridos de la solictud
    const disabledSectionsJson = componentConfigJson.disabledSections; //datos de los componentes disabled de la solictud
    const [showConfirmationModal, setConfirmationModal] = useState(false); //hook del modal de confirmacion
    const [showCancellationModal, setCancellationnModal] = useState(false); //hook del modal de cancelacion
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [genModalOpen, setGenModalOpen] = useState(false); //para generic modal
    const [msgModal, setMsgModal] = useState(""); //para generic modal

    const toggleModal = () => { setConfirmationModal(!showConfirmationModal); };
    const toggleCancelModal = () => { setCancellationnModal(!showCancellationModal); };

    //comprobar si colab existe
    const checkIdColab = async (employeeId) => {
        setLoading(true);

        try {
            const resp = await dispatch(checkColabId(employeeId));
            setLoading(false);
            return resp.payload.data.isValid;
        } catch {
            setLoading(false);
            return false;
        }

    };
    //boton de cargar
    const handleSendButton = () => {
        // Validar los campos antes de enviar la solicitud

        const emptyFields = validateFields(data, componentConfigJson.requiredFields);
        setRequiredJson(emptyFields);

        if (emptyFields.length === 0) {
            //todos los campos requeridos estan llenos, guardar solicitud
            toggleModal();
        }
        else {
            setMsgModal("Favor llenar todos los campos obligatorios")
            setGenModalOpen(true);
        }
    };
    //Funcion para validar los datos ingresados
    const validateFields = (data, requiredFields) => {
        let res = [];

        //condicion para el phonetype
        if (data.phoneType === "NO") {
            requiredFields = requiredFields.filter(item => item !== "phoneExt");
        }

        for (var i = 0; i < requiredFields.length; i++) {
            var field = requiredFields[i];
            const fieldValue = data[field];

            if (field !== null) {
                if (fieldValue === null) {
                    res.push(field);
                }
                else {
                    if (typeof fieldValue === "object") {
                        try {
                            if (fieldValue.length === 0) { res.push(field); }
                        }
                        catch { }

                    }
                    else if (typeof fieldValue === "undefined" || fieldValue === "") {
                        res.push(field);
                    }
                }
            }
        }
        return res;
    };
    //Funcion para guardar la soli en la BD
    const saveRequest = async () => {
        data.lastStep = step;
        data.pendingMail = 1;

        //ACCION A LA BD de guardar los datos
        setLoading(true);
        await dispatch(newRequest(data)).then((resp) => {
            setLoading(false);
            if (resp.payload.request.status !== 200) {
                setMsgModal("No se pudo procesar su solicitud, si el problema persiste comuníquese con Application Management")
                setGenModalOpen(true);
            }
            else {
                setShowMenu("menu");
            }
        });

        //alert(JSON.stringify(data));
    };
    //Funcion para cancelar la soli en la BD
    const handleCancelRequest = async (reason) => {
        data.lastStep = step;
        data.pendingMail = 1;

        //ACCION A LA BD de guardar los datos
        setLoading(true);
        await dispatch(cancelRequest({ data, reason })).then((resp) => {
            setLoading(false);
            debugger;
            if (resp.payload.request.status !== 200) {
                setMsgModal("No se pudo procesar su solicitud, si el problema persiste comuníquese con Application Management")
                setGenModalOpen(true);
            }
            else {
                setShowMenu("menu");
            }
        });
    };

    //RETURN
    return (
        <>
            {/* boton de enviar y titulo*/}
            <Row style={{ padding: '0px 15px 0px' }}>
                {/* boton de enviar */}
                {step !== "ReadRequest" &&
                    (
                        <Button
                            color="success"
                            onClick={() => handleSendButton()}
                            style={{ marginBottom: "10px" }}
                        >
                            <span className="">
                                <i className="fa fa-paper-plane"></i>
                            </span>
                            <span className="btn-inner--text">
                                {" "} Enviar
                            </span>
                        </Button>
                    )}
                {step === "ReadRequest" && data.isFinished !== '1' &&
                    (
                        <Button
                            color="warning"
                            onClick={() => toggleCancelModal()}
                            style={{ marginBottom: "10px" }}
                        >
                            <span className="">
                                <i className="fa fa-paper-plane"></i>
                            </span>
                            <span className="btn-inner--text">
                                {" "} Cancelar
                            </span>
                        </Button>
                    )}


                {/* El titulo */}

                <h1>Solicitud de {data.requestState}</h1>

            </Row>

            <CancellationInfo
                //isVisible={componentConfigJson.requiredSections.includes("CancellationInfo")}
                isVisible={data.isCancelled === "1"}
                data={data}
            />
            <IsNewColab
                isVisible={componentConfigJson.requiredSections.includes("IsNewColab")}
                data={data}
            />
            <RequestDate
                isVisible={componentConfigJson.requiredSections.includes("RequestDate")}
                data={data}
            />
            <GenDataFreelance
                enableComments={(step === "InitialFreelanceTermination")}
                requiredJson={requiredJson}
                data={data}
                isVisible={componentConfigJson.requiredSections.includes("GenDataFreelance")}
                optionsJson={optionsJson}
                setData={setData}
                disabledFieldsJson={disabledSectionsJson}
                checkIdColab={checkIdColab}
            />
            <GenData
                enableComments={(step === "InitialTermination")}//anular el disabled de comentarios
                requiredJson={requiredJson}//campos para colocarlos en obligatorio
                data={data}//informacion para llenar los campos por default
                isVisible={componentConfigJson.requiredSections.includes("GenData")}//se muestra o no este componente en la pantalla
                optionsJson={optionsJson}//informacion de las lista para dropdows, radio buttons, checks, etc
                setData={setData} //funcion que llena lo que se ingresó en el componente
                disabledFieldsJson={disabledSectionsJson} //información de los campos editables
            />
            <ExclusiveHC
                enableComments={(step === "InitialTermination")}
                requiredJson={requiredJson}
                data={data}
                isVisible={componentConfigJson.requiredSections.includes("ExclusiveHC")}
                optionsJson={optionsJson}
                setData={setData}
                selectedCountry={selectedCountry}
                disabledFieldsJson={disabledSectionsJson}
                checkIdColab={checkIdColab}
            />
            <ServerSupportFreelance
                enableComments={(step === "ServerSupportFreelanceTermination")}
                isVisible={componentConfigJson.requiredSections.includes("ServerSupportFreelance")}
                setData={setData}
                data={data}
                requiredJson={requiredJson}
                disabledFieldsJson={disabledSectionsJson}
            />
            <ServerSupportAdministrator
                enableComments={(step === "ServerSupportTermination")}
                isVisible={componentConfigJson.requiredSections.includes("ServerSupportAdministrator")}
                setData={setData}
                data={data}
                requiredJson={requiredJson}
                disabledFieldsJson={disabledSectionsJson}
            />
            <AppSupportFreelance
                enableComments={(step === "ApplicationSupportFreelanceTermination")}
                isVisible={componentConfigJson.requiredSections.includes("AppSupportFreelance")}
                requiredJson={requiredJson}
                data={data}
                optionsJson={optionsJson}
                setData={setData}
                disabledFieldsJson={disabledSectionsJson}
            />
            <AppSupportAdministrator
                optionsJson={optionsJson}
                enableComments={(step === "ApplicationSupportTermination")}
                requiredJson={requiredJson}
                data={data}
                isVisible={componentConfigJson.requiredSections.includes("AppSupportAdministrator")}
                disabledFieldsJson={disabledSectionsJson}
                setData={setData}
            />
            <CommunicationSupportAdministrator
                enableComments={(step === "CommunicationSupportTermination")}
                requiredJson={requiredJson}
                disabledFieldsJson={disabledSectionsJson}
                data={data}
                isVisible={componentConfigJson.requiredSections.includes("CommunicationSupportAdministrator")}
                setData={setData}
            />
            <PhysicalAccesses
                disabledFieldsJson={disabledSectionsJson}
                isVisible={componentConfigJson.requiredSections.includes("PhysicalAccesses")}
                optionsJson={optionsJson}
                setData={setData}
                data={data}
            />
            <SystemAccesses
                requiredJson={requiredJson}
                data={data}
                isVisible={componentConfigJson.requiredSections.includes("SystemAccesses")}
                optionsJson={optionsJson}
                setData={setData}
                selectedCountry={selectedCountry}
                disabledFieldsJson={disabledSectionsJson}
            />
            <AdditionalSoftware
                isVisible={componentConfigJson.requiredSections.includes("AdditionalSoftware")}
                optionsJson={optionsJson}
                disabledFieldsJson={disabledSectionsJson}
                setData={setData}
                data={data}
            />
            <ConfirmModal
                showModal={showConfirmationModal}
                toggle={toggleModal}
                okFunct={saveRequest}
            />
            <GenericModal
                showModal={genModalOpen}
                setShowModal={setGenModalOpen}
                msg={msgModal}
            />

            <CancellationModal
                showModal={showCancellationModal}
                toggle={toggleCancelModal}
                okFunct={handleCancelRequest}
            />
        </>
    )
}

export default Request;