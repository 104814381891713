import React, { useState, useEffect } from 'react';
import FormUser from "components/UserCloudRequest/Forms";
import { Card, CardBody, Col, CardHeader, Button, Row, CardFooter } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getTenantsByCloud } from "actions/userCloudRequests.jsx";
import { useDispatch } from "react-redux";
const CancellationRequest = ({ selectMatrix, matrix, setInfo, info, backPage, handleOnChangeInfo, createNewRequest, notify, data, setNewInfo }) => {
    const dispatch = useDispatch();
    const [alert, setAlert] = useState("");
    const [tenants, setTenants] = useState([]);
    const [userMatriz, setUserMatriz] = useState([]);
    const itemsForms = [
        { label: "Correo GBM", type: "selectCustom", required: true, colWidth: 6, options: selectMatrix, onChange: (e, x) => searchData(e, x), value: "mail", valueInsert: 21 },
        { label: "Nombre", type: "input", required: true, colWidth: 6, disabled: info["NameEmployee"] ? true : false, value: "NameEmployee", valueInsert: 22 },
        { label: "País", type: "input", required: true, colWidth: 6, disabled: info["country"] ? true : false, value: "country", valueInsert: 27 },
        { label: "Posición", type: "input", required: true, multi: false, colWidth: 6, disabled: info["gbmPosition"] ? true : false, value: "gbmPosition", valueInsert: 23 },
        { label: "Grupo Resolutorio Cloud", type: "input", required: true, multi: true, colWidthmd: 6, disabled: info["groupCloud"] ? true : false, value: "groupCloud", valueInsert: 24 },
        { label: "Nube", type: "selectCustom", required: true, options: data.clouds, multi: false, onChange: (e, item, valueInsert, groupCloud) => searchTenantsByCloud(e, item, valueInsert, groupCloud), colWidth: 6, valueInsert: 25, groupCloud: 24 },
        { label: "Número de tenant", type: "select", required: true, options: tenants, value: "tenant", multi: true, colWidth: 6, valueInsert: 26 },
        { label: "Motivo para dar de baja", type: "textarea", required: true, value: "reasonDiscard", colWidth: 6, valueInsert: 28 },
    ];
    const createUser = () => {
        createNewRequest(1, 1);
        setAlert("")
    }
    const searchData = (element, item) => {
        const user = matrix.find((element) => element.mail === item.value);
        setUserMatriz(user);
        for (const element of Object.keys(user)) {

            setInfo((prevState) => ({
                ...prevState,
                [element]: user[element]
            }));
            const newInsert = itemsForms.find((item) => item.value === element)
            if (newInsert) {
                setNewInfo((prevState) => ({
                    ...prevState,
                    [newInsert.value]: { value: user[element], insert: newInsert.valueInsert }

                }));
            }
        }
        // const insert = 
    }
    const createConfirmation = () => {
        setAlert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`¿Desea crear el nuevo usuario?`}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => createUser()}
                onCancel={() => setAlert("")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Sí, crear"
                cancelBtnText="No, cancelar"
                btnSize="md"
            />
        )
    }
    const searchTenantsByCloud = async (e, item, valueInsert, groupCloud) => {
        const id = item["value"];
        await changeConfirmLocale(item, groupCloud);
        await dispatch(getTenantsByCloud(id)).then((resp) => {
            const { payload } = resp;
            if (payload.isAxiosError) {
                notify("danger", "Error", payload.message);
            } else {
                setTenants(payload.data.data)
                setNewInfo((prevState) => ({
                    ...prevState,
                    ["cloud"]: { value: id, insert: valueInsert }
                }));
            }
        });
    }
    const changeConfirmLocale = async (item, valueInsert) => {
        info["groupCloud"] = userMatriz[item.label]
        setNewInfo((prevState) => ({
            ...prevState,
            ["groupCloud"]: { value: info["groupCloud"], insert: valueInsert }

        }));
    }
    return (
        <div>
            {alert}
            <Row className="d-flex justify-content-end">
                <Col sm="12" md="1">
                    <Button
                        className="btn  btn-icon"
                        color="warning"
                        block
                        type="button"
                        onClick={() => backPage()}
                    >
                        <span className="btn-inner--icon mr-">
                            <i className="fas fa-chevron-left"></i>
                        </span>
                        <span className="btn-inner--text">Inicio </span>
                    </Button>
                </Col>
            </Row>

            <Card className="mt-3">
                <CardHeader>
                    <h6 className="surtitle">Formulario</h6>
                    <h5 className="h3 mb-0">Solicitud de Baja</h5>
                </CardHeader>
                <CardBody>
                    <FormUser
                        itemForm={itemsForms}
                        itemInfo={info}
                        handleOnChangeInfo={handleOnChangeInfo}
                    />
                </CardBody>
                <CardFooter>
                    <Row className="d-flex justify-content-end">

                        <Col sm="12" md="3">
                            <Button
                                className="btn  btn-icon"
                                color="success"
                                block
                                type="button"
                                onClick={() => createConfirmation()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-share"></i>
                                </span>
                                <span className="btn-inner--text">Finalizar </span>
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </div>
    );
};


export default CancellationRequest;