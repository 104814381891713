// core actions
import {
  createActivityLogByContractOnHoldID,
  findActivityLogsByContractID,
  findAllContractsOnHold,
  findAllStatusAvailable,
  updateStatusContractOnHoldByID,
  updateTargetStartDateContractOnHoldByID,
  downloadContractsOnHold,
  findAllUsersFMandPM,
  createUserFMPMAssign,
  createContractException,
} from "actions/secoh.jsx";
// antd components
import { Spin, message } from "antd";
// core components
import ActivityLogModal from "components/SECOH/ActivityLogModal.jsx";
import ChangeStatusModal from "components/SECOH/ChangeStatusModal.jsx";
import ContractDetail from "components/SECOH/ContractDetail.jsx";
import Contracts from "components/SECOH/Contracts.jsx";
import Filters from "components/SECOH/Filters.jsx";
import TargetStartDateModal from "components/SECOH/TargetStartDateModal.jsx";
import UsersAssignModal from "components/SECOH/UsersAssignModal.jsx";
// core components Shared
import CardLoading from "components/Shared/Cards/CardLoading.jsx";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
// nodejs library to format dates
import moment from "moment";
import "moment/locale/es";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core selectors
import {
  getActivityFlowsByContractID,
  getContractsOnHold,
  getStatusAvailable,
  getServicesAvailable,
  getAllUsersFMAndPM,
  getTargetStartLogsByContractID,
} from "selectors/secoh.jsx";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Extracción de librería swal.
const MySwal = withReactContent(Swal);

class ListContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      willMount: true,
      loading: false,
      loadingModal: false,
      message: null,
      page: 1,
      sizePerPage: 25,
      edit: {
        id: null,
        idStatus: null,
        flag: false,
      },
      typeFilter: {
        value: "all",
        label: "Seleccione la columna que deseas filtrar",
        type: "text",
      },
      valueFilter: null,
      optionsFilter: [],
      conditionFilter: {
        value: "all",
        label: "Seleccione la condición a aplicar",
        simbol: "NA",
      },
      filtersApplied: [],
      contractSelected: null,
      showContractDetail: false,
      modalUpdateStatus: false,
      modalActivityLog: false,
      modalTargetStartDate: false,
      referenceFilesList: [],
      referencesRemoved: [],
      inProgressFlag: false,
      countriesList: [],
      statusList: [],
      servicesList: [],
      responsibleFMList: [],
      responsiblePMList: [],
      modalUsers: false,
      contractUserAssign: null,
    };
  }

  componentWillMount() {
    this.findContractsOnHold();
  }

  findContractsOnHold = () => {
    const { inProgressFlag, filtersApplied } = this.state;
    this.props
      .findAllContractsOnHold(inProgressFlag, { filters: filtersApplied })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findAllStatusAvailable();
          this.props.findAllUsersFMandPM();
        }
        this.setState({
          willMount: false,
        });
      });
  };

  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  handleOnSetPage = (page) => {
    this.setState({
      page,
    });
  };

  handleOnOpenContract = (row) => {
    this.setState({
      loading: true,
      message: "Cargando la información del contrato",
    });
    this.props.findActivityLogsByContractID(row.id).then((res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          const {
            data: { payload },
          } = res.payload.response;
          this.notify("warning", "Atención", payload.message);
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        this.setState({
          contractSelected: row,
          showContractDetail: true,
          loading: false,
          message: null,
        });
      }
    });
  };

  handleOnEditStateContract = (row) => {
    this.setState({
      edit: row,
      modalUpdateStatus: true,
    });
  };

  handleOnChangeTypeFilter = (event, type) => {
    const {
      countriesList,
      statusList,
      servicesList,
      responsibleFMList,
      responsiblePMList,
    } = this.state;
    let {
      users: { usersFM, usersPM },
    } = this.props;
    if (event.value === "status") {
      if (!statusList.length) {
        const { rows } = this.props;
        let states = rows.map((row) => row.activityLog);
        states = states
          .map((e) => e)
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
          // eliminate the dead keys & store unique objects
          .filter((e) => states[e])
          .map((e) => states[e]);
        states = states.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
        states = states.map((row, key) => {
          return {
            value: key,
            label: row,
          };
        });
        this.setState({
          optionsFilter: states,
          statusList: states,
        });
      } else {
        this.setState({
          optionsFilter: statusList,
        });
      }
    } else if (event.value === "services") {
      if (!servicesList.length) {
        const { rows } = this.props;
        let services = [];
        for (const element of rows) {
          if (!element.services.length) {
            services = [...services, "N/A"];
          } else {
            for (const service of element.services) {
              services = [...services, service.serviceGroup];
            }
          }
        }
        services = services
          .map((e) => e)
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
          // eliminate the dead keys & store unique objects
          .filter((e) => services[e])
          .map((e) => services[e]);
        services = services.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
        services = services.map((row, key) => {
          return {
            value: key,
            label: row,
          };
        });
        this.setState({
          optionsFilter: services,
          servicesList: services,
        });
      } else {
        this.setState({
          optionsFilter: servicesList,
        });
      }
    } else if (event.value === "country") {
      if (!countriesList.length) {
        const { rows } = this.props;
        let countries = rows.map((row) => row.country);
        countries = countries
          .map((e) => e)
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
          // eliminate the dead keys & store unique objects
          .filter((e) => countries[e])
          .map((e) => countries[e]);
        countries = countries.map((row, key) => {
          return {
            value: key,
            label: row,
          };
        });
        this.setState({
          optionsFilter: countries,
          countriesList: countries,
        });
      } else {
        this.setState({
          optionsFilter: countriesList,
        });
      }
    } else if (event.value === "nameResponsibleFM") {
      usersFM = usersFM.map((row, key) => {
        return {
          value: row.userID,
          label: row.name,
        };
      });
      this.setState({
        optionsFilter: usersFM,
        responsibleFMList: usersFM,
      });
    } else if (event.value === "nameResponsiblePM") {
      usersPM = usersPM.map((row, key) => {
        return {
          value: row.userID,
          label: row.name,
        };
      });
      this.setState({
        optionsFilter: usersPM,
        responsiblePMList: usersPM,
      });
    } else if (event.value === "delayed") {
      this.setState({
        optionsFilter: [
          { value: 1, label: "SI" },
          { value: 2, label: "NO" },
        ],
        responsibleFMList: usersFM,
      });
    }
    if (type === "valueFilterText") {
      const { value } = event.target;
      this.setState({
        valueFilter: value.toUpperCase(),
      });
    } else {
      this.setState({
        [type]: event,
      });
    }
  };

  handleOnAddFilter = (value, typeFilter, conditionFilter) => {
    const { inProgressFlag, filtersApplied } = this.state;
    this.setState({
      loading: true,
      message: "Cargando los contratos on hold",
    });
    this.setState((state) => ({
      filtersApplied: [
        ...state.filtersApplied,
        {
          col: typeFilter.value,
          type: typeFilter.type,
          value: value.label ? value.label : value,
          name: typeFilter.label,
          condition: conditionFilter.label,
          simbol: conditionFilter.simbol,
        },
      ],
      typeFilter: {
        value: "all",
        label: "Seleccione la columna que deseas filtrar",
      },
      conditionFilter: {
        value: "all",
        label: "Seleccione la condición a aplicar",
        simbol: "NA",
      },
      valueFilter: "",
      optionsFilter: [],
      page: 1,
    }));
    this.props
      .findAllContractsOnHold(inProgressFlag, {
        filters: [
          ...filtersApplied,
          {
            col: typeFilter.value,
            type: typeFilter.type,
            value: value.label ? value.label : value,
            name: typeFilter.label,
            condition: conditionFilter.label,
            simbol: conditionFilter.simbol,
          },
        ],
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findAllStatusAvailable();
        }
        this.setState({
          loading: false,
          message: "",
        });
      });
  };

  handleOnRenderRowsFiltered = (rows) => {
    let { filtersApplied } = this.state;
    filtersApplied = filtersApplied.sort((a, b) => {
      if (a.col < b.col) {
        return -1;
      }
      if (a.col > b.col) {
        return 1;
      }
      return 0;
    });
    let rowsFilters = [];
    for (const element of filtersApplied) {
      const { col, condition, value, simbol, type } = element;
      if (simbol === "NA") {
        if (col === "status") {
          if (rowsFilters.length) {
            rowsFilters = rowsFilters.filter((row) => row.activityLog == value);
          } else {
            rowsFilters = [
              ...rowsFilters,
              ...rows.filter((row) => row.activityLog == value),
            ];
          }
        } else if (col === "services") {
          if (value === "N/A") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter((row) => row[col] === []);
            } else {
              rowsFilters = [
                ...rowsFilters,
                ...rows.filter((row) => row[col].length === 0),
              ];
            }
          } else {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter((row) =>
                row[col].some((ele) => ele.serviceGroup.includes(value))
              );
            } else {
              rowsFilters = [
                ...rowsFilters,
                ...rows.filter((row) =>
                  row[col].some((ele) => ele.serviceGroup.includes(value))
                ),
              ];
            }
          }
        } else {
          if (rowsFilters.length) {
            rowsFilters = rowsFilters.filter((row) =>
              row[col].toUpperCase().includes(value)
            );
          } else {
            rowsFilters = rows.filter((row) =>
              row[col].toUpperCase().includes(value)
            );
          }
        }
      } else {
        if (condition === "Mayor que") {
          if (type === "number") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) => parseInt(row[col]) > parseInt(value)
              );
            } else {
              rowsFilters = rows.filter(
                (row) => parseInt(row[col]) > parseInt(value)
              );
            }
          } else if (type === "date") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") >
                  moment(value).format("YYYY-MM-DD")
              );
            } else {
              rowsFilters = rows.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") >
                  moment(value).format("YYYY-MM-DD")
              );
            }
          } else {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter((row) => row[col] > value);
            } else {
              rowsFilters = rows.filter((row) => row[col] > value);
            }
          }
        } else if (condition === "Menor que") {
          if (type === "number") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) => parseInt(row[col]) < parseInt(value)
              );
            } else {
              rowsFilters = rows.filter(
                (row) => parseInt(row[col]) < parseInt(value)
              );
            }
          } else if (type === "date") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") <
                  moment(value).format("YYYY-MM-DD")
              );
            } else {
              rowsFilters = rows.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") <
                  moment(value).format("YYYY-MM-DD")
              );
            }
          } else {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter((row) => row[col] < value);
            } else {
              rowsFilters = rows.filter((row) => row[col] < value);
            }
          }
        } else if (condition === "Igual que") {
          if (type === "number") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) => parseInt(row[col]) === parseInt(value)
              );
            } else {
              rowsFilters = rows.filter(
                (row) => parseInt(row[col]) === parseInt(value)
              );
            }
          } else if (type === "date") {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") ===
                  moment(value).format("YYYY-MM-DD")
              );
            } else {
              rowsFilters = rows.filter(
                (row) =>
                  moment(row[col]).format("YYYY-MM-DD") ===
                  moment(value).format("YYYY-MM-DD")
              );
            }
          } else {
            if (rowsFilters.length) {
              rowsFilters = rowsFilters.filter((row) => row[col] === value);
            } else {
              rowsFilters = rows.filter((row) => row[col] === value);
            }
          }
        }
      }
    }
    return filtersApplied.length ? rowsFilters : rows;
  };

  handleOnDeleteFilter = (filter) => {
    const { inProgressFlag, filtersApplied } = this.state;
    this.setState({
      loading: true,
      message: "Cargando los contratos on hold",
      filtersApplied: filtersApplied.filter(
        (row) =>
          !(
            row.col === filter.col &&
            row.value === filter.value &&
            row.simbol === filter.simbol
          )
      ),
    });
    this.props
      .findAllContractsOnHold(inProgressFlag, {
        filters: filtersApplied.filter(
          (row) =>
            !(
              row.col === filter.col &&
              row.value === filter.value &&
              row.simbol === filter.simbol
            )
        ),
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findAllStatusAvailable();
        }
        this.setState({
          loading: false,
          message: "",
        });
      });
  };

  handleOnClearFilters = () => {
    const { inProgressFlag } = this.state;
    this.setState({
      loading: true,
      message: "Cargando los contratos on hold",
      filtersApplied: [],
      typeFilter: {
        value: "all",
        label: "Seleccione la columna que deseas filtrar",
        type: "text",
      },
      valueFilter: null,
      conditionFilter: {
        value: "all",
        label: "Seleccione la condición a aplicar",
        simbol: "NA",
      },
      optionsFilter: [],
    });
    this.props
      .findAllContractsOnHold(inProgressFlag, {
        filters: [],
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          this.props.findAllStatusAvailable();
        }
        this.setState({
          loading: false,
          message: "",
        });
      });
  };

  handleOnBackContractSelected = () => {
    this.setState({
      contractSelected: null,
      showContractDetail: false,
    });
  };

  handleOnToggleModalUpdateStatus = () => {
    this.setState({
      modalUpdateStatus: false,
    });
  };

  handleOnToggleModalActivityLog = () => {
    this.setState({
      modalActivityLog: false,
    });
  };

  handleOnToggleModalTargetStartDate = () => {
    this.setState({
      modalTargetStartDate: false,
    });
  };

  handleOnUpdateStatusByContractOnHold = (values) => {
    const { id, status, comments } = values;
    const { edit } = this.state;
    if (parseInt(status) === edit.status) {
      this.setState({
        modalUpdateStatus: false,
        edit: {
          id: null,
          idStatus: null,
          flag: false,
        },
      });
      this.notify(
        "warning",
        "Atención",
        "El estado seleccionado es el mismo, debes seleccionar un estado diferente"
      );
    } else {
      this.setState({
        loadingModal: true,
      });
      this.props
        .updateStatusContractOnHoldByID(id, status, {
          commentary: comments,
        })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            const {
              data: { payload },
            } = res.payload;
            this.notify("success", "Éxito", payload.message);
            this.findContractsOnHold();
            this.setState({
              edit: {
                id: null,
                idStatus: null,
                flag: false,
              },
            });
          }
          this.setState({
            loadingModal: false,
            modalUpdateStatus: false,
          });
        });
    }
  };

  handleNewActivityLogByContractOnHold = () => {
    this.setState({
      modalActivityLog: true,
    });
  };

  handleNewActivityLogInContractOnHold = (row) => {
    this.setState({
      modalActivityLog: true,
      contractSelected: row,
    });
  };

  handleOnCreateActivityLogByContractOnHold = (values) => {
    const {
      contractSelected: { id, status },
      inProgressFlag,
      filtersApplied,
    } = this.state;
    const { comments } = values;
    this.setState({
      loadingModal: true,
    });
    this.props
      .createActivityLogByContractOnHoldID(id, {
        idStatus: status,
        commentary: comments,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.props.findActivityLogsByContractID(id);
          this.props.findAllContractsOnHold(inProgressFlag, {
            filters: filtersApplied,
          });
        }
        this.setState({
          loadingModal: false,
          modalActivityLog: false,
        });
      });
  };

  handleOnNewTargetStartDate = () => {
    this.setState({
      modalTargetStartDate: true,
    });
  };

  handleOnUpdateTargetStartDate = (values) => {
    const {
      contractSelected: { id },
      referenceFilesList,
      referencesRemoved,
    } = this.state;
    const { reason, targetStartDate } = values;
    const attachments = referenceFilesList.map((row) => {
      const { idReference, path, name } = row.response.payload;
      return {
        idReference,
        path,
        name,
      };
    });
    this.setState({
      loadingModal: true,
    });
    this.props
      .updateTargetStartDateContractOnHoldByID(id, {
        targetStartDate: moment(targetStartDate).format("YYYY-MM-DD"),
        reason,
        status: 5,
        attachments,
        referencesRemoved,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findContractsOnHold();
          this.props.findActivityLogsByContractID(id);
        }
        this.setState({
          loadingModal: false,
          modalTargetStartDate: false,
          referenceFilesList: [],
          referencesRemoved: [],
        });
      });
  };

  handleOnFileListReferences = (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const {
        file: { name, uid, response },
      } = info;
      if (response) {
        const { status, payload } = response;
        if (status === 200) {
          const { idReference } = payload;
          this.setState((state) => ({
            referenceFilesList: state.referenceFilesList.filter(
              (row) => row.uid !== uid
            ),
            referencesRemoved: [...state.referencesRemoved, idReference],
          }));
        } else {
          this.setState((state) => ({
            referenceFilesList: state.referenceFilesList.filter(
              (row) => row.uid !== uid
            ),
          }));
        }
        message.success(`El archivo ${name} fue quitado exitosamente.`);
      } else {
        message.success(`El archivo ${name} fue quitado exitosamente.`);
        this.setState({
          referenceFilesList: [],
        });
      }
    } else {
      const {
        fileList,
        file: { status, name },
      } = info;
      if (status === "done") {
        message.success(`La carga del archivo ${name} fue exitosa.`);
      } else if (status === "error") {
        message.success(`Ocurrio un error cargando el archivo ${name}.`);
      }
      this.setState({
        referenceFilesList: fileList,
      });
    }
  };

  handleOnChangeViewContract = (event) => {
    const { id } = event.target;
    const { filtersApplied } = this.state;
    this.setState({
      loading: true,
      message: "Cargando la información de los contratos",
    });
    if (id === "viewInProgress") {
      this.props
        .findAllContractsOnHold(true, { filters: filtersApplied })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.props.findAllStatusAvailable();
          }
          this.setState({
            loading: false,
            message: null,
            inProgressFlag: true,
          });
        });
    } else if (id === "viewOnHold") {
      this.props
        .findAllContractsOnHold(false, { filters: filtersApplied })
        .then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { payload },
              } = res.payload.response;
              this.notify("warning", "Atención", payload.message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
          } else {
            this.props.findAllStatusAvailable();
          }
          this.setState({
            loading: false,
            message: null,
            inProgressFlag: false,
          });
        });
    }
  };

  handleOnExportData = () => {
    const { rows } = this.props;
    this.props.downloadContractsOnHold(rows).then(async (res) => {
      if (res.payload.isAxiosError) {
        if (res.payload.response) {
          this.notify(
            "warning",
            "Atención",
            `No se logro descargar la información del modelo seleccionado`
          );
        } else {
          this.notify(
            "danger",
            "Falló",
            "No se logro establecer conexion con el servidor."
          );
        }
      } else {
        const url = window.URL.createObjectURL(new Blob([res.payload.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Lista Contratos On Hold.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  handleOnToggleModalUsersAssign = () => {
    this.setState({
      modalUsers: false,
      contractUserAssign: null,
    });
  };

  handleOnAssignEmployee = (row) => {
    this.setState({
      modalUsers: true,
      contractUserAssign: row,
    });
  };

  handleOnCreateUserAssignation = (values) => {
    const {
      users: { usersFM, usersPM },
    } = this.props;
    const { contractUserAssign } = this.state;
    let userInfo = {};
    if (values.userType === "userFM") {
      userInfo = usersFM.find((row) => row.id_User === parseInt(values.user));
    } else {
      userInfo = usersPM.find((row) => row.id_User === parseInt(values.user));
    }
    this.setState({
      loadingModal: true,
    });
    this.props
      .createUserFMPMAssign(contractUserAssign.id, {
        user: userInfo,
        type: values.userType,
      })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("warning", "Atención", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const {
            data: { payload },
          } = res.payload;
          this.notify("success", "Éxito", payload.message);
          this.findContractsOnHold();
        }
        this.setState({
          loadingModal: false,
          modalUsers: false,
          contractUserAssign: null,
        });
      });
  };

  handleCreateContractException = (contractNumber, contractId) => {
    MySwal.fire({
      title: "Alerta",
      text: `¿Desea establecer este contrato #${contractNumber} como No Delayed?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: `Si, establecer como No Delayed`,
      confirmButtonColor: "#2dce89",
      cancelButtonText: "No, cancelar",
      cancelButtonColor: "#adb5bd",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-md btn-success", // Botón de confirmación pequeño y verde
        cancelButton: "btn btn-md ", // Botón de cancelación pequeño y rojo
      },
      className: "p-0",
      style: {
        overflow: "unset",
        padding: "0px",
      },
    }).then((result) => {
      if (result.value) {
        this.setState({
          loading: true,
          message: "Cargando los contratos on hold",
        });
        this.props
          .createContractException({ contractId: contractId })
          .then((res) => {
            if (res.payload.isAxiosError) {
              if (res.payload.response) {
                const {
                  data: { payload },
                } = res.payload.response;
                this.notify("warning", "Atención", payload.message);
              } else {
                this.notify(
                  "danger",
                  "Falló",
                  "No se logro establecer conexion con el servidor."
                );
              }
            } else {
              const {
                data: { payload },
              } = res.payload;
              this.notify("success", "Éxito", payload.message);
              const { inProgressFlag, filtersApplied } = this.state;

              window.scrollTo({ top: 0, behavior: "smooth" });
              this.props
                .findAllContractsOnHold(inProgressFlag, {
                  filters: filtersApplied,
                })
                .then((res) => {
                  if (res.payload.isAxiosError) {
                    if (res.payload.response) {
                      const {
                        data: { payload },
                      } = res.payload.response;
                      this.notify("warning", "Atención", payload.message);
                    } else {
                      this.notify(
                        "danger",
                        "Falló",
                        "No se logro establecer conexion con el servidor."
                      );
                    }
                  } else {
                    this.setState({
                      loading: false,
                      message: "",
                    });
                  }
                });
            }
          });
      }
    });
  };

  render() {
    const {
      name,
      parentName,
      rows,
      cols,
      colsFilter,
      states,
      activityLogs,
      users,
      targetStartLogs,
    } = this.props;

    const {
      alert,
      loading,
      loadingModal,
      message,
      willMount,
      page,
      sizePerPage,
      edit,
      valueFilter,
      typeFilter,
      modalUsers,
      optionsFilter,
      filtersApplied,
      inProgressFlag,
      conditionFilter,
      contractSelected,
      showContractDetail,
      modalUpdateStatus,
      modalActivityLog,
      referenceFilesList,
      modalTargetStartDate,
      decoded,
    } = this.state;

    return (
      <>
        {alert}
        <TargetStartDateModal
          title="Actualización del Objetivo de Fecha de Inicio"
          showModal={modalTargetStartDate}
          loading={loadingModal}
          fileList={referenceFilesList}
          toggleModal={this.handleOnToggleModalTargetStartDate}
          updateTarget={this.handleOnUpdateTargetStartDate}
          onFileListReference={this.handleOnFileListReferences}
        />
        <ActivityLogModal
          title="Creación de nuevo comentario"
          showModal={modalActivityLog}
          loading={loadingModal}
          toggleModal={this.handleOnToggleModalActivityLog}
          onCreate={this.handleOnCreateActivityLogByContractOnHold}
        />
        <ChangeStatusModal
          title="Actualizar Estado Contrato"
          showModal={modalUpdateStatus}
          status={states}
          loading={loadingModal}
          initialValues={edit}
          toggleModal={this.handleOnToggleModalUpdateStatus}
          onUpdate={this.handleOnUpdateStatusByContractOnHold}
        />
        <UsersAssignModal
          title="Asignación de Usuarios FM/PM"
          data={users}
          showModal={modalUsers}
          loading={loadingModal}
          toggleModal={this.handleOnToggleModalUsersAssign}
          onSubmit={this.handleOnCreateUserAssignation}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AdminHeader name={name} parentName={parentName} />
        <Container className="mt--6" fluid>
          <Spin size="large" spinning={loading} tip={message}>
            {willMount ? (
              <Row className="justify-content-center">
                <Col>
                  <CardLoading title="Cargando la lista de Contratos On Hold" />
                </Col>
              </Row>
            ) : contractSelected && showContractDetail ? (
              <ContractDetail
                inProgress={inProgressFlag}
                row={contractSelected}
                status={states}
                logs={activityLogs}
                targetStartLogs={targetStartLogs}
                onBack={this.handleOnBackContractSelected}
                newActivity={this.handleNewActivityLogByContractOnHold}
                newTargetStartDate={this.handleOnNewTargetStartDate}
              />
            ) : (
              <>
                <Filters
                  title="Filtros Disponibles"
                  subtitle="Aplicación de Filtros a la lista de Contratos On Hold"
                  cols={colsFilter}
                  options={{
                    typeFilter,
                    valueFilter,
                    filtersApplied,
                    conditionFilter,
                    optionsFilter,
                  }}
                  changeTypeFilter={this.handleOnChangeTypeFilter}
                  addFilter={this.handleOnAddFilter}
                  deleteFilter={this.handleOnDeleteFilter}
                  onClear={this.handleOnClearFilters}
                />
                <Contracts
                  edit={edit}
                  title="Contratos On Hold"
                  subtitle="Lista de Contratos On Hold"
                  cols={cols}
                  rows={rows}
                  page={page}
                  options={{
                    states,
                    inProgressFlag,
                  }}
                  sizePerPage={sizePerPage}
                  onEdit={this.handleOnEditStateContract}
                  newActivity={this.handleNewActivityLogInContractOnHold}
                  onOpen={this.handleOnOpenContract}
                  onSetPage={this.handleOnSetPage}
                  onExport={this.handleOnExportData}
                  onChangeView={this.handleOnChangeViewContract}
                  onAssignEmployee={this.handleOnAssignEmployee}
                  buttonFunction={this.handleCreateContractException}
                />
              </>
            )}
          </Spin>
        </Container>
      </>
    );
  }
}

ListContracts.defaultProps = {
  name: "Lista de Contratos",
  parentName: "Contratos On Hold",
  cols: [
    { key: "contractNumber", name: "# Contrato", type: "number" },
    { key: "country", name: "País", type: "text" },
    { key: "customerName", name: "Cliente", type: "text" },
    { key: "externalReference", name: "Refencia Externa", type: "text" },
    { key: "startDate", name: "Fecha Inicio", type: "date" },
    { key: "onHoldDate", name: "Fecha en On Hold", type: "date" },
    { key: "delayed", name: "Delayed", type: "text" },
    { key: "timeDelayed", name: "Time Delayed", type: "number" },
    {
      key: "contractExcepcionButton",
      name: "Aplicar excepción",
      type: "actionButton",
    },
    { key: "service", name: "Servicio", type: "array" },
    { key: "monthlyValue", name: "Valor Mensual", type: "money" },
    { key: "netValue", name: "Valor Contrato", type: "money" },
    { key: "daysSinceOnHold", name: "Días en Espera", type: "number" },
    { key: "targetStartDate", name: "Objetivo de Fecha Inicio", type: "date" },
    { key: "outsourcing", name: "Outsourcing", type: "checkbox" },
    { key: "activityLog", name: "Último Comentario", type: "text" },
    {
      key: "updateStartDateLog",
      name: "Último Comentario Cambio Fecha Inicio",
      type: "text",
    },
    { key: "lastUpdatedAt", name: "Última Actualización", type: "date" },
    { key: "items", name: "Items", type: "array" },
    {
      key: "nameResponsibleFM",
      name: "FM Responsable",
      type: "text",
    },
    {
      key: "nameResponsiblePM",
      name: "PM/Coord Instalación",
      type: "text",
    },
    { key: "btn", name: "Asignar FM/PM", type: "button" },
    { key: "status", name: "Estado", type: "select", editable: true },
  ],
  colsFilter: [
    {
      value: "all",
      label: "Seleccione la columna que deseas filtrar",
      type: "text",
    },
    { value: "contractNumber", label: "# Contrato", type: "text" },
    { value: "country", label: "País", type: "select" },
    { value: "customerName", label: "Cliente", type: "text" },
    { value: "externalReference", label: "Refencia Externa", type: "text" },
    { value: "startDate", label: "Fecha Inicio", type: "date" },
    { value: "services", label: "Servicio", type: "select" },
    { value: "monthlyValue", label: "Valor Mensual", type: "number" },
    { value: "netValue", label: "Valor Contrato", type: "number" },
    { value: "daysSinceOnHold", label: "Días en Espera", type: "number" },
    {
      value: "targetStartDate",
      label: "Objetivo de Fecha Inicio",
      type: "date",
    },
    { value: "status", label: "Comentario", type: "select" },
    { value: "nameResponsibleFM", label: "FM Responsable", type: "select" },
    {
      value: "nameResponsiblePM",
      label: "PM/Coord Instalación",
      type: "select",
    },
    { value: "delayed", label: "Delayed", type: "select" },
    { value: "timeDelayed", label: "Time Delayed", type: "number" },
  ],
  rows: [],
  states: [],
  activityLogs: [],
  users: [],
  targetStartLogs: [],
};

ListContracts.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  activityLogs: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  targetStartLogs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  rows: getContractsOnHold(state),
  states: getStatusAvailable(state),
  activityLogs: getActivityFlowsByContractID(state),
  services: getServicesAvailable(state),
  users: getAllUsersFMAndPM(state),
  targetStartLogs: getTargetStartLogsByContractID(state),
});

export default withRouter(
  connect(mapStateToProps, {
    findAllContractsOnHold,
    findAllStatusAvailable,
    findActivityLogsByContractID,
    createActivityLogByContractOnHoldID,
    updateStatusContractOnHoldByID,
    updateTargetStartDateContractOnHoldByID,
    downloadContractsOnHold,
    findAllUsersFMandPM,
    createUserFMPMAssign,
    createContractException,
  })(ListContracts)
);
