import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";


const Filters = ({ jsonFilters, setFilter, optionsFilters, filters, handleOnChangeTable, thisTable, rol }) => {

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="d-flex justify-content-between">
                        <Col sm="12" md="8">
                            <h3 className="mb-0">Busqueda Avanzada</h3>
                            <p className="text-sm mb-0">
                                Busqueda personalizada en los registros
                            </p>
                        </Col>
                        <Col
                            sm="12" md="2"
                            className="p-1"
                        >
                            {
                                rol !== "BS Ventas" &&
                                (
                                    <Button
                                        className="btn-icon sm"
                                        color={thisTable === "reportUS" ? "success" : "info"}
                                        block
                                        type="button"
                                        onClick={thisTable === "reportUS" ? () => handleOnChangeTable("ordesAssign") : () => handleOnChangeTable("reportUS")}
                                    >
                                        <span className="btn-inner--icon mr-">
                                            <i className="fas fa-copy"></i>
                                        </span>
                                        <span className="btn-inner--text" style={{ whiteSpace: "normal", textAlign: "justify" }}>{thisTable === "reportUS" ? "Ordenes pendientes de asignación" : "Detalles de ordenes para inventario"}</span>
                                    </Button>
                                )

                            }

                        </Col>

                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {jsonFilters.map((item, key) => (
                            item.type === "select" ?
                                <Col key={key} className="text-left" xs="12" md={item.md}>
                                    <FormGroup>
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className="form-control-label"
                                        >
                                            {item.label}:
                                        </label>
                                        <Select
                                            key={key}
                                            className="basic-single is-invalid"
                                            type="select"
                                            isSearchable
                                            options={[{ value: 0, label: "Todos" }, ...optionsFilters[item.name]]}
                                            onChange={(e) => filters(item.value, e.value)}
                                        />
                                        <div className="invalid-feedback">
                                            Este campo no puede estar vacio
                                        </div>
                                    </FormGroup>
                                </Col>
                                :
                                <Col key={key} className="text-left" xs="12" md={item.md}>
                                    <FormGroup>
                                        <label className="form-control-label">
                                            {item.label}:
                                        </label>
                                        <Input
                                            key={key}
                                            className="m-0"
                                            type={item.type}
                                            onChange={(e) => filters(item.value, e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default Filters;