import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const findAllContractsOnHold = createAction(
  constants.GET_ALL_CONTRACTS_ON_HOLD,
  (type, body) =>
    method.post(`${urls.SECOH.getAllContractsOnHold}/${type}`, body)()
);

export const findAllDataMasterByStatus = createAction(
  constants.GET_ALL_DATA_MASTER_BY_STATUS,
  (type) => method.post(`${urls.SECOH.getAllDataMasterByStatus}/${type}`)()
);

export const findAllUsersFMandPM = createAction(
  constants.GET_ALL_USER_FM_AND_PM,
  method.get(urls.SECOH.getAllUsersFMandPM)
);

export const findAllStatusAvailable = createAction(
  constants.GET_ALL_STATUS_AVAILABLE,
  method.get(urls.SECOH.getAllStatusAvailable)
);

export const findActivityLogsByContractID = createAction(
  constants.GET_ACTIVITY_FLOW_BY_CONTRACT_ID,
  (id) => method.get(`${urls.SECOH.getActivityLogsByContractID}/${id}`)()
);

export const findUsersNotificationMatrix = createAction(
  constants.GET_USERS_NOTIFICATION_ESCALATIONS,
  method.get(urls.SECOH.getUsersNotificationMatrix)
);

export const findUsersEscalationMatrix = createAction(
  constants.GET_USERS_ESCALATIONS,
  method.get(urls.SECOH.getUsersEscalationMatrix)
);

export const findDashboardContractsOnHoldByType = createAction(
  constants.GET_DASHBOARD_CONTRACTS_ON_HOLD_BY_TYPE,
  (type, body) =>
    method.post(
      `${urls.SECOH.getDashboardContractsOnHoldByType}/${type}`,
      body
    )()
);

export const findContractGraphDetail = createAction(
  constants.GET_CONTRACTS_GRAPH_DETAIL,
  (body) => method.post(`${urls.SECOH.getContractGraphDetail}`, body)()
);

export const findContractGraphDetailByCountry = createAction(
  constants.GET_CONTRACTS_GRAPH_DETAIL_BY_COUNTRY,
  (country, body) =>
    method.post(
      `${urls.SECOH.getContractGraphDetailByCountry}/${country}`,
      body
    )()
);

export const createActivityLogByContractOnHoldID = createAction(
  constants.CREATE_ACTIVITY_LOG_BY_CONTRACT_ID,
  (id, body) =>
    method.post(
      `${urls.SECOH.createActivityLogByContractOnHoldID}/${id}`,
      body
    )()
);

export const createUserNotification = createAction(
  constants.CREATE_USER_ESCALATION_NOTIFICATION,
  (body) => method.post(`${urls.SECOH.createUserNotification}`, body)()
);

export const createUserEscalation = createAction(
  constants.CREATE_USER_ESCALATION,
  (body) => method.post(`${urls.SECOH.createUserEscalation}`, body)()
);

export const createStatusContractOnHold = createAction(
  constants.CREATE_STATUS_CONTRACT_ON_HOLD,
  (body) => method.post(`${urls.SECOH.createStatusContractOnHold}`, body)()
);

export const createDataMaster = createAction(
  constants.CREATE_DATA_MASTER,
  (body) => method.post(`${urls.SECOH.createDataMaster}`, body)()
);

export const updateStatusContractOnHoldByID = createAction(
  constants.UPDATE_STATUS_CONTRACT_ON_HOLD_BY_ID,
  (id, idState, body) =>
    method.put(
      `${urls.SECOH.updateStatusContractOnHoldByID}/${id}/${idState}`,
      body
    )()
);

export const updateTargetStartDateContractOnHoldByID = createAction(
  constants.UPDATE_TARGET_START_DATE_CONTRACT_ON_HOLD,
  (id, body) =>
    method.put(
      `${urls.SECOH.updateTargetStartDateContractOnHoldByID}/${id}`,
      body
    )()
);

export const updateUserEscalationNotification = createAction(
  constants.UPDATE_USER_ESCALATION_NOTIFICATION_BY_ID,
  (id, body) =>
    method.put(`${urls.SECOH.updateUserEscalationNotification}/${id}`, body)()
);

export const updateUserEscalationByID = createAction(
  constants.UPDATE_USER_ESCALATION_NOTIFICATION_BY_ID,
  (id, body) =>
    method.put(`${urls.SECOH.updateUserEscalationByID}/${id}`, body)()
);

export const updateStatusByID = createAction(
  constants.UPDATE_STATUS_BY_ID,
  (id, body) => method.put(`${urls.SECOH.updateStatusByID}/${id}`, body)()
);

export const deactivateUserEscalationNotificationByID = createAction(
  constants.DEACTIVATE_USER_ESCALATION_NOTIFICATION_BY_ID,
  (id) =>
    method.put(`${urls.SECOH.deactivateUserEscalationNotificationByID}/${id}`)()
);

export const deactivateUserEscalationByID = createAction(
  constants.DEACTIVATE_USER_ESCALATION_NOTIFICATION_BY_ID,
  (id) => method.put(`${urls.SECOH.deactivateUserEscalationByID}/${id}`)()
);

export const deactivateStatusByID = createAction(
  constants.DEACTIVATE_STATUS_BY_ID,
  (id) => method.put(`${urls.SECOH.deactivateStatusByID}/${id}`)()
);

export const updateDataMasterById = createAction(
  constants.UPDATE_DATA_MASTER_BY_ID,
  (id, body) => method.put(`${urls.SECOH.updateDataMasterById}/${id}`, body)()
);

export const updateStatusDataMasterByID = createAction(
  constants.DEACTIVATE_DATA_MASTER_BY_ID,
  (id, status) =>
    method.put(`${urls.SECOH.updateStatusDataMasterByID}/${id}/${status}`)()
);

export const validateGbmCollaborator = createAction(
  constants.VALIDATE_GBM_COLLABORATOR,
  (body) => method.post(`${urls.SECOH.validateGbmCollaborator}`, body)()
);

export const downloadContractsOnHold = createAction(
  constants.DOWNLOAD_PARTS_BY_MODEL_IN_QUOTE,
  (body) =>
    method.downloadDataTables(`${urls.SECOH.downloadContractsOnHold}`, body)()
);

export const createUserFMPMAssign = createAction(
  constants.CREATE_USER_FM_PM_ASSIGN,
  (id, body) => method.post(`${urls.SECOH.createUserFMPMAssign}/${id}`, body)()
);

export const findAllUsersAccessOnHold = createAction(
  constants.GET_ALL_USERS_ACCESS_ON_HOLD,
  method.get(urls.SECOH.getAllUsersAccessOnHold)
);

export const deactivateAccessUserOnHoldByID = createAction(
  constants.DEACTIVATE_ACCESS_USER_ON_HOLD_BY_ID,
  (id) => method.put(`${urls.SECOH.deactivateAccessUserOnHoldByID}/${id}`)()
);

export const createAccessUserOnHold = createAction(
  constants.CREATE_ACCESS_USER_ON_HOLD,
  (body) => method.post(`${urls.SECOH.createAccessUserOnHold}`, body)()
);

export const editAccessUserOnHold = createAction(
  constants.EDIT_ACCESS_USER_ON_HOLD,
  (body) => method.post(`${urls.SECOH.editAccessUserOnHold}`, body)()
);

export const createContractException = createAction(
  "CONTRACT_EXCEPTION",
  (body) => method.post(`${urls.SECOH.createContractException}`, body)()
);
