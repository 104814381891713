import { createAction } from "redux-actions";
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";

export const transactionCount = createAction(
  constants.WMS_DASHBOARD_COUNT_TRANSACTIONS,
  method.get(urls.WMS.transactionCount)
);

export const loginTotals = createAction(
  constants.WMS_DASHBOARD_LOGIN_TOTALS,
  method.get(urls.WMS.loginTotals)
);

export const mostExecutedTransaction = createAction(
  constants.WMS_DASHBOARD_MOST_EXECUTED,
  method.get(urls.WMS.mostExecutedTransaction)
);

export const executedTotals = createAction(
  constants.WMS_DASHBOARD_EXECUTED_TOTALS,
  method.get(urls.WMS.executedTotals)
);

export const movementsTotals = createAction(
  constants.WMS_DASHBOARD_MOVEMENT_TOTALS,
  method.get(urls.WMS.movementsTotals)
);

export const locationsTotals = createAction(
  constants.WMS_DASHBOARD_LOCATION_TOTALS,
  method.get(urls.WMS.locationsTotals)
);

export const monthsTotals = createAction(
  constants.WMS_DASHBOARD_MONTHS_TOTALS,
  method.get(urls.WMS.monthsTotals)
);

export const daysTotals = createAction(
  constants.WMS_DASHBOARD_DAYS_TOTALS,
  method.get(urls.WMS.daysTotals)
);

// export const monthTotals = createAction(
//   constants.GET_NODES,
//   method.get(urls.PLANNINGMRS.getNodes)
// );
