import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { insertInterview } from "actions/exitInterview.jsx";
import { sendEmailToUser, sendEmailToHCM } from "actions/exitInterview.jsx";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Col,
  Row,
  UncontrolledTooltip
} from "reactstrap";

const SaveAndDraftButtons = ({ Toast, setAlert, info, setInfo }) => {

  const dispatch = useDispatch();
  const disableButton = info.user && info.countriesData && info.user !== "" && info.collaboratorName !== "" && info.exitType ? false : true
  const handleOnInterview = () => {
    info["endDate"] = new Date().toISOString().split('T')[0]
    dispatch(insertInterview({ info: info })).then((resp) => {
      const { payload } = resp;
      if (resp.payload.isAxiosError) {
        Toast.fire({
          title: "Atención",
          html: `${resp.payload.response.data.payload.message}`,
          type: "warning",
        });
        setAlert("");
      } else {
        if (payload.status === 200) {
          Toast.fire({
            title: "Atención",
            html: "Se guardó correctamente",
            type: "success",
          });
          dispatch(sendEmailToHCM({ info: info, complete: true })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              //good
            }
          });
          setAlert("");
        }
      }
    });
    setInfo({
      collaboratorName: "",
      department: "",
      immediateBoss: "",
      timeWorked: "",
      idCollaborator: "",
      benefitsJob: null,
      specify: "",
      date: new Date().toISOString().split('T')[0]
    });
  };
  const handleOnDraftInterview = () => {
    info["status"] = 3;
    dispatch(insertInterview({ info: info })).then((resp) => {
      const { payload } = resp;
      if (resp.payload.isAxiosError) {
        Toast.fire({
          title: "Atención",
          html: `${resp.payload.response.data.payload.message}`,
          type: "warning",
        });
        setAlert("");
        const forms = document.querySelectorAll(".isValid input, select");
        Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
        setInfo({
          collaboratorName: "",
          department: "",
          immediateBoss: "",
          timeWorked: "",
          idCollaborator: "",
          benefitsJob: "",
          specify: "",
          date: new Date().toISOString().split('T')[0]
        });
      } else {
        if (payload.status === 200) {
          Toast.fire({
            title: "Atención",
            html: "Se guardó como borrador",
            type: "success",
          });
          setAlert("");
          const forms = document.querySelectorAll(".isValid input, select");
          Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
          setInfo({
            collaboratorName: "",
            department: "",
            immediateBoss: "",
            timeWorked: "",
            idCollaborator: "",
            benefitsJob: "",
            specify: "",
            date: new Date().toISOString().split('T')[0]
          });
        }
      }
    });
  };
  const handleOnSendCollaboratorInterview = () => {
    info["status"] = 1;
    dispatch(insertInterview({ info: info })).then((resp) => {
      const { payload } = resp;
      if (resp.payload.isAxiosError) {
        Toast.fire({
          title: "Atención",
          html: `${resp.payload.response.data.payload.message}`,
          type: "warning",
        });
        setAlert("");
        const forms = document.querySelectorAll(".isValid input, select");
        Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
        setInfo({
          collaboratorName: "",
          department: "",
          immediateBoss: "",
          timeWorked: "",
          idCollaborator: "",
          benefitsJob: "",
          specify: "",
          date: new Date().toISOString().split('T')[0]
        });
      } else {
        if (payload.status === 200) {
          Toast.fire({
            title: "Atención",
            html: "Se envio el formulario al colaborador",
            type: "success",
          });
          setAlert("");
          const forms = document.querySelectorAll(".isValid input, select");
          Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
          dispatch(sendEmailToUser({  user: info.user  })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
              //email enviado
            }
          });
          setInfo({
            collaboratorName: "",
            department: "",
            immediateBoss: "",
            timeWorked: "",
            idCollaborator: "",
            benefitsJob: "",
            specify: "",
            date: new Date().toISOString().split('T')[0]
          });
        }
      }
    });
  }
  const handleOnSaveInterview = (type) => {
    let cont = 0;
    if (type === "interview") {
      const forms = document.querySelectorAll(".isValid input, select");
      Array.from(forms).forEach((input) => {
        if (!info.exitReason || (info.exitReason && !info.exitReason.length)) {
          document.getElementsByClassName("css-yk16xz-control")[0].setAttribute("style", "border-color:#fb6340;");
          input.classList.add("is-invalid");
          cont++;
        } else {
          document.getElementsByClassName("css-yk16xz-control")[0].removeAttribute("style");
        }
        if (input.required === true && (input.value === "" || input.value === "---")) {
          input.classList.add("is-invalid");
          cont++;
        } else {
          input.classList.remove("is-invalid");
        }

      }

      );
    }
    if (cont === 0) {
      setAlert(
        <ReactBSAlert
          custom
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title={
            type == "draft" ?
              "¿Está seguro(a) que desea guardar la entrevista en borrador ?"
              : type == "sendToCollab" ? "¿Está seguro(a) que desea enviar la entrevista al colaborador?"
                : "¿Está seguro(a) que desea guardar la entrevista?"
          }
          customIcon={
            <div
              className="swal2-icon swal2-question swal2-animate-question-icon"
              style={{ display: "flex" }}
            >
              <span className="swal2-icon-text">?</span>
            </div>
          }
          onConfirm={
            type == "draft" ? () => handleOnDraftInterview()
              : type == "sendToCollab" ? () => handleOnSendCollaboratorInterview()
                : () => handleOnInterview()
          }
          onCancel={() => setAlert("")}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sí, guardar"
          cancelBtnText="No, cancelar"
          btnSize="md"
        />
      );
    }
  };
  const buttons = [
    { text: "Guardar Entrevista Completa", id: "btn1", color: "primary", onclick: () => handleOnSaveInterview("interview"), icon: "fas fa-check-circle", textHover: "Completar entrevista.", xs: "12", md: "4" },
    { text: "Guardar como borrador", id: "btn2", color: "secondary", onclick: () => handleOnSaveInterview("draft"), icon: "fas fa-eraser", textHover: "Guardar cambios en borrador", xs: "12", md: "4" },
    { text: "Enviar formulario a Colaborador", id: "btn3", color: "success", onclick: () => handleOnSaveInterview("sendToCollab"), icon: "fas fa-file-import", textHover: "Envia la entrevista al colaborador para que la complete", xs: "12", md: "4" },
  ];
  console.log(info);
  return (
    <div>

      <Row className="d-justify-content-between">
        {
          buttons.map((item, key) => (
            <Col
              key={key}
              xs={item.xs}
              md={item.md}
              className="p-1"
              style={{ display: "flex", justifyContent: "flex-right" }}
            >
              <Button
                key={key}

                disabled={disableButton}
                className="btn-icon"
                color={item.color}
                block
                onClick={item.onclick}
                type="button"
                id={item.id}
              >
                <span className="btn-inner--icon mr-">
                  <i className={item.icon} />
                </span>
                <span className="btn-inner--text">
                  {item.text}
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target={item.id} key={key}>
                {item.textHover}
              </UncontrolledTooltip>
            </Col>
          ))
        }
      </Row>

    </div>
  );
};

export default SaveAndDraftButtons;
