// react library
import React, { Component, useState, useEffect } from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

import { TbPointFilled } from "react-icons/tb";

import { GiReceiveMoney } from "react-icons/gi";


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      windowWidth: window.innerWidth, // State to track window width
      ...this.getCollapseStates(props.routes),
    };
    this.handleResize = this.handleResize.bind(this); // Bind the resize handler
  }



  componentDidMount() {
    this.closeSidenav();
    // this.props.toggleSidenav();
    window.addEventListener('resize', this.handleResize); // Add resize event listener
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize); // Clean up event listener
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth }); // Update state on resize
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, tempLevel) => {
    return routes.map((prop, key) => {
      if (prop.invisible) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <NavItem className="ml--2" key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: false // this.getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {/* {prop.icon ? <i className={prop.icon} /> : null} */}
              {prop.iconObject ?
                <>

                  <div
                    style={{
                      backgroundColor: "#1a1f37",
                      color: "rgba(0, 0, 0, 0.54)",
                      flexShrink: 0,
                      background: "rgb(26, 31, 55)",
                      minWidth: "2rem",
                      minHeight: "2rem",
                      borderRadius: "0.75rem",
                      display: "grid",
                      placeItems: "center",
                      boxShadow: "rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                      transition: "margin 300ms cubic-bezier(0.4, 0, 0.2, 1)",

                    }}>
                    <prop.iconObject style={{ fill: "rgb(0,99,255)" }} />
                  </div>
                </>

                : tempLevel == 2 ?
                  <TbPointFilled style={{ color: "rgb(0, 99, 255)" }} />

                  :
                  null





              }

              <span className={`nav-link-text ${tempLevel == 3 ? "ml-4" : tempLevel > 3 ? "ml-2" : "ml-2"}`}>{prop.name /*+ tempLevel*/}</span>
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views, tempLevel + 1)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      } else if (prop.level === 3) {
        return (
          <NavItem
            className={this.activeRoute(prop.layout + prop.path)}
            key={key}
          >
            <NavLink
              to={prop.layout + prop.path}
              activeClassName=""
              onClick={this.closeSidenav}
              tag={NavLinkRRD}
              className="ml-4"
              style={{ paddingLeft: "5rem" }}
            >
              <div>


                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <span className="nav-link-text">{prop.name}</span>
                  </>
                ) : (
                  prop.name 
                )}

              </div>
            </NavLink>
          </NavItem>
        );
      } else {
        return (
          <NavItem
            className={this.activeRoute(prop.layout + prop.path)}
            key={key}
          >
            <NavLink
              className="ml-3"
              to={prop.layout + prop.path}
              activeClassName=""
              onClick={this.closeSidenav}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name + "h"}</span>
                </>
              ) : (
                prop.name 
              )}
            </NavLink>
          </NavItem>
        );
      }
    });
  };


  render() {


    const { routes, logo } = this.props;
    const { windowWidth } = this.state; // Get windowWidth from state
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center justify-content-center mt-1 p-0">
          {logo ? (

            <div >

              <NavbarBrand {...navbarBrandProps} style={{ width: windowWidth <= 600 ? '73%' : '100%', }} className={windowWidth <= 767 ? "ml-4" : "ml-4"}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>


            </div>




          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: this.props.sidenavOpen,
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>

        <div>


          <hr
            className={`mt-2 ${windowWidth <= 767 ? 'mb-2' : 'mb--1'}`}
            style={{
              flexShrink: 0,
              // width: "300px",
              borderWidth: '0px 0px medium',
              borderStyle: 'solid solid none',
              borderColor: 'rgba(0, 0, 0, 0.08) rgba(0, 0, 0, 0.08) currentcolor',
              height: '0.0625rem',
              margin: '0rem 0px',
              opacity: 0.25,
              backgroundColor: 'transparent',
              backgroundImage: 'linear-gradient(to right, rgba(0, 117, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0))',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar >{this.createLinks(routes, 1)} </Nav>
          </Collapse>
        </div>
      </div>
    );

    return (

      <Navbar
        className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-dark bg-gradient-info-navbar"
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
