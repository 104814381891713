import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";



//Traer los usuario de la matriz
export const getUsersMatrix = createAction(constants.USER_CLOUD_GET_USER_MATRIX, method.get(urls.USERCLOUDREQUEST.getAllUserMatrix));
export const createRequest = createAction(constants.USER_CLOUD_CREATE_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.createRequest}`, body)());
export const deleteFile = createAction(constants.USER_CLOUD_REMOVE_FILE, body => method.post(urls.USERCLOUDREQUEST.deleteFile, body)());
export const deleteApproveFile = createAction(constants.USER_CLOUD_REMOVE_APPROVE_FILE, body => method.post(urls.USERCLOUDREQUEST.deleteApproveFile, body)());
export const approveRequestCloud = createAction(constants.CLOUD_APPROVE_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.approveRequest}`, body)());
export const getAllRequest = createAction(constants.CLOUD_GET_ALL_REQUEST, method.get(`${urls.USERCLOUDREQUEST.getAllRequest}`));
export const declineRequestCloud = createAction(constants.CLOUD_DECLINE_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.getDeclineRequest}`, body)());
export const returnRequestCloud = createAction(constants.CLOUD_RETURN_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.getReturnRequest}`, body)());
export const getAllTenant = createAction(constants.CLOUD_GET_ALL_TENANT, method.get(`${urls.USERCLOUDREQUEST.getAllTenant}`));
export const createNewAccessMatriz = createAction(constants.CLOUD_CREATE_ACCESS_MATRIZ, (body) => method.post(`${urls.USERCLOUDREQUEST.createAccessMatriz}`, body)());
export const updateItemAccessMatriz = createAction(constants.CLOUD_UPDATE_ACCESS_MATRIZ, (body) => method.post(`${urls.USERCLOUDREQUEST.updateAccessMatriz}`, body)());
export const deleteItemAccessMatriz = createAction(constants.CLOUD_DELETE_ACCESS_MATRIZ, (id) => method.put(`${urls.USERCLOUDREQUEST.deleteAccessMatriz}/${id}`)());
export const getAllAccessMatrizHistory = createAction(constants.CLOUD_GET_MATRIZ_ACCESS_HISTORY, method.get(urls.USERCLOUDREQUEST.getAccessMAtrizHistory));
export const downloadFileVersionMatriz = createAction(constants.CLOUD_DOWNLOAD_VERSION_MATRIZ, (body) => method.fileDownload(`${urls.USERCLOUDREQUEST.downloadVersionMatriz}/${body}`)());
export const getAllCountries = createAction(constants.CLOUD_GET_COUNTRIES, method.get(urls.USERCLOUDREQUEST.getCountries));
export const getMyRequest = createAction(constants.GET_MY_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.getMyRequest}`, body)());
export const getAllCloudVendor = createAction(constants.CLOUD_GET_ALL_CLOUD_VENDOR, method.get(`${urls.USERCLOUDREQUEST.getAllCloudVendor}`));
export const createCloudVendor = createAction(constants.CLOUD_CREATE_CLOUD_VENDOR, (body) => method.post(`${urls.USERCLOUDREQUEST.createCloudVendor}`, body)());
export const updateCloudVendor = createAction(constants.CLOUD_UPDATE_CLOUD_VENDOR, (body) => method.post(`${urls.USERCLOUDREQUEST.updateCloudVendor}`, body)());
export const deleteCloudVendor = createAction(constants.CLOUD_DELETE_CLOUD_VENDOR, (id) => method.put(`${urls.USERCLOUDREQUEST.deleteCloudVendor}/${id}`)());
export const createTenant = createAction(constants.CLOUD_CREATE_TENANT, (body) => method.post(`${urls.USERCLOUDREQUEST.createTenant}`, body)());
export const updateTenant = createAction(constants.CLOUD_UPDATE_TENANT, (id, body) => method.put(`${urls.USERCLOUDREQUEST.updateTenant}/${id}`, body)());
export const deleteTenant = createAction(constants.CLOUD_DELETE_TENANT, (id) => method.put(`${urls.USERCLOUDREQUEST.deleteTenant}/${id}`)());
export const getTenantsByCloud = createAction(constants.CLOUD_GET_TENANTS_BY_CLOUD, (id) => method.get(`${urls.USERCLOUDREQUEST.getTenantsByCloud}/${id}`)());
export const downloadFileRequesById = createAction(constants.CLOUD_DOWNLOAD_FILE_REQUEST, (body) => method.fileDownload(`${urls.USERCLOUDREQUEST.downloadFileRequest}/${body}`)());
export const updateRequest = createAction(constants.CLOUD_UPDATE_REQUEST, (body) => method.post(`${urls.USERCLOUDREQUEST.updateRequest}`, body)());