// core view Sales
// import PartsManagementPages from "views/SalesPages/CriticalParts/Index.jsx";
// import DigitalRequirementsPages from "views/SalesPages/Requirements/Index.jsx";
// import CreateRequirement from "views/SalesPages/Requirements/CreateRequirement.jsx";
// import MatrixCriticalParts from "views/SalesPages/CriticalParts/Matrix.jsx";
// core views DigitalSignaturePages
import DashboardDigitalSignature from "views/DigitalSignature/Dashboard.jsx";
import DigitalSignature from "views/DigitalSignature/Index.jsx";
import MySignatures from "views/DigitalSignature/MySignatures.jsx";
import RegisterSignature from "views/DigitalSignature/RegisterSignature.jsx";
import DigitalSignatureMaintenance from "views/DigitalSignature/Maintenance.jsx";
// core views ExtraHoursPages
import AccessManagement from "views/ExtraHoursPages/AccessManagement.jsx";
import ExtraHoursDashboardStatus from "views/ExtraHoursPages/ExtraHoursDashboardStatus";
import ExtraHoursPage from "views/ExtraHoursPages/Index";
import Information from "views/ExtraHoursPages/Information.jsx";
import ExtraHoursCeo from "views/ExtraHoursPages/ExtraHoursCeo";
import ExtraHoursMaintenance from "views/ExtraHoursPages/Maintenance.jsx";
// core views Mispages
import Inactive from "views/MISPages/Inactive";
import Mis from "views/MISPages/Index.jsx";
import InternalTeam from "views/MISPages/InternalTeam.jsx";
import Main from "views/MISPages/Main.jsx";
import Maintenance from "views/MISPages/Maintenance.jsx";
import Projects from "views/MISPages/Projects";
// core views NewPositionPages
import UpdateVacantPosition from "views/NewPositionPages/EditVacantPosition.jsx";
import Dashboard from "views/NewPositionPages/History.jsx";
import NewPosition from "views/NewPositionPages/Index.jsx";
import MyRequests from "views/NewPositionPages/MyRequests.jsx";
import OptionalsPositions from "views/NewPositionPages/OptionalsPositions.jsx";
import SupportPosition from "views/NewPositionPages/SupportPosition.jsx";
import WorkFlow from "views/NewPositionPages/WorkFlow.jsx";
import PositionMaintenance from "views/NewPositionPages/Maintenance.jsx";
// core views NotificationsPages
import DashboardUsabilityRecord from "views/UsabilityRecord/Dashboard.jsx";
import DigitalSignatureNotification from "views/Notifications/DigitalSignatureNotification.jsx";
import Notifications from "views/Notifications/Index.jsx";
import NotificationsMain from "views/Notifications/Notifications.jsx";
import SignatureSupervisorFlow from "views/Notifications/Signatures/SupervisorFlow.jsx";
import SignatureCollaboratorFlow from "views/Notifications/Signatures/CollaboratorFlow.jsx";
import TargetLettersHC from "views/Notifications/TargetLetter/TargetLettersHC.jsx";
import TargetLetterUser from "views/Notifications/TargetLetter/TargetLetterUser.jsx";
import TargetLettersHeadship from "views/Notifications/TargetLetter/TargetLettersHeadship.jsx";
import TargetLettersGeneralManager from "views/Notifications/TargetLetter/TargetLettersGeneralManager.jsx";
import TargetLettersManagementServicesDirector from "views/Notifications/TargetLetter/TargetLettersManagementServicesDirector.jsx";
import TargetLettersHCRegional from "views/Notifications/TargetLetter/TargetLettersHCRegional.jsx";
import EngineersFlow from "views/Notifications/Opportunities/EngineersFlow.jsx";
import PlannersFlow from "views/Notifications/Opportunities/PlannersFlow.jsx";
// core views PlanningMRSPages
import PlanningMRS from "views/PlanningMRSPages/Index.jsx";
import Nodes from "views/PlanningMRSPages/Nodes.jsx";
import Resourses from "views/PlanningMRSPages/Resourses.jsx";
import Tasks from "views/PlanningMRSPages/Tasks.jsx";
// core views My Signatures
// import MySignatures from "views/ProfilePage/MySignatures.jsx";
// core views Profile
import ProfileView from "views/ProfilePage/Profile.jsx";
// core views ServiceOrderPages
import AllServiceOrders from "views/ServiceOrderPages/AllServiceOrders.jsx";
import SORoutes from "views/ServiceOrderPages/index.jsx";
import ServiceOrderDetails from "views/ServiceOrderPages/ServiceOrderDetails.jsx";
import UserServiceOrders from "views/ServiceOrderPages/UserServiceOrders.jsx";
// core views WMSPages
import WMSOverview from "views/WMSPages/Overview.jsx";
// core views COE
import ActivitiesReport from "views/CoePages/ActivitiesReport.jsx";
import COE from "views/CoePages/Index.jsx";
import CollaboratorsReport from "views/CoePages/CollaboratorsReport.jsx";
import Indicators from "views/CoePages/Indicators.jsx";
import HistoricalClient from "views/CoePages/HistoricalClient.jsx";
import HoursAccusedReport from "views/CoePages/HoursAccused.jsx";
import COEMaintenance from "views/CoePages/UsersMaintenance.jsx";
import PlannedRealHours from "views/CoePages/PlannedRealHours.jsx";
// core views: Salary approval
import SARoutes from "views/ASPages/Index.jsx";
import SANewRequest from "views/ASPages/newRequest.jsx";
import SAPendingRequests from "views/ASPages/PendingRequest.jsx";
import SACancelledRequests from "views/ASPages/CancelledRequests.jsx";
import SACompleteRequests from "views/ASPages/CompleteRequest.jsx";
import SARequestStatus from "views/ASPages/RequestStatus.jsx";
import SANotifications from "views/ASPages/NotificationsRequest.jsx";
import SAAccessOtherApprovers from "views/ASPages/AccessOthersApprovers";
// import PlannedRealHours from "views/CoePages/PlannedRealHours.jsx"
// core views Target Letters
import DashboardLetters from "views/TargetLetterPages/DashboardLetters.jsx";
import MyTargetLetters from "views/TargetLetterPages/MyTargetLetters.jsx";
import TargetLetter from "views/TargetLetterPages/Index.jsx";
import TargetLetterRequests from "views/TargetLetterPages/TargetLetterRequests.jsx";
// core view Sales
import SupportPages from "views/SalesPages/Support/Index.jsx";
import MasterVariables from "views/SalesPages/Support/MasterVariables.jsx";
import TSSEscalations from "views/SalesPages/Support/TSSEscalations.jsx";
import ManagersPages from "views/SalesPages/Managers/Index.jsx";
import OppsHistory from "views/SalesPages/Managers/OppsHistory.jsx";
import PartsManagementPages from "views/SalesPages/CriticalParts/Index.jsx";
import MatrixCriticalParts from "views/SalesPages/CriticalParts/Matrix.jsx";
import UserMaintenanceParts from "views/SalesPages/CriticalParts/UsersMaintenance";
import CreateRequirement from "views/SalesPages/Requirements/CreateRequirement.jsx";
import DigitalRequirementsPages from "views/SalesPages/Requirements/Index.jsx";
import MyRequestsLDR from "views/SalesPages/Requirements/MyRequests.jsx";
import InventoriesManagementPages from "views/SalesPages/Inventories/Index.jsx";
import Quotes from "views/SalesPages/Inventories/Quotes.jsx";
import PricingPages from "views/SalesPages/Pricing/Index.jsx";
import MasterData from "views/SalesPages/Pricing/MasterData.jsx";
import OffersAjustment from "views/SalesPages/Pricing/OffersAjustment.jsx";
import PlannersPages from "views/SalesPages/Planners/Index.jsx";
import PlannersAssignmentManager from "views/SalesPages/Planners/AssignmentManager.jsx";
// core view Medical Records
import MedicalRecord from "views/MedicalRecords/Index.jsx";
import Records from "views/MedicalRecords/Records.jsx";
import RecordsMaintenance from "views/MedicalRecords/Maintenance.jsx";
import DashboardRecords from "views/MedicalRecords/Dashboard.jsx";
import contractDashBoard from "views/SalesPages/Managers/contractDashBoard.jsx";
// import plannersDashBoard from "views/SalesPages/planners/plannersDashBoard.jsx"

// core views: Finance Request
import FFRoutes from "views/FinanceFlows/Index.jsx";
import FFNotifications from "views/FinanceFlows/ApprovalFlows/MyFlows.jsx";
import FFRequests from "views/FinanceFlows/ApprovalFlows/Requests.jsx";
import FFRequestDetails from "views/FinanceFlows/ApprovalFlows/RequestDetailsLegacy.jsx";
import FFHistoryRequest from "views/FinanceFlows/ApprovalFlows/RequestsLegacy.jsx";
import FFNewRequest from "views/FinanceFlows/ApprovalFlows/NewRequest";
import FFDocumentAdmin from "views/FinanceFlows/administration/Documents.jsx";
import FFGeneraldmin from "views/FinanceFlows/administration/GeneralConfiguration.jsx";
import FFApprovalMapping from "views/FinanceFlows/administration/ApprovalTemplate.jsx";
import FFApprovalRoles from "views/FinanceFlows/administration/ApprovalRoles.jsx";
import FFRequestUpdate from "views/FinancePages/UpdateRequest.jsx";
import FFSettingsProfile from "views/FinanceFlows/administration/SettingsProfile.jsx";
import FFMyRequest from "views/FinanceFlows/ApprovalFlows/MyRequests.jsx";

//core views: Contacts
import Contacts from "views/Contacts/Index.jsx";
import HistoryContacts from "views/Contacts/HistoryContacts";
import CustomersList from "views/Contacts/CustomersList";

//core views: Incidents Reports
import IncidentsReports from "views/IncidentsReports/Index.jsx";
import IncidentsReportsAdmin from "views/IncidentsReports/admin.jsx";

// core views: Document System
import DocumentSystem from "views/DocumentSystem/Index.jsx";
import SDHome from "views/DocumentSystem/Home.jsx";

// core views SECOH
import SECOH from "views/SECOH/Index.jsx";
import DashboardSecoh from "views/SECOH/Dashboard.jsx";
import ContractsList from "views/SECOH/ListContracts.jsx";
import StatusMaintenance from "views/SECOH/StatusMaintenance.jsx";
import ServicesManagement from "views/SECOH/ServicesManagement.jsx";
import MatrixEscalations from "views/SECOH/MatrixEscalations.jsx";
import MatrixNotifications from "views/SECOH/MatrixNotifications.jsx";
import UsersMaintenanceOnHold from "views/SECOH/UsersMaintenance.jsx";
//core views: HCM Hiring
import HCMHiring from "views/HCMHiringPages/Index.jsx";
import HCMH from "views/HCMHiringPages/Inicio.jsx";

// core views PanamaBids
import PanamaBids from "views/PanamaBids/Index.jsx";
import Agreement from "views/PanamaBids/Agreement.jsx";
import Entities from "views/PanamaBids/Entities.jsx";
import Competition from "views/PanamaBids/Competition.jsx";
import QuickQuotes from "views/PanamaBids/QuickQuotes.jsx";

// core views CostaRicaBids
import AgreementCR from "views/CostaRicaBids/AgreementCR.jsx";
import CostaRicaBids from "views/CostaRicaBids/Index.jsx";

// core views ExitInterviews
import ExitInterview from "views/ExitInterview/Index.jsx";
import DigitalForm from "views/ExitInterview/DigitalForm.jsx";
import CategoryManagement from "views/ExitInterview/CategoryManagement.jsx";
import HistoryQuery from "views/ExitInterview/HistoryQuery.jsx";
import Dashboards from "views/ExitInterview/Dashboards.jsx";
import CompleteInterview from "views/ExitInterview/CompleteInterview.jsx";
import ExitInterviewManagement from "views/ExitInterview/AccessManagement";
// core views Autopp
import AutoppStart from "views/AutoppLdrs/AutoppStart.jsx";
import AutoppCare from "views/AutoppLdrs/AutoppCare.jsx";
import AutoppLdrs from "views/AutoppLdrs/Index.jsx";

// core know employee
import CompleteFormKnowEmployee from "views/Notifications/KnowEmployee/CompleteForm.jsx";
import KnowEmployee from "views/KnowEmployee/Index.jsx";
import MyFormDataKnowEmployee from "views/KnowEmployee/MyFormData.jsx";
import Forms from "views/KnowEmployee/Forms.jsx";

// core Conflict Interests Form
import ConflictInterestsForm from "views/Notifications/ConflictInterests/FormConflictInterests";
import ConflictInterests from "views/ConflictInterests/Index.jsx";
import FormsCompleteConflictsInterest from "views/ConflictInterests/FormsComplete.jsx";
import ConflictInterestMain from "views/Notifications/ConflictInterests/ConflictInterests.jsx";

// core views MasterData
import HomeMasterData from "views/NewMasterData/HomeMasterData.jsx";
import MyRequestsMasterData from "views/NewMasterData/MyRequestsMasterData.jsx";
import ApprovalsMasterData from "views/NewMasterData/ApprovalsMasterData.jsx";
import AllRequestsMasterData from "views/NewMasterData/AllRequestsMasterData.jsx";
import NewMasterData from "views/NewMasterData/Index.jsx";

//Business System
import BusinessSystem from "views/BusinessSystem/Index.jsx";
import BusinessSystemHome from "views/BusinessSystem/Home.jsx";
import BusinessSystemManagement from "views/BusinessSystem/AccessManagement";

// Core views Freelance
import HomeFreelance from "views/Freelance/Home.jsx";
import Freelance from "views/Freelance/Index.jsx";

// core views Databot
import Databot from "views/Databot/Index.jsx";
import DatabotOrchestrator from "views/Databot/Orchestrator.jsx";
import DatabotStats from "views/Databot/Stats.jsx";
import MaintenanceWindowMasterData from "views/NewMasterData/MaintenanceWindowMasterData";

//Core ItRequest
import ItRequestIndex from "views/ItRequests/Index.jsx";
import ItRequestHome from "views/ItRequests/Home.jsx";

//Core HoursReport
import ReportHoursIndex from "views/HoursReport/Index.jsx";
import ReportHoursHome from "views/HoursReport/Home.jsx";
import ExtraHoursReport from "views/Notifications/ExtraHoursReport/ExtraHoursReport";
//Core view user cloud request
import UCRequest from "views/UserCloudRequest/AllRequests"
import UCApprovals from "views/UserCloudRequest/ApprovalsRequests"
import UCMyrequest from "views/UserCloudRequest/MyRequests.jsx"
import UCIndex from "views/UserCloudRequest/index.jsx"
import UCAccessRol from "views/UserCloudRequest/AccessRol"
import UCAceesMatriz from "views/UserCloudRequest/AccessMatriz"
//Core view user cloud request admin
import UCAIndex from "views/UserCloudRequest/AdminUserCloudRequest/index.jsx"
import UCATenant from "views/UserCloudRequest/AdminUserCloudRequest/AdminTenant.jsx"
import UCAProvider from "views/UserCloudRequest/AdminUserCloudRequest/AdminProviderCloud.jsx"

import { GiReceiveMoney } from "react-icons/gi";
import { TbCloudNetwork } from "react-icons/tb";
import { TiContacts } from "react-icons/ti";
import { GoGraph } from "react-icons/go";
import { IoPeople } from "react-icons/io5";

const AdminRoutes = [
  {
    path: "/notifications",
    name: "Notificaciones",
    icon: "ni ni-app text-white",
    iconObject: GiReceiveMoney,
    component: Notifications,
    invisible: true,
    layout: "/admin",
  },
  {
    path: "/hours-report",
    name: "Reporte de horas",
    icon: "ni ni-app text-white",
    iconObject: GiReceiveMoney,
    component: ReportHoursIndex,
    invisible: true,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "GBM Digital",
    icon: "ni ni-app text-white",
    iconObject: TbCloudNetwork ,
    state: "misCollapse",
    views: [
      {
        path: "/about-us",
        name: "¿Quienes Somos?",
        component: Mis, // UserServiceOrders,
        layout: "/admin/mis",
      },
      {
        path: "/internal-equipment",
        name: "Equipo Interno",
        component: Mis, //AllServiceOrders,
        layout: "/admin/mis",
      },
      {
        invisible: true,
        path: "/mis",
        name: "",
        component: Mis, //AllServiceOrders,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Services",
    icon: "ni ni-collection text-white",
    iconObject: TiContacts,
    state: "servicesCollapse",
    views: [
      {
        collapse: true,
        name: "COE",
        state: "coeCollapse",
        views: [
          {
            path: "/planned-real-hours",
            name: "Horas Planeadas vs Horas Reales",
            component: COE,
            layout: "/admin/coe",
          },
          {
            path: "/historical-client",
            name: "Histórico por Cliente",
            component: COE,
            layout: "/admin/coe",
          },
          {
            path: "/indicators",
            name: "Indicadores",
            component: COE,
            layout: "/admin/coe",
          },
          {
            path: "/collaborators",
            name: "Colaboradores",
            component: COE,
            layout: "/admin/coe",
          },
          {
            path: "/hours-accused",
            name: "Horas Imputadas",
            component: COE,
            layout: "/admin/coe",
          },
          {
            path: "/activities-report",
            name: "Actividades",
            component: COE,
            layout: "/admin/coe",
          },
          {
            invisible: true,
            path: "/coe",
            name: "",
            component: COE,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/planning-board",
        name: "Planning Board",
        component: PlanningMRS,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Administración de reportes de IN & SR",
        state: "incidentsReportsCollapse",
        views: [
          {
            path: "/incidents-reports",
            name: "Administración de reportes de IN & SR",
            component: IncidentsReportsAdmin,
            layout: "/admin/incidentsReports",
          },
          {
            invisible: true,
            path: "/incidentsReports",
            name: "",
            component: IncidentsReports,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Boletas de Servicio",
        // icon: "ni ni-archive-2 text-white",
        state: "serviceOrdersCollapse",
        views: [
          {
            path: "/service-orders/me",
            name: "Mis Boletas",
            component: SORoutes, // UserServiceOrders,
            layout: "/admin",
          },
          {
            path: "/service-orders",
            name: "Todas las Boletas",
            component: SORoutes, //AllServiceOrders,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "WMS",
        // icon: "ni ni-archive-2 text-white",
        state: "wmsCollapse",
        views: [
          {
            path: "/wms/overview",
            name: "Dashboard",
            component: WMSOverview, // UserServiceOrders,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Expediente Médico",
        state: "medicalRecordsCollapse",
        views: [
          {
            path: "/dashboard-records",
            name: "Dashboard",
            component: MedicalRecord,
            layout: "/admin/medical-record",
          },
          {
            path: "/records",
            name: "Expedientes",
            component: MedicalRecord,
            layout: "/admin/medical-record",
          },
          {
            invisible: true,
            path: "/medical-record",
            name: "",
            component: MedicalRecord,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Contactos",
        state: "contactsCollapse",
        views: [
          {
            path: "/history-list",
            name: "Estadisticas",
            component: HistoryContacts,
            layout: "/admin/contacts",
          },
          {
            path: "/customers-list",
            name: "Lista de Clientes",
            component: CustomersList,
            layout: "/admin/contacts",
          },
          {
            invisible: true,
            path: "/contacts",
            name: "",
            component: Contacts,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Conflicto de Intereses con Proveedores",
        state: "conflictsInterestsCollapse",
        views: [
          {
            path: "/forms-complete",
            name: "Formularios Completos",
            component: ConflictInterests,
            layout: "/admin/conflicts-interests",
          },
          {
            invisible: true,
            path: "/conflicts-interests",
            name: "",
            component: ConflictInterests,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Datos Maestros",
        state: "masterDataCollapse",
        views: [
          {
            path: "/masterdata",
            name: "Inicio",
            component: HomeMasterData,
            layout: "/admin/masterdata",
          },
          {
            path: "/MyRequestsMasterData",
            name: "Mis Gestiones",
            component: MyRequestsMasterData,
            layout: "/admin/masterdata",
          },
          {
            path: "/ApprovalsMasterData",
            name: "Aprobaciones",
            component: ApprovalsMasterData,
            layout: "/admin/masterdata",
          },
          {
            path: "/AllRequestsMasterData",
            name: "Todas las Gestiones",
            component: AllRequestsMasterData,
            layout: "/admin/masterdata",
          },
          {
            path: "/MaintenanceWindowMasterData",
            name: "Vista de Mantenimiento",
            component: MaintenanceWindowMasterData,
            layout: "/admin/masterdata",
          },
          // {
          //   path: "/customers-list",
          //   name: "Lista de Clientes",
          //   component: CustomersList,
          //   layout: "/admin/contacts",
          // },
          {
            invisible: true,
            path: "/masterdata",
            name: "",
            component: NewMasterData,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Portal Proveedores",
        state: "freelanceCollapse",
        views: [
          {
            path: "/freelance",
            name: "Inicio",
            component: Freelance,
            layout: "/admin/freelance",
          },
          {
            invisible: true,
            path: "/freelance",
            name: "",
            component: Freelance,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        parent: true,
        name: "Databot",
        state: "DatabotCollapse",
        views: [
          {
            collapse: true,
            name: "Orquestador",
            state: "orchestratorDtBCollapse",
            views: [
              {
                level: 3,
                path: "/Databot/orchestrator",
                name: "Inicio",
                component: DatabotOrchestrator,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Estadísticas",
            state: "statsDtBCollapse",
            views: [
              {
                level: 3,
                path: "/Databot/stats",
                name: "Inicio",
                component: DatabotStats,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Solicitudes de TI",
        state: "itRequestsCollapse",
        views: [
          {
            path: "/itrequests",
            name: "Inicio",
            component: ItRequestHome,
            layout: "/admin/itrequests",
          },
          {
            invisible: true,
            path: "/itrequests",
            name: "",
            component: ItRequestIndex,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Human Capital",
    icon: "ni ni-archive-2 text-white",
    iconObject: IoPeople,
    state: "humanCapitalCollapse",
    views: [
      {
        collapse: true,
        name: "Nueva Posición",
        state: "newPositionCollapse",
        views: [
          {
            path: "/dashboard-positions",
            name: "Dashboard",
            component: NewPosition,
            layout: "/admin/new-position",
          },
          {
            path: "/requests",
            name: "Crear Solicitud",
            component: NewPosition,
            layout: "/admin/new-position",
          },
          {
            path: "/my-requests",
            name: "Mis Solicitudes",
            component: NewPosition,
            layout: "/admin/new-position",
          },
          {
            invisible: true,
            path: "/new-position",
            name: "",
            component: NewPosition,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        parent: true,
        name: "Entrevistas de Salida",
        state: "ExitInterviewCollapse",
        views: [
          {
            collapse: true,
            name: "Administración de Categorías",
            state: "requirementsCollapse",
            views: [
              {
                level: 3,
                path: "/exit-Interview/category-management",
                name: "Manejo de Categorías",
                component: ExitInterview,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/exit-Interview",
                name: "",
                component: ExitInterview,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Gestión",
            state: "gestionCollapse",
            views: [
              {
                level: 3,
                path: "/exit-Interview/digital-form",
                name: "Formulario Digital",
                component: ExitInterview,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/exit-Interview/history-query",
                name: "Consulta de Historico/Reportes",
                component: ExitInterview,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/exit-Interview/dashboards",
                name: "Dashboards",
                component: ExitInterview,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/exit-Interview",
                name: "",
                component: ExitInterview,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Horas Extra",
        state: "multiCollapse",
        views: [
          {
            path: "/dashboard-status",
            name: "Dashboard estados",
            component: ExtraHoursPage,
            layout: "/admin/extra-hours",
          },
          {
            path: "/information",
            name: "Información",
            component: ExtraHoursPage,
            layout: "/admin/extra-hours",
          },
          {
            path: "/access-management",
            name: "Gestión de Accesos",
            component: ExtraHoursPage,
            layout: "/admin/extra-hours",
          },
          {
            invisible: true,
            path: "/extra-hours",
            name: "",
            component: ExtraHoursPage,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Firma Digital",
        state: "signatureCollapse",
        views: [
          {
            path: "/dashboard-signatures",
            name: "Dashboard",
            component: DigitalSignature,
            layout: "/admin/digital-signature",
          },
          {
            path: "/register-signature",
            name: "Registrar Firma",
            component: DigitalSignature,
            layout: "/admin/digital-signature",
          },
          {
            invisible: true,
            path: "/digital-signature",
            name: "",
            component: DigitalSignature,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Aprobación Salarial",
        state: "SalaryCollapse",
        views: [
          {
            path: "/notifications",
            name: "Mis Flujos",
            component: SANotifications,
            layout: "/admin/salary",
          },
          {
            path: "/new",
            name: "Nueva Solicitud",
            component: SARoutes,
            layout: "/admin/salary",
          },
          {
            path: "/pendings",
            name: "Solicitudes Pendientes",
            component: SARoutes,
            layout: "/admin/salary",
          },
          {
            path: "/completed",
            name: "Solicitudes Completadas",
            component: SARoutes,
            layout: "/admin/salary",
          },
          {
            path: "/cancelled",
            name: "Solicitudes Canceladas y Rechazadas",
            component: SACancelledRequests,
            layout: "/admin/salary",
          },
          {
            path: "/salary",
            name: "",
            component: SARoutes,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Carta de Objetivos",
        state: "targetLetterCollapse",
        views: [
          {
            path: "/dashboard-letters",
            name: "Dashboard",
            component: TargetLetter,
            layout: "/admin/targets-letters",
          },
          {
            path: "/info-letters",
            name: "Solicitudes",
            component: TargetLetter,
            layout: "/admin/targets-letters",
          },
          {
            invisible: true,
            path: "/targets-letters",
            name: "",
            component: TargetLetter,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Contrataciones PB10",
        state: "ContratacionesCollapse",
        views: [
          {
            path: "/hcm-hiring/Inicio",
            name: "Inicio",
            component: HCMHiring,
            layout: "/admin",
          },

          {
            invisible: true,
            path: "/hcm-hiring",
            name: "",
            component: HCMHiring,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Conoce a tus Empleados",
        state: "youEmployeeCollapse",
        views: [
          {
            path: "/forms",
            name: "Formularios",
            component: KnowEmployee,
            layout: "/admin/know-employee",
          },

          {
            invisible: true,
            path: "/know-employee",
            name: "",
            component: KnowEmployee,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Sales",
    icon: "fas fa-hand-holding-usd text-white",
    iconObject: GiReceiveMoney,
    state: "salesCollapse",
    views: [
      {
        collapse: true,
        name: "Administración de Partes",
        state: "criticalPartsCollapse",
        views: [
          {
            path: "/parts-management/matrix",
            name: "Partes Críticas",
            component: PartsManagementPages,
            layout: "/admin",
          },
          {
            invisible: true,
            path: "/parts-management",
            name: "",
            component: PartsManagementPages,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Licitaciones de Panamá",
        state: "PanamaBidsCollapse",
        views: [
          {
            path: "/PanamaBids/agreement",
            name: "Convenio Marco",
            component: PanamaBids,
            layout: "/admin",
          },

          {
            path: "/PanamaBids/entities",
            name: "Entidades",
            component: PanamaBids,
            layout: "/admin",
          },
          {
            path: "/PanamaBids/quick-quotes",
            name: "Cotizaciones Rapidas",
            component: PanamaBids,
            layout: "/admin",
          },
          {
            path: "/PanamaBids/competition",
            name: "Competencia",
            component: PanamaBids,
            layout: "/admin",
          },
          {
            invisible: true,
            path: "/PanamaBids",
            name: "",
            component: PanamaBids,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Licitaciones de Costa Rica",
        state: "CostaRicaBidsCollapse",
        views: [
          {
            path: "/CostaRicaBids/agreementcr",
            name: "Concursos SICOP",
            component: CostaRicaBids,
            layout: "/admin",
          },
          {
            invisible: true,
            path: "/CostaRicaBids",
            name: "",
            component: CostaRicaBids,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        parent: true,
        name: "Fábrica de Propuestas",
        state: "AutoppLdrsCollapse",
        views: [
          {
            collapse: true,
            name: "Oportunidad de Venta",
            state: "oppSalesCollapse",
            views: [
              {
                level: 3,
                path: "/AutoppLdrs/autoppstart",
                name: "Inicio",
                component: AutoppLdrs,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Mantenimiento",
            state: "autoppCareCollapse",
            views: [
              {
                level: 3,
                path: "/AutoppLdrs/autoppcare",
                name: "Formularios LDR",
                component: AutoppCare,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        parent: true,
        name: "Contratos de Mantenimiento",
        state: "hwMaintenanceCollapse",
        views: [
          {
            collapse: true,
            name: "Lev. Requerimientos",
            state: "requirementsCollapse",
            views: [
              {
                level: 3,
                path: "/digital-requirements/request",
                name: "Crear Requerimiento",
                component: DigitalRequirementsPages,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/digital-requirements/my-requeriments",
                name: "Mis Requerimientos",
                component: DigitalRequirementsPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/digital-requirements",
                name: "",
                component: DigitalRequirementsPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Inventarios",
            state: "inventoriesCollapse",
            views: [
              {
                level: 3,
                path: "/inventories-management/quotes",
                name: "Cotización de Opp",
                component: InventoriesManagementPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/inventories-management",
                name: "",
                component: InventoriesManagementPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Pricing",
            state: "pricingCollapse",
            views: [
              {
                level: 3,
                path: "/pricing/master-data",
                name: "Master Data",
                component: PricingPages,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/pricing/offers-in-ajustment",
                name: "Ofertas en Ajuste",
                component: PricingPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/pricing",
                name: "",
                component: PricingPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Managers",
            state: "managersCollapse",
            views: [
              {
                level: 3,
                path: "/dashboard-contracts",
                name: "Dashboard",
                component: contractDashBoard,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/managers/opps-history",
                name: "Historial de Requerimientos",
                component: ManagersPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/managers",
                name: "",
                component: ManagersPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Planificación",
            state: "plannersCollapse",
            views: [
              {
                level: 3,
                path: "/planners/assignment-manager",
                name: "Gestor de Asignaciones",
                component: PlannersPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/planners",
                name: "",
                component: PlannersPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Soporte para Lev. Requerimientos",
            state: "supportDigitalRequestCollapse",
            views: [
              {
                level: 3,
                path: "/support/master-variables",
                name: "Variables Maestras",
                component: SupportPages,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/support/tss-escalations",
                name: "Gestión de Escalaciones",
                component: SupportPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/support",
                name: "",
                component: SupportPages,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Escalaciones TSS",
            state: "tssEscalationsCollapse",
            views: [
              {
                level: 3,
                path: "/support/tss-escalations",
                name: "Gestión de Escalaciones",
                component: SupportPages,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/support",
                name: "",
                component: SupportPages,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        parent: true,
        name: "Contratos On Hold",
        state: "secohCollapse",
        views: [
          {
            collapse: true,
            name: "Análisis On Hold",
            state: "analyticsCollapse",
            views: [
              {
                level: 3,
                path: "/secoh/dashboard",
                name: "Dashboard",
                component: SECOH,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/secoh/contracts",
                name: "Lista de Contratos",
                component: SECOH,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/secoh",
                name: "",
                component: SECOH,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Gestiones On Hold",
            state: "managementsCollapse",
            views: [
              {
                level: 3,
                path: "/secoh/matrix-notifications",
                name: "Matriz de Notificaciones",
                component: SECOH,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/secoh/status-maintenance",
                name: "Mantenimientos de Estados",
                component: SECOH,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/secoh/users-maintenance",
                name: "Mantenimientos de Usuarios",
                component: SECOH,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/secoh",
                name: "",
                component: SECOH,
                layout: "/admin",
              },
            ],
          },
          {
            collapse: true,
            name: "Gestión de Escalaciones",
            state: "comptrollerCollapse",
            views: [
              {
                level: 3,
                path: "/secoh/matrix-escalations",
                name: "Matriz de Escalaciones",
                component: SECOH,
                layout: "/admin",
              },
              {
                level: 3,
                path: "/secoh/service-management",
                name: "Gestión de Servicios",
                component: SECOH,
                layout: "/admin",
              },
              {
                invisible: true,
                path: "/secoh",
                name: "",
                component: SECOH,
                layout: "/admin",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Finanzas",
    icon: "ni ni-basket text-white",
    iconObject: GoGraph,
    state: "FinanceCollapse",
    views: [
      {
        collapse: true,
        name: "Sistema de Documentos",
        state: "DocumentSystemCollapse",
        views: [
          {
            path: "/document-system/home",
            name: "Home",
            component: DocumentSystem,
            layout: "/admin",
          },
          {
            invisible: true,
            path: "/document-system",
            name: "",
            component: DocumentSystem,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Administración datos cloud",
        state: "UserCloudRequestAdminCollapse",
        views: [
          {
            path: "/admin-tenant-cloud",
            name: "Administración de tenants",
            component: UCATenant,
            layout: "/admin/admin-cloud",
          },
          {
            path: "/admin-provider-cloud",
            name: "Proveedores nube",
            component: UCAProvider,
            layout: "/admin/admin-cloud",
          },
          {
            path: "/approvals",
            name: "Aprobación",
            component: UCApprovals,
            layout: "/admin/admin-cloud",
          },
          {
            path: "/access-matriz",
            name: "Matriz de Accesos",
            component: UCAceesMatriz,
            layout: "/admin/admin-cloud",
          },
          {
            invisible: true,
            path: "/admin-cloud",
            name: "",
            component: UCAIndex,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Solicitud Usuarios Cloud",
        state: "userCloudRequestCollapse",
        views: [
          {
            path: "/request",
            name: "Solicitud",
            component: UCRequest,
            layout: "/admin/cloud",
          },
          // {
          //   path: "/access-role",
          //   name: "Gestión de Usuarios",
          //   component: UCAccessRol,
          //   layout: "/admin/cloud",
          // },
          // {
          //   path: "/access-change",
          //   name: "Cambio de accesos",
          //   component: UCAccessChange,
          //   layout: "/admin/user-cloud-request",
          // },
          {
            path: "/my-request",
            name: "Mis Solicitudes",
            component: UCMyrequest,
            layout: "/admin/cloud",
          },
          {
            invisible: true,
            path: "/cloud",
            name: "",
            component: UCIndex,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Pedidos para inventario",
        state: "BusinessSystemCollapse",
        views: [
          {
            path: "/business-system/home",
            name: "Home",
            component: BusinessSystemHome,
            layout: "/admin",
          },
          {
            invisible: true,
            path: "/business-system",
            name: "",
            component: BusinessSystem,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Flujos de Aprobación",
        state: "FinanceFlowCollapse",
        views: [
          {
            path: "/notifications",
            name: "Mis Flujos",
            component: FFNotifications,
            layout: "/admin/finance",
          },
          {
            path: "/new",
            name: "Nueva Solicitud",
            component: FFNewRequest,
            layout: "/admin/finance",
          },
          {
            path: "/requests",
            name: "Solicitudes",
            component: FFRequests,
            layout: "/admin/finance",
          },
          {
            path: "/myRequests",
            name: "Mis Solicitudes",
            component: FFMyRequest,
            layout: "/admin/finance",
          },
          {
            invisible: true,
            path: "/finance",
            name: "",
            component: FFRoutes,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Administración de Flujos",
        state: "FinanceAdminCollapse",
        views: [
          {
            path: "/config/general",
            name: "Configuración General",
            component: FFGeneraldmin,
            layout: "/admin/finance",
          },
          {
            path: "/config/documents",
            name: "Documentos",
            component: FFDocumentAdmin,
            layout: "/admin/finance",
          },
          {
            path: "/config/settingsProfile",
            name: "Configuración de Perfiles",
            component: FFSettingsProfile,
            layout: "/admin/finance",
          },

          {
            path: "/config/roles",
            name: "Usuarios",
            component: FFApprovalRoles,
            layout: "/admin/finance",
          },
          {
            path: "/config/templates",
            name: "Plantillas de Aprobación",
            component: FFApprovalMapping,
            layout: "/admin/finance",
          },
          {
            path: "/config/requests-history",
            name: "Historial de Solicitudes",
            component: FFHistoryRequest,
            layout: "/admin/finance",
          },
          {
            invisible: true,
            path: "/finance",
            name: "",
            component: FFRoutes,
            layout: "/admin",
          },
        ],
      },
    ],
  },
];

const DigitalSignatureRoutes = [
  {
    path: "/dashboard-signatures",
    name: "",
    component: DashboardDigitalSignature,
    layout: "/admin/digital-signature",
  },
  {
    path: "/maintenance",
    name: "",
    component: RegisterSignature,
    layout: "/admin/digital-signature",
  },
  {
    path: "/register-signature",
    name: "",
    component: RegisterSignature,
    layout: "/admin/digital-signature",
  },
];

const CoeRoutes = [
  {
    path: "/planned-real-hours",
    name: "COE",
    component: PlannedRealHours,
    layout: "/admin/coe",
  },
  {
    path: "/historical-client",
    name: "COE",
    component: HistoricalClient,
    layout: "/admin/coe",
  },
  {
    path: "/indicators",
    name: "COE",
    component: Indicators,
    layout: "/admin/coe",
  },
  {
    path: "/collaborators",
    name: "COE",
    component: CollaboratorsReport,
    layout: "/admin/coe",
  },
  {
    path: "/hours-accused",
    name: "COE",
    component: HoursAccusedReport,
    layout: "/admin/coe",
  },
  {
    path: "/activities-report",
    name: "COE",
    component: ActivitiesReport,
    layout: "/admin/coe",
  },
  {
    path: "/maintenance",
    name: "COE",
    component: COEMaintenance,
    layout: "/admin/coe",
  },
];

const PlanningMRSRoutes = [
  {
    path: "/resourses/tasks/:id",
    name: "Planning Board",
    component: Tasks,
    layout: "/admin/planning-board",
  },
  {
    path: "/resourses",
    name: "Planning Board",
    component: Resourses,
    layout: "/admin/planning-board",
  },
  {
    path: "/",
    name: "Planning Board",
    component: Nodes,
    layout: "/admin/planning-board",
  },
];

const NewPositionRoutes = [
  {
    path: "/requests/support-position",
    name: "Nueva Posicion",
    component: SupportPosition,
    layout: "/admin/new-position",
  },
  {
    path: "/requests/work-flow",
    name: "Nueva Posicion",
    component: WorkFlow,
    layout: "/admin/new-position",
  },
  {
    path: "/requests",
    name: "Nueva Posicion",
    component: OptionalsPositions,
    layout: "/admin/new-position",
  },
  {
    path: "/my-requests/update-vacant/:id",
    name: "Nueva Posicion",
    component: UpdateVacantPosition,
    layout: "/admin/new-position",
  },
  {
    path: "/my-requests",
    name: "Nueva Posicion",
    component: MyRequests,
    layout: "/admin/new-position",
  },
  {
    path: "/dashboard-positions",
    name: "Nueva Posicion",
    component: Dashboard,
    layout: "/admin/new-position",
  },
  {
    path: "/maintenance",
    name: "",
    component: PositionMaintenance,
    layout: "/admin/new-position",
  },
];

const MisRoutes = [
  // {
  //   path: "/my-signatures",
  //   name: "Mis Firmas",
  //   component: MySignatures,
  //   layout: "/admin/mis"
  // },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
    layout: "/admin/mis",
  },
  {
    path: "/internal-equipment",
    name: "Profile",
    component: InternalTeam,
    layout: "/admin/mis",
  },
  {
    path: "/about-us/projects",
    name: "MIS",
    component: Projects,
    layout: "/admin/mis",
  },
  {
    path: "/about-us/inactive",
    name: "MIS",
    component: Inactive,
    layout: "/admin/mis",
  },
  {
    path: "/about-us/maintenance",
    name: "MIS",
    component: Maintenance,
    layout: "/admin/mis",
  },
  {
    path: "/about-us",
    name: "MIS",
    component: Main,
    layout: "/admin/mis",
  },
];

const ExtraHoursRoutes = [
  {
    path: "/access-management",
    name: "Extra Hours",
    component: AccessManagement,
    layout: "/admin/extra-hours",
  },
  {
    path: "/information",
    name: "Extra Hours",
    component: Information,
    layout: "/admin/extra-hours",
  },
  {
    path: "/dashboard-status",
    name: "Dashboard estados",
    component: ExtraHoursDashboardStatus,
    layout: "/admin/extra-hours",
  },
];

const WMSRoutes = [
  {
    path: "",
    name: "WMS",
    component: WMSOverview,
    layout: "/admin/wms",
  },
];

const ServiceOrderRoutes = [
  {
    path: "/details/:id",
    name: "Detalles de Boleta",
    component: ServiceOrderDetails,
    layout: "/admin/service-orders",
  },
  {
    path: "/me",
    name: "Mis Boletas",
    component: UserServiceOrders,
    layout: "/admin/service-orders",
  },
  {
    path: "/",
    name: "Boletas de Servicio",
    component: AllServiceOrders,
    layout: "/admin/service-orders",
  },
];

const PartsManagementRoutes = [
  {
    path: "/matrix",
    name: "",
    component: MatrixCriticalParts,
    layout: "/admin/parts-management",
  },
  {
    path: "/users-maintenance",
    name: "",
    component: UserMaintenanceParts,
    layout: "/admin/parts-management",
  },
];

const NotificationsRoutes = [
  {
    path: "/request_assignment",
    name: "",
    component: EngineersFlow,
    layout: "/admin/notifications",
  },
  {
    path: "/planners_flow",
    name: "",
    component: PlannersFlow,
    layout: "/admin/notifications",
  },
  {
    path: "/my-target-letters",
    name: "",
    component: MyTargetLetters,
    layout: "/admin/notifications",
  },
  {
    path: "/extra-hours-user",
    name: "",
    component: ExtraHoursCeo,
    layout: "/admin/notifications",
  },
  {
    path: "/usability-record",
    name: "",
    component: DashboardUsabilityRecord,
    layout: "/admin/notifications",
  },
  {
    path: "/my-signatures",
    name: "",
    component: MySignatures,
    layout: "/admin/notifications",
  },
  {
    path: "/signature-collaborator-flow",
    name: "Notifications",
    component: SignatureCollaboratorFlow,
    layout: "/admin/notifications",
  },
  {
    path: "/signature-supervisor-flow",
    name: "Notifications",
    component: SignatureSupervisorFlow,
    layout: "/admin/notifications",
  },
  {
    path: "/signature",
    name: "Notifications",
    component: DigitalSignatureNotification,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-hc",
    name: "Notifications",
    component: TargetLettersHC,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-user",
    name: "Notifications",
    component: TargetLetterUser,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-headship",
    name: "Notifications",
    component: TargetLettersHeadship,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-g-manager",
    name: "Notifications",
    component: TargetLettersGeneralManager,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-m-services",
    name: "Notifications",
    component: TargetLettersManagementServicesDirector,
    layout: "/admin/notifications",
  },
  {
    path: "/target-letter-hc-regional",
    name: "Notifications",
    component: TargetLettersHCRegional,
    layout: "/admin/notifications",
  },
  {
    path: "/salary/approvals/:id",
    name: "",
    component: SARequestStatus,
    layout: "/admin/notifications",
  },
  {
    path: "/digital-signature-maintenance",
    name: "",
    component: DigitalSignatureMaintenance,
    layout: "/admin/notifications",
  },
  {
    path: "/extra-hours-maintenance",
    name: "",
    component: ExtraHoursMaintenance,
    layout: "/admin/notifications",
  },
  {
    path: "/exit-interview-completion",
    name: "",
    component: CompleteInterview,
    layout: "/admin/notifications",
  },
  {
    path: "/know-employee-form",
    name: "",
    component: CompleteFormKnowEmployee,
    layout: "/admin/notifications",
  },
  {
    path: "/my-form-know-employee-complete",
    name: "",
    component: MyFormDataKnowEmployee,
    layout: "/admin/notifications",
  },
  {
    path: "/conflict-interests-form",
    name: "",
    component: ConflictInterestsForm,
    layout: "/admin/notifications",
  },
  {
    path: "/my-form-conflict-interest",
    name: "",
    component: ConflictInterestMain,
    layout: "/admin/notifications",
  },
  {
    path: "/extra-hours-flow",
    name: "",
    component: ExtraHoursReport,
    layout: "/admin/notifications",
  },
  {
    path: "/",
    name: "Notifications",
    component: NotificationsMain,
    layout: "/admin/notifications",
  },
];

const SalaryApprovalRoutes = [
  {
    path: "/details/:id",
    name: "Detalles de Solicitud",
    component: SARequestStatus,
    layout: "/admin/salary",
  },
  {
    path: "/new",
    name: "Nueva Solicitud Salarial",
    component: SANewRequest,
    layout: "/admin/salary",
  },
  {
    path: "/pendings",
    name: "Solicitudes Pendientes",
    component: SAPendingRequests,
    layout: "/admin/salary",
  },
  {
    path: "/completed",
    name: "Solicitudes Completadas",
    component: SACompleteRequests,
    layout: "/admin/salary",
  },
  {
    path: "/cancelled",
    name: "Solicitudes Canceladas y Rechazadas",
    component: SACancelledRequests,
    layout: "/admin/salary",
  },
  {
    path: "/notifications",
    name: "Mis Flujos",
    component: SANotifications,
    layout: "/admin/salary",
  },
  ,

  {
    path: "/others-approvers",
    name: "Administración de otros aprovadores",
    component: SAAccessOtherApprovers,
    layout: "/admin/salary",
  },
];

const TargetLettersRoutes = [
  {
    path: "/dashboard-letters",
    name: "",
    component: DashboardLetters,
    layout: "/admin/targets-letters",
  },
  {
    path: "/info-letters",
    name: "",
    component: TargetLetterRequests,
    layout: "/admin/targets-letters",
  },
];

const DigitalRequirementsRoutes = [
  {
    path: "/request",
    name: "",
    component: CreateRequirement,
    layout: "/admin/digital-requirements",
  },
  {
    path: "/my-requeriments",
    name: "",
    component: MyRequestsLDR,
    layout: "/admin/digital-requirements",
  },
];

const QuotesRoutes = [
  {
    path: "/quotes",
    name: "",
    component: Quotes,
    layout: "/admin/inventories-management",
  },
];

const PrincingRoutes = [
  {
    path: "/master-data",
    name: "",
    component: MasterData,
    layout: "/admin/pricing",
  },
  {
    path: "/offers-in-ajustment",
    name: "",
    component: OffersAjustment,
    layout: "/admin/pricing",
  },
];

const ManagersRoutes = [
  {
    path: "/opps-history",
    name: "",
    component: OppsHistory,
    layout: "/admin/managers",
  },
];

const SupportRoutes = [
  {
    path: "/master-variables",
    name: "",
    component: MasterVariables,
    layout: "/admin/support",
  },
  {
    path: "/tss-escalations",
    name: "",
    component: TSSEscalations,
    layout: "/admin/support",
  },
];

const MedicalRecordsRoutes = [
  {
    path: "/dashboard-records",
    name: "",
    component: DashboardRecords,
    layout: "/admin/medical-record",
  },
  {
    path: "/records",
    name: "",
    component: Records,
    layout: "/admin/medical-record",
  },
  {
    path: "/maintenance",
    name: "",
    component: RecordsMaintenance,
    layout: "/admin/medical-record",
  },
];

const FinanceRoutes = [
  {
    path: "/request/update/:id",
    name: "Actualizacion de Solicitud",
    component: FFRequestUpdate,
    layout: "/admin/finance",
  },
  {
    path: "/request/:id",
    name: "Detalles de Solicitud",
    component: FFRequestDetails,
    layout: "/admin/finance",
  },
  {
    path: "/notifications",
    name: "Mis Flujos",
    component: FFNotifications,
    layout: "/admin/finance",
  },
  {
    path: "/new",
    name: "Nueva Solicitud",
    component: FFNewRequest,
    layout: "/admin/finance",
  },
  {
    path: "/requests",
    name: "Solicitudes",
    component: FFRequests,
    layout: "/admin/finance",
  },
  {
    path: "/myRequests",
    name: "Mis Solicitudes",
    component: FFMyRequest,
    layout: "/admin/finance",
  },
  {
    path: "/config/documents",
    name: "Documentos",
    component: FFDocumentAdmin,
    layout: "/admin/finance",
  },
  {
    path: "/config/settingsProfile",
    name: "Configuración de Perfiles",
    component: FFSettingsProfile,
    layout: "/admin/finance",
  },

  {
    path: "/config/roles",
    name: "Roles de Aprobación",
    component: FFApprovalRoles,
    layout: "/admin/finance",
  },
  {
    path: "/config/templates",
    name: "Plantillas de Aprobación",
    component: FFApprovalMapping,
    layout: "/admin/finance",
  },
  {
    path: "/config/general",
    name: "Configuración General",
    component: FFGeneraldmin,
    layout: "/admin/finance",
  },
  {
    path: "/config/requests-history",
    name: "Historial de Solicitudes",
    component: FFHistoryRequest,
    layout: "/admin/finance",
  },
];

const ContactsRoutes = [
  {
    path: "/history-list",
    name: "Estadisticas",
    component: HistoryContacts,
    layout: "/admin/contacts",
  },
  {
    path: "/customers-list",
    name: "Lista de Clientes",
    component: CustomersList,
    layout: "/admin/contacts",
  },
];

const IncidentsReportsRoutes = [
  {
    path: "/incidents-reports",
    name: "Administracion de incidentes",
    component: IncidentsReportsAdmin,
    layout: "/admin/incidentsReports",
  },
];

const DocumentSystemRoutes = [
  {
    path: "/home",
    name: "Sistema de Documentos",
    component: SDHome,
    layout: "/admin/document-system",
  },
];

const SecohRoutes = [
  {
    path: "/dashboard",
    name: "",
    component: DashboardSecoh,
    layout: "/admin/secoh",
  },
  {
    path: "/contracts",
    name: "",
    component: ContractsList,
    layout: "/admin/secoh",
  },
  {
    path: "/status-maintenance",
    name: "",
    component: StatusMaintenance,
    layout: "/admin/secoh",
  },
  {
    path: "/matrix-notifications",
    name: "",
    component: MatrixNotifications,
    layout: "/admin/secoh",
  },
  {
    path: "/matrix-escalations",
    name: "",
    component: MatrixEscalations,
    layout: "/admin/secoh",
  },
  {
    path: "/service-management",
    name: "",
    component: ServicesManagement,
    layout: "/admin/secoh",
  },
  {
    path: "/users-maintenance",
    name: "",
    component: UsersMaintenanceOnHold,
    layout: "/admin/secoh",
  },
];

const HcmHiringRoutes = [
  {
    path: "/Inicio",
    name: "Contrataciones PB10",
    component: HCMH,
    layout: "/admin/hcm-hiring",
  },
];

const PanamaBidsRoutes = [
  {
    path: "/agreement",
    name: "Convenio Macro",
    component: Agreement,
    layout: "/admin/PanamaBids",
  },
  {
    path: "/entities",
    name: "Entidades",
    component: Entities,
    layout: "/admin/PanamaBids",
  },
  {
    path: "/quick-quotes",
    name: "Cotizaciones Rapidas",
    component: QuickQuotes,
    layout: "/admin/PanamaBids",
  },
  {
    path: "/competition",
    name: "Competencia",
    component: Competition,
    layout: "/admin/PanamaBids",
  },
];

const CostaRicaBidsRoutes = [
  {
    path: "/agreementcr",
    name: "Concursos SICOP",
    component: AgreementCR,
    layout: "/admin/CostaRicaBids",
  },
];

const ExitInterviewRoutes = [
  {
    path: "/digital-form",
    name: "Formulario Digital",
    component: DigitalForm,
    layout: "/admin/exit-Interview",
  },
  {
    path: "/category-management",
    name: "Administracion de Categorias",
    component: CategoryManagement,
    layout: "/admin/exit-Interview",
  },
  {
    path: "/history-query",
    name: "Consulta de Historico/Reportes",
    component: HistoryQuery,
    layout: "/admin/exit-Interview",
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: Dashboards,
    layout: "/admin/exit-Interview",
  },
  {
    path: "/complete-interview",
    name: "Completar Entrevista de Salida",
    component: CompleteInterview,
    layout: "/admin/exit-Interview",
  },

  {
    path: "/maintenance",
    name: "Administración de role",
    component: ExitInterviewManagement,
    layout: "/admin/exit-Interview",
  },
];

const AutoppLdrsRoutes = [
  {
    path: "/autoppstart",
    name: "Inicio",
    component: AutoppStart,
    layout: "/admin/AutoppLdrs",
  },
];

const PlannersRoutes = [
  {
    path: "/assignment-manager",
    name: "",
    component: PlannersAssignmentManager,
    layout: "/admin/planners",
  },
];

const KnowEmployeeRoutes = [
  {
    path: "/forms",
    name: "",
    component: Forms,
    layout: "/admin/know-employee",
  },
];

const ConflictInterestsRoutes = [
  {
    path: "/forms-complete",
    name: "",
    component: FormsCompleteConflictsInterest,
    layout: "/admin/conflicts-interests",
  },
];

const NewMasterDataRoutes = [
  {
    path: "/masterdata",
    name: "Inicio",
    component: HomeMasterData,
    layout: "/admin/masterdata",
  },
  {
    path: "/MyRequestsMasterData",
    name: "Mis Gestiones",
    component: MyRequestsMasterData,
    layout: "/admin/masterdata",
  },
  {
    path: "/ApprovalsMasterData",
    name: "Aprobaciones",
    component: ApprovalsMasterData,
    layout: "/admin/masterdata",
  },
  {
    path: "/AllRequestsMasterData",
    name: "Todas las Gestiones",
    component: AllRequestsMasterData,
    layout: "/admin/masterdata",
  },
  {
    path: "/MaintenanceWindowMasterData",
    name: "Vista de Mantenimiento",
    component: MaintenanceWindowMasterData,
    layout: "/admin/masterdata",
  },
];

const BusinessSystemRoutes = [
  {
    path: "/business-system",
    name: "Pedidos para inventario",
    component: BusinessSystemHome,
    layout: "/admin/business-system",
  },
  {
    path: "/maintenance",
    name: "Administración de role",
    component: BusinessSystemManagement,
    layout: "/admin/business-system",
  },
];

const FreelanceRoutes = [
  {
    path: "/freelance",
    name: "GBM Freelance",
    component: HomeFreelance,
    layout: "/admin/freelance",
  },
];

const DatabotRoutes = [
  {
    path: "/orchestrator",
    name: "Orquestador",
    component: DatabotOrchestrator,
    layout: "/admin/Databot",
  },
  {
    path: "/stats",
    name: "Estadísticas",
    component: DatabotStats,
    layout: "/admin/Databot",
  },
];

const ItRequestsRoutes = [
  {
    path: "/itrequests",
    name: "Inicio",
    component: ItRequestHome,
    layout: "/admin/itrequests",
  },
];
const UserCloudRequestRoutes = [
  {
    path: "/request",
    name: "Solicitud",
    component: UCRequest,
    layout: "/admin/cloud",
  },
  // {
  //   path: "/access-role",
  //   name: "Gestión de Usuarios",
  //   component: UCAccessRol,
  //   layout: "/admin/cloud",
  // },
  // {
  //   path: "/access-change",
  //   name: "Cambio de accesos",
  //   component: UCAccessChange,
  //   layout: "/admin/user-cloud-request",
  // },
  {
    path: "/my-request",
    name: "Mis Solicitudes",
    component: UCMyrequest,
    layout: "/admin/cloud",
  }
]

const UserCloudRequestAdminRoutes = [
  {
    path: "/approvals",
    name: "Aprobación",
    component: UCApprovals,
    layout: "/admin/admin-cloud",
  },
  {
    path: "/access-matriz",
    name: "Matriz de Accesos",
    component: UCAceesMatriz,
    layout: "/admin/admin-cloud",
  },
  {
    path: "/admin-tenant-cloud",
    name: "Administración de tenants",
    component: UCATenant,
    layout: "/admin/admin-cloud",
  },
  {
    path: "/admin-provider-cloud",
    name: "Proveedores nube",
    component: UCAProvider,
    layout: "/admin/admin-cloud",
  },
  
]

const HoursReportRoutes = [
  {
    path: "/home",
    name: "GBM Hours Report",
    component: ReportHoursHome,
    layout: "/admin/hours-report",
  },
];

export default AdminRoutes;
export {
  PlanningMRSRoutes,
  MisRoutes,
  ExtraHoursRoutes,
  WMSRoutes,
  IncidentsReportsRoutes,
  NewPositionRoutes,
  ServiceOrderRoutes,
  DigitalSignatureRoutes,
  NotificationsRoutes,
  CoeRoutes,
  PartsManagementRoutes,
  DigitalRequirementsRoutes,
  SalaryApprovalRoutes,
  TargetLettersRoutes,
  QuotesRoutes,
  PrincingRoutes,
  ManagersRoutes,
  SupportRoutes,
  MedicalRecordsRoutes,
  FinanceRoutes,
  ContactsRoutes,
  DocumentSystemRoutes,
  SecohRoutes,
  HcmHiringRoutes,
  PanamaBidsRoutes,
  CostaRicaBidsRoutes,
  ExitInterviewRoutes,
  PlannersRoutes,
  AutoppLdrsRoutes,
  KnowEmployeeRoutes,
  ConflictInterestsRoutes,
  NewMasterDataRoutes,
  BusinessSystemRoutes,
  FreelanceRoutes,
  DatabotRoutes,
  ItRequestsRoutes,
  HoursReportRoutes,
  UserCloudRequestRoutes,
  UserCloudRequestAdminRoutes
};
