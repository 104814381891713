import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Row } from "reactstrap";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import InternalUser from "components/UserCloudRequest/Requests/InternalUser"
import ExternalUser from "components/UserCloudRequest/Requests/ExternalUser"
import CancellationRequest from "components/UserCloudRequest/Requests/CancellationRequest"
import CancellationExternalRequest from "components/UserCloudRequest/Requests/CancellationExternalRequest"
import AccessChange from "components/UserCloudRequest/Requests/AccessChange"
import { useDispatch } from "react-redux";
import { getUsersMatrix, createRequest, deleteFile, getAllCloudVendor, getAllCountries } from "actions/userCloudRequests";
import { Spin } from 'antd';
import { urlBase } from "api/urls.jsx";
import NotificationAlert from "react-notification-alert";
import { CustomCard } from 'components/Shared/Cards/CustomCard';

import { LiaExchangeAltSolid } from "react-icons/lia";
import { IoPersonOutline } from "react-icons/io5";
import { SlPeople } from "react-icons/sl";
import { MdKeyboardArrowDown } from "react-icons/md";




const AllRequests = () => {

  const dispatch = useDispatch();
  const notifyEl = useRef(null);

  const [view, setView] = useState(0);
  const [loading, setLoad] = useState(false);
  const [info, setInfo] = useState([]);
  const [newInfo, setNewInfo] = useState([]);
  const [matrix, setMatrix] = useState([]);
  const [isCustom, setCustom] = useState(0);
  const [temporalFolderFilesId] = useState(new Date().getTime());
  const [data, setData] = useState({
    clouds: [],
    countries: []
  });

  useEffect(() => {
    const getAllData = async () => {
      await getData();
    };
    getAllData();
  }, []);

  const getData = async () => {
    const array = [];
    setLoad(true);
    await dispatch(getUsersMatrix()).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoad(false);
      } else {
        let element;
        for (const item of payload.data.payload.users) {
          element = { label: ` ${item.mail} - ${item.NameEmployee}`, value: item.mail };
          array.push(element);
        }
        setMatrix((prevState) => ({
          ...prevState,
          rowsMatrix: payload.data.payload.users,
          selectMatrix: array
        }));
        setLoad(false);
      }
      setMatrix((prevState) => ({
        ...prevState,
        rowsMatrix: payload.data.payload.users,
        selectMatrix: array
      }));
      setLoad(false);
    })
    await dispatch(getAllCloudVendor()).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoad(false);
      } else {
        let cloud = [];

        const data = payload.data.data
        for (const item of data) {
          let json = {};
          json["value"] = item.id
          json["label"] = item.name
          cloud.push(json);
        }
        setData(prevState => ({
          ...prevState,
          clouds: cloud
        }))
      }
    })
    await dispatch(getAllCountries()).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoad(false);
      } else {
        setData(prevState => ({
          ...prevState,
          countries: payload.data.data
        }))
      }
    });
  };

  const fields = [
    {
      title: "Usuario Interno",
      subtitle: " Creación de nuevos usuarios internos",
      id: "clientes",
      image: "idea.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      click: (e) => setView(1),
      icon: IoPersonOutline
    },
    {
      title: "Usuario Externo ( Cliente )",
      subtitle: "Creación de nuevo usuario externo",
      id: "proveedores",
      image: "teacher.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      click: (e) => setView(2),
      icon: SlPeople
    },
    {
      title: "Solicitud de baja usuario interno",
      subtitle: "Solicitudes para la gestion de bajas de la nube",
      id: "servicios",
      image: "exam.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      click: (e) => setView(3),
      icon: MdKeyboardArrowDown
    },
    {
      title: "Solicitud de baja usuario externo ( Cliente )",
      subtitle: "Solicitudes para la gestion de bajas de la nube de usuarios externos",
      id: "servicios",
      image: "exam.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      click: (e) => setView(4),
      icon: MdKeyboardArrowDown
    },
    {
      title: "Cambio de acceso",
      subtitle: "Gestion de los accesos de los usuarios",
      id: "ibase",
      image: "ibase.png",
      admin: false,
      colorCardSelected: "#d6d4fa",
      click: (e) => setView(5),
      icon: LiaExchangeAltSolid
    },
  ];
  //Se devuelve a la vista inicial de los carts
  const handleOnPrevPage = () => {
    setView(0);
    setInfo([]);
    setNewInfo([]);
  }
  //Funcion para editar los campos de la solicitud
  const handleOnChangeInfo = (constant, value, valueInsert) => {
    setNewInfo((prevState) => ({
      ...prevState,
      [constant]: { value: value, insert: valueInsert }
    }));
    setInfo((prevState) => ({
      ...prevState,
      [constant]: value
    }));
  }
  //Funcion para crear la nueva solcitud 
  const createNewRequest = (formRequestId, permissionId) => {

    // notify("success", "Exito", "Se creo correctamente");
    dispatch(createRequest({ formRequestId: formRequestId, requestInfo: newInfo, permissionId: permissionId, temporalFolderFilesId: temporalFolderFilesId, requestFile: fileList, custom: isCustom })).then((resp) => {
      const { payload } = resp;
      if (payload.isAxiosError) {
        notify("danger", "Error", payload.message);
        setLoad(false);
      } else {
        notify("success", "Exito", "Se creo correctamente");
        handleOnPrevPage();
      }
    });
  }
  //Funcion para validar que los campos esten llenos para enviar la solicitud
  const handleOnValidateRequest = (formRequestId, permissionId) => {

    let cont = 0;
    const forms = document.querySelectorAll(".isValid input, select, textarea");
    Array.from(forms).forEach((input) => {
      if (input.required === true && (input.value === "" || input.value === "---")) {
        document.getElementsByClassName("css-yk16xz-control")[0].setAttribute("style", "border-color:#fb6340;");
        input.classList.add("is-invalid");
        cont++;
      } else {
        input.classList.remove("is-invalid");
      }
    });

    if (cont === 0) {
      createNewRequest(formRequestId, permissionId);
      const forms = document.querySelectorAll(".isValid input, select");
      Array.from(forms).forEach((input) => { input.classList.remove("is-invalid") });
    };
  };
  //Muestra el modal que despliega notificaciones 
  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) { }
  };
  //#region Funciones para la carga de archivos
  const [state, setState] = useState({
    fileList: [],
    idFileRequest: "",
    readyToSend: false,
  });
  const { fileList } = state
  //Funcion para agregar o borrar archivos del dragger
  const handleOnFileList = (info) => {
    const { status } = info.file;
    if (status === "removed") {
      const { file: { name, uid, response: { status, payload } } } = info;
      if (status === 200) {
        const { idFile } = payload;
        setState((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          fileList: fileList.filter((row) => row.uid !== uid),
        }));
      }
      dispatch(deleteFile({ id: temporalFolderFilesId, name: name })).then((resp) => {
        const { payload } = resp;

        if (payload.isAxiosError) {
          notify("danger", "Error", payload.message);
          setLoad(false);
        } else {
          if (fileList.length === 1) {
            setState((prevState) => ({
              ...prevState,
              readyToSend: false,
            }));
          }
        }
      })
    } else {
      const { fileList, file: { status, name } } = info;
      let response = "";
      if (status === 'done') {
        const { file } = info;
        response = file["response"]
        response = response.payload.idFile;
        //habilitar el botón de enviar
        setState((prevState) => ({
          ...prevState,
          readyToSend: true,
        }));
      } else if (status === 'error') {
        notify("danger", "Error", "Solo se permite cargar archivos con extension .pdf");

      }
      setState((prevState) => ({
        ...prevState,
        idFileRequest: response,
        fileList: fileList,
      }));
    }
  }
  //Funcion para llamar el endpoint que carga los archivos para crearlos en el back
  const uploadProps = {
    name: 'file',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
    accept: ".pdf",
    multiple: true,
    action: `${urlBase}/user-cloud-request/add-files/${temporalFolderFilesId}`,
    onChange: handleOnFileList,
  };
  //#endregion
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      <AdminHeader name="Solicitudes" parentName="Usuarios Cloud" />
      <Container className="mt--6" fluid>
        <Spin size="large" spinning={loading}>

          {view === 0 ?

            <Row className="mt-3 d-flex justify-content-center">
              {fields.map((row, key) => (

                <CustomCard
                  // key={key}
                  xs={"12"}
                  sm={"4"}
                  title={row.title}
                  subtitle={row.subtitle}
                  onClick={() => row.click()}
                  Icon={row.icon}
                // id={row.id}

                // image={row.image}
                // colorCardSelected={row.colorCardSelected}
                // formSelected={formSelected}
                ></CustomCard>


              ))}
            </Row>
            :
            view === 1 ?
              <InternalUser
                notify={notify}
                setView={setView}
                setInfo={setInfo}
                backPage={handleOnPrevPage}
                handleOnChangeInfo={handleOnChangeInfo}
                createNewRequest={handleOnValidateRequest}
                setNewInfo={setNewInfo}
                setCustom={setCustom}
                info={info}
                data={data}
                selectMatrix={matrix.selectMatrix}
                matrix={matrix.rowsMatrix}
              />
              :
              view === 2 ?
                <ExternalUser
                  setView={setView}
                  backPage={handleOnPrevPage}
                  handleOnChangeInfo={handleOnChangeInfo}
                  createNewRequest={() => handleOnValidateRequest(2, 3)}
                  data={data}
                  info={info}
                  fileList={fileList}
                  uploadProps={uploadProps}
                />
                :
                view === 3 ?
                  <CancellationRequest
                    notify={notify}
                    setView={setView}
                    setInfo={setInfo}
                    backPage={handleOnPrevPage}
                    handleOnChangeInfo={handleOnChangeInfo}
                    createNewRequest={() => handleOnValidateRequest(3, 3)}
                    setNewInfo={setNewInfo}
                    info={info}
                    data={data}
                    selectMatrix={matrix.selectMatrix}
                    matrix={matrix.rowsMatrix} />
                  :
                  view === 4 ?
                    <CancellationExternalRequest
                      setView={setView}
                      backPage={handleOnPrevPage}
                      handleOnChangeInfo={handleOnChangeInfo}
                      createNewRequest={() => handleOnValidateRequest(5, 3)}
                      info={info}
                      data={data} />
                    :
                    <AccessChange
                      notify={notify}
                      setView={setView}
                      setInfo={setInfo}
                      backPage={handleOnPrevPage}
                      handleOnChangeInfo={handleOnChangeInfo}
                      createNewRequest={() => handleOnValidateRequest(4, 3)}
                      setNewInfo={setNewInfo}
                      info={info}
                      data={data}
                      selectMatrix={matrix.selectMatrix}
                      matrix={matrix.rowsMatrix} />
          }

        </Spin>
      </Container>
    </div>
  );
}

export default AllRequests;


