// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v2.0.0";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// core layouts
import AdminLayout from "layouts/Admin.jsx";
// react library
import React from "react";
// react library for dom
import ReactDOM from "react-dom";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// react redux library for store
import { Provider } from "react-redux";
// react library for routing
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// core store
import { getStore } from "store/index.jsx";
// core views
// import Login from "views/LoginPage/Login";
// import LoginDuo from "views/LoginPage/LoginDuo";
// import LoginWait from "views/LoginPage/LoginWait";
// service worker file
import * as serviceWorker from "./serviceWorker";
// core Private Route
import PrivateRoute from "PrivateRoute.jsx";
import { GeistProvider } from "@geist-ui/react";

import LoginDark from "views/LoginPage/LoginDark";
import LoginWaitDark from "views/LoginPage/LoginWaitDark";

const store = getStore();

const RootComponent = (
  <GeistProvider>
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Route path="/:id" component={LoginWaitDark} />
          <Route path="/" component={LoginDark} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Provider>
  </GeistProvider>
);

ReactDOM.render(RootComponent, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
// serviceWorker.register();
