import React, { useEffect, useState } from 'react';
import Filters from 'components/BusinessSystem/Filters';
import Table from 'components/BusinessSystem/Table';
import Modals from "components/BusinessSystem/Modals";
import Flows from 'components/BusinessSystem/Flows';
import { useDispatch } from "react-redux";
import { getData, getOrdersAssign, getCountriesByHCM, getReportUS, getReportFlow, updateRequest, insertRequest } from "actions/BusinessSystem"
import ReactBSAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTeamsUserAccess, getUserProtected } from 'selectors/adminLayout.jsx';
import { desencrypt } from 'helpers/desencrypt.jsx';

const MainPage = ({ setLoad }) => {

    const MySwal = withReactContent(Swal);

    const Toast = MySwal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: true,
        timer: 5000,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [alert, setAlert] = useState("");

    const dispatch = useDispatch();
    const [openModal, setModal] = useState({
        type: "",
        visible: false
    });
    const [countries, setCountries] = useState([]);
    const [page, setPage] = useState(1);
    const [thisTable, setThisTable] = useState("reportUS");
    const [filtersByTable, setFiltersByTable] = useState([]);
    const [infoTable, setInfoTable] = useState(
        {
            reportUs: [],
            orderAssing: [],
            itemSelected: "",
            flowItem: false,
            flowInfo: []
        }
    )
    const [valueSelect, setValueSelect] = useState(
        {
            countryOA: [],
            statusReports: [],
            statusOrderAssign: [],
            roles: [],
            users: []
        }
    )
    const [newInfo, setNewInfo] = useState({});
    const [rol, setRol] = useState("");

    //Carga la data inicial de las vistas
    useEffect(() => {
        const getInfo = async () => {
            await handleOnGetInfoTables();
        };
        getInfo();
    }, []);
    //Valida cual rol tiene el usuario
    useEffect(() => {
        //trae informacion del token
        const teams = getTeamsUserAccess();
        if (teams.some((row) => row.indexOf("BS Pais") !== -1)) {
            setRol("BS Pais")
        }
        if (teams.some((row) => row.indexOf("BS Admin") !== -1)) {
            setRol("BS Admin")
        }
        if (teams.some((row) => row.indexOf("BS Ventas") !== -1)) {
            setRol("BS Ventas")
        }
    }, []);
    //Trae la informacion de los endpoints de las tablas y los selects
    const handleOnGetInfoTables = async () => {
        setLoad(true);
        const nombre = getUserProtected().NOMBRE;
        dispatch(getData()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                let data = desencrypt(payload.data.payload.masterData);                
                data = JSON.parse(data)
                setValueSelect((prevState) => ({
                    ...prevState,
                    countryOA: data.Countries,
                    statusReports: data.StatusReports,
                    StatusOrderAssign: data.StatusOrderAssign,
                    roles: data.roles,
                    users: data.users
                }));
            }
            setLoad(false);
        });
        dispatch(getCountriesByHCM({ name: nombre })).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setCountries(payload.data.payload.data)
            }
        })
        dispatch(getOrdersAssign()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    orderAssing: payload.data.payload.data,
                }));
            }
            setLoad(false);
        });
        dispatch(getReportUS()).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    reportUs: payload.data.payload.data,
                }));
            }
            setLoad(false);
        });
    };
    const filtersUS = [
        {
            type: "input",
            label: "PO",
            md: "4",
            value: "POTrad"
        },
        {
            type: "input",
            label: "Número de producto",
            md: "4",
            value: "PN",
            isBS: false
        },
        {
            type: "select",
            label: "Status",
            md: "4",
            name: "statusReports",
            value: "statusReports",
            isBS: false
        }
    ]
    const filtersOr = [
        {
            type: "select",
            label: "País",
            md: "4",
            name: "countryOA",
            value: "countryOA"

        },
        {
            type: "input",
            label: "Número de producto",
            md: "4",
            value: "PN",
            isBS: false
        },
        {
            type: "select",
            label: "Status",
            md: "4",
            name: "StatusOrderAssign",
            value: "StatusOrderAssign",
            isBS: false
        }
    ]
    const tableReportUs = [
        { label: "Fecha de colocación", value: "ordenEntryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isTable: true },
        { label: "PO", value: "POTrad", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isTable: true, isBS: false },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad del pedido", value: "ordenQuantity", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad disponible", value: "quantityAvailable", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "quantityReserved", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: true, isTable: true },
        { label: "Fecha estimada de salida a planta", value: "actualShipDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha estimada de llegada a Miami", value: "deliveryDate", type: "date", colWidth: "4", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Monto colocado", value: "colocationAmount", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Estado", value: "Status", valueSelect: "statusReports", type: "select", colWidth: "6", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha Orden en transito", value: "updateAt", type: "date", colWidth: "6", required: false, filter: true, disabled: false, isBS: false, isTable: false },
        { label: "Item", value: "item", colWidth: "6", maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", maxlength: "500", filter: true, disabled: false, isTable: true },

    ]
    const tableOrders = [
        { label: "País", value: "country", colWidth: "4", type: "select", valueSelect: "countryOA", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cliente", value: "customer", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Fecha de recibo de la orden", type: "date", value: "dateToRevibeOrder", colWidth: "4", required: true, filter: false, disabled: false, isBS: false, isTable: true },
        { label: "SO", value: "SO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "PO País", value: "countryPO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Número de producto", value: "PN", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Cantidad Reservada", value: "reservedAmount", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false, isTable: true },
        { label: "Costo SO", value: "costSO", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isBS: false, isTable: true },
        // { label: "Costo total SO", value: "totalCostSO", colWidth: "4", required: true, maxlength: "10", filter: false, disabled: false, isBS: false },
        { label: "Estado", value: "Status", colWidth: "4", type: "select", valueSelect: "StatusOrderAssign", required: true, filter: true, disabled: false, isBS: false, isTable: true },
        { label: "Post", value: "post", colWidth: "4", required: true, maxlength: "10", filter: true, disabled: false, isTable: true },
        { label: "Usuario", value: "user", colWidth: "4", required: true, maxlength: "50", filter: false, disabled: false, isTable: true },
        { label: "Comentario", value: "comment", colWidth: "12", required: true, maxlength: "500", filter: false, disabled: false, isTable: true },
    ]
  

    //Aplicar Filtros a la tabla de ordenes pendientes
    const renderRowsByFilters = (rows) => {
        let rows2 = [];
        if (Object.keys(filtersByTable).length === 0) {
            rows = rows.filter(
                (item) => {
                    for (const x of countries) {
                        if (item.countryOA === x.countryId) {
                            rows2.push(item)
                        }
                    }
                }
            )
            return rows2
        }
        else {
            const filterKeys = Object.keys(filtersByTable);
            rows.filter(
                (item) => {
                    for (const x of countries) {
                        if (item.countryOA === x.countryId) {
                            rows2.push(item)
                        }
                    }
                }
            )
            for (const element of filterKeys) {
                const valueFiltered = filtersByTable[element].toLowerCase();
                rows2 = rows2.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1)
            }
        }
        return rows2
    }
    //Aplicar Filtros a la tabla de ordenes para inventario
    const renderRowsByFiltersRUS = (rows) => {
     
        if (Object.keys(filtersByTable).length === 0) {
            return rows
        }
        else {
            const filterKeys = Object.keys(filtersByTable);
            for (const element of filterKeys) {
                const valueFiltered = filtersByTable[element].toLowerCase();
                if (valueFiltered !== "") {
                    rows = rows.filter((item) => item[element] !== null);
                    rows = rows.filter((item) => item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
                    )
                }
            }
        }
        return rows
    }
  
    //Funcion para los filtros por columna
    const handleOnfilterDinamic = (constant, e) => {
        if ((constant === "statusReports" || constant === "countryOA" || constant === "StatusOrderAssign") && e === 0) {
            setFiltersByTable(prevState => ({
                ...prevState,
                [constant]: ``
            }))
        } else {
            setFiltersByTable(prevState => ({
                ...prevState,
                [constant]: `${e}`
            }))
        }
    }
    //Funcion para cambiar entre la tabla #1 y la tabla #2
    const handleOnChangeTable = (id) => {
        setThisTable(id);
        setFiltersByTable([]);
        setNewInfo({});
    }
    //Funcion para tomar la informacion de la fila a la hora de tocar el boton de editar
    const handleTakeInfoRow = (row) => {
        setModal((prevState) => ({
            ...prevState,
            visible: true,
            type: "edit"
        }))
        setInfoTable((prevState) => ({
            ...prevState,
            itemSelected: row,
        }));
    }
    //Funcion para abrir el flujo del item seleccionado al tocar el boton de flujo
    const handleOnFlowItem = async (row) => {
        setLoad(true);
        setInfoTable((prevState) => ({
            ...prevState,
            flowItem: true,
            itemSelected: row,
        }));
        dispatch(getReportFlow(row.Id)).then((resp) => {
            const { payload } = resp;
            if (payload.status === 200) {
                setInfoTable((prevState) => ({
                    ...prevState,
                    flowInfo: payload.data.payload.data
                }));
            }
            setLoad(false);
        })
    }
    //Funcion para almacenar campos modificados por nombre
    const handleOnChangeInfo = (constant, value) => {
        setNewInfo((prevState) => ({
            ...prevState,
            [constant]: value,
        }));
    };
    //Funcion para modificar el registro seleccionado
    const handleOnUpdateRequest = async () => {
        setLoad(true);
        dispatch(updateRequest(infoTable.itemSelected["Id"], { data: newInfo, table: thisTable, idStatus: infoTable.itemSelected["idStatus"] })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                handleOnGetInfoTables();
                setModal((prevState) => ({
                    ...prevState,
                    visible: false,
                    type: ""
                }))

                Toast.fire({
                    title: "Actualizado",
                    html: "La información se actualizo correctamente",
                    type: "success",
                });
            }
        });
        setAlert("");
        setLoad(false);
    };
    //Funcion para insertar registro nuevo
    const handleOnInsertRequest = async () => {
        setLoad(true);
        dispatch(insertRequest({ data: newInfo, table: thisTable })).then((res) => {
            if (res.payload.isAxiosError) {
                if (res.payload.response) {
                    const { data: { payload } } = res.payload.response;
                    Toast.fire({
                        title: "Atención",
                        html: payload.message,
                        type: "danger",
                    });
                } else {
                    Toast.fire({
                        title: "Fallo",
                        html: "No se logro establecer conexion con el servidor.",
                        type: "danger",
                    });
                }
            } else {
                handleOnGetInfoTables();
                setModal((prevState) => ({
                    ...prevState,
                    visible: false,
                }))
                Toast.fire({
                    title: "Insertado",
                    html: "Se inserto correctamente",
                    type: "success",
                });
            }
        });
        setAlert("");
        setLoad(false);
    };
    //Volver a la tabla principal desde el flujo
    const handleOnGoBack = () => {
        setInfoTable((prevState) => ({
            ...prevState,
            flowItem: false
        }));
        setInfoTable((prevState) => ({
            ...prevState,
            itemSelected: ""
        }));
        setFiltersByTable([]);
    }
    //Mostrar y ocultar modal
    const handleOnViewModal = (value) => {

        if (value === "close") {
            setInfoTable((prevState) => ({
                ...prevState,
                itemSelected: "",
            }));

            setModal((prevState) => ({
                ...prevState,
                type: ""
            }))
        }
        setModal((prevState) => ({
            ...prevState,
            visible: !openModal.visible

        }))
    }
  
    //Guardar la nuevo registro o actualizar nuevo registro
    const handleOnSave = () => {
        let cont = 0;
        const forms = document.querySelectorAll(".isValid input, select");
        Array.from(forms).forEach((input) => {
            if (input.required === true && input.value === "") {
                document.getElementsByClassName("css-yk16xz-control")[0].setAttribute("style", "border-color:#fb6340;");
                input.classList.add("is-invalid");
                cont++;
            } else {
                document.getElementsByClassName("css-yk16xz-control")[0].removeAttribute("style");
                input.classList.remove("is-invalid");
            }
            if (input.required === true && (input.value === "" || input.value === "---")) {
                input.classList.add("is-invalid");
                cont++;
            } else {
                input.classList.remove("is-invalid");
            }
        }
        );
        if (cont === 0) {
            setAlert(
                <ReactBSAlert
                    custom
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title={"¿Esta segur@ que desea guardar?"}
                    customIcon={
                        <div
                            className="swal2-icon swal2-question swal2-animate-question-icon"
                            style={{ display: "flex" }}
                        >
                            <span className="swal2-icon-text">?</span>
                        </div>
                    }
                    onConfirm={openModal.type === "edit" ? () => handleOnUpdateRequest() : () => handleOnInsertRequest()}
                    onCancel={() => setAlert("")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sí, guardar"
                    cancelBtnText="No, cancelar"
                    btnSize="md"
                />
            );
        }
    };
    console.log(filtersByTable);
    return (
        <div>
            {alert}
            {infoTable.flowItem === true ?
                <Flows
                    flow={infoTable.flowInfo}
                    goBack={handleOnGoBack}
                    flowInfo={infoTable.itemSelected} />
                : thisTable !== "Admin" &&
                <>
                    <Filters
                        jsonFilters={thisTable === "reportUS" ? rol !== "BS Admin" ? filtersUS.filter(item => item.isBS === false) : filtersUS : rol !== "BS Admin" ? filtersOr.filter(item => item.isBS === false) : filtersOr}
                        optionsFilters={valueSelect}
                        handleOnChangeTable={handleOnChangeTable}
                        thisTable={thisTable}
                        filters={handleOnfilterDinamic}
                        rol={rol}
                    />

                    {thisTable === "reportUS" ?
                        <Table
                            text={"Detalles de ordenes para inventario"}
                            handleTakeInfoRow={handleTakeInfoRow}
                            colums={tableReportUs}
                            rows={renderRowsByFiltersRUS(infoTable.reportUs)}
                            page={page}
                            setPage={setPage}
                            thisTable={thisTable}
                            filters={handleOnfilterDinamic}
                            handleOnFlowItem={handleOnFlowItem}
                            handleOnViewModal={handleOnViewModal}
                            rol={rol}
                            type={1}
                        />
                        :
                        <Table
                            text={"Ordenes pendientes de asignación"}
                            handleTakeInfoRow={handleTakeInfoRow}
                            colums={tableOrders}
                            rows={renderRowsByFilters(infoTable.orderAssing)}
                            page={page}
                            setPage={setPage}
                            thisTable={thisTable}
                            filters={handleOnfilterDinamic}
                            handleOnViewModal={handleOnViewModal}
                            rol={rol}
                            type={2}
                        />
                    }
                </>
            }
         

            <Modals
                openModal={openModal.visible}
                handleOnViewModal={handleOnViewModal}
                text={openModal.type === "edit" ? "Editar solicitud" : "Nueva linea"}
                filters={handleOnfilterDinamic}
                itemModal={thisTable === "reportUS" ? tableReportUs : tableOrders}
                itemInfo={infoTable.itemSelected}
                valueSelect={valueSelect}
                handleOnChangeInfo={handleOnChangeInfo}
                saveButton={handleOnSave}
            />
          
        </div>
    );
};

export default MainPage;