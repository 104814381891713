import React from 'react';
import {
    Card,
    CardBody,
    Col,
    CardHeader,
    FormGroup,
    Input,
    Button,
    Row,
    CardFooter,
    Modal,
    Form,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";

const Modals = ({ openModal, handleOnViewModal, itemModal, filters, text, itemInfo, valueSelect, handleOnChangeInfo, saveButton }) => {

    return (
        <Modal size="lg" isOpen={openModal}>
            <Card>
                <CardHeader>
                    <h4 className="surtitle">{text}</h4>
                </CardHeader>
                <CardBody>
                    <Form noValidate className="isValid needs-validation">
                        <Row>
                            {
                                itemModal.map((item, key) => (
                                    <Col xs={item.colWidth} key={key}>
                                        {item.type === "select" ?

                                            <FormGroup>
                                                <label
                                                    style={{ fontSize: "12px" }}
                                                    className="form-control-label"
                                                >
                                                    {item.label}:
                                                </label>
                                                <Select
                                                    isDisabled={itemInfo !== "" ? item.disabled : false}
                                                    key={key}
                                                    className="basic-single is-invalid"
                                                    type="select"
                                                    required={item.required}
                                                    isSearchable
                                                    options={valueSelect[item.valueSelect]}
                                                    defaultValue={{ label: itemInfo[item.value], value: 1 } || ""}
                                                    onChange={(e) => handleOnChangeInfo(item.valueSelect, e.value)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacio
                                                </div>
                                            </FormGroup>
                                            : <FormGroup>
                                                <label className="form-control-label">
                                                    {item.label}:
                                                </label>
                                                <Input
                                                    disabled={itemInfo !== "" ? item.disabled : false}
                                                    defaultValue={itemInfo[item.value] && (item.type === "date"
                                                        ? moment.utc(itemInfo[item.value]).format("YYYY-MM-DD")
                                                        : itemInfo[item.value])}
                                                    key={key}
                                                    required={item.required}
                                                    className="m-0"
                                                    type={item.type}
                                                    maxLength={item.maxlength}
                                                    onChange={(e) => handleOnChangeInfo(item.value, e.target.value)}
                                                />
                                                <div className="invalid-feedback">
                                                    Este campo no puede estar vacio
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                ))
                            }
                        </Row>
                    </Form>
                </CardBody>
                <CardFooter>
                    <Row className="d-flex justify-content-between">
                        <Col xs="12" md="3" className="p-1">
                            <Button
                                className="btn  btn-icon"
                                color="danger"
                                block
                                type="button"
                                onClick={() => handleOnViewModal("close")}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-times"></i>
                                </span>
                                <span className="btn-inner--text">Cerrar</span>
                            </Button>
                        </Col>
                        <Col xs="12" md="3" className="p-1">
                            <Button
                                className="btn-icon"
                                color="success"
                                block
                                type="button"
                                onClick={() => saveButton()}
                            >
                                <span className="btn-inner--icon mr-">
                                    <i className="fas fa-save"></i>
                                </span>
                                <span className="btn-inner--text">
                                    Guardar
                                </span>
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </Modal>
    );
};

export default Modals;