// core actions
import { getUUID } from "actions/auth.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library
import React, { Component, useState } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-redux library for conect redux
import { connect } from "react-redux";
// react-router-dom library for router
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Col,
  Form as FormRS,
  FormGroup,
  Input,
  InputGroup,
  Progress,
  Row,
  Nav, NavItem, NavLink

} from "reactstrap";
// core selectors
import { getTokenAuth } from "selectors/adminLayout";
import { verifyToken } from "helpers/verifyToken.jsx";
import { validate as uuidv4 } from "uuid";

import bgImage from '../../assets/img/login/backgroundLogin.jpg'; // Ajusta la ruta según la ubicación del archivo
import logoImage from '../../assets/img/login/logoBlanco.png'; // Asegúrate de importar la imagen que quieres usar

class LoginWaitDark extends Component {
  state = {};

  //instalar UUID y validar lo que se esta esperando
  componentWillMount() {
    const { id } = this.props.match.params;
    if (uuidv4(id) === true) {
      const hasToken = verifyToken();
      if (hasToken) {
        this.props.history.push("/admin");
      } else {
        this.props.getUUID(id).then((res) => {
          if (res.payload.isAxiosError) {
            if (res.payload.response) {
              const {
                data: { message },
              } = res.payload.response;
              this.notify("danger", "Falló", message);
            } else {
              this.notify(
                "danger",
                "Falló",
                "No se logro establecer conexion con el servidor."
              );
            }
            setTimeout(() => {
              this.props.history.push("/");
            }, 2000);
          } else {
            const {
              data: { token, avatar },
            } = res.payload;
            localStorage.setItem("token", token);
            localStorage.setItem("avatar", avatar);
            this.props.history.push("/admin");
          }
        });
      }
    } else {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    document.body.classList.add("bg-default");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  /**
   * Funcion para el manejo de notificaciones parametrizadas
   * @memberof OptionalsPositions
   */
  notify = (type, title, message) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (Object.keys(this.refs).length)
      if (this.props.history.location.pathname === this.props.location.pathname)
        this.refs.notificationAlert.notificationAlert(options);
  };

  validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Este campo es obligatorio";
    }
    if (!values.password) {
      errors.password = "Este campo es obligatorio";
    }
    return errors;
  };

  renderInputComponent = (props) => {
    const { placeholder, input, inputProps, meta } = props;
    return (
      <FormGroup>
        <InputGroup className="input-group-merge input-group-alternative">
          {inputProps}
          <Input
            {...input}
            placeholder={placeholder}
            invalid={meta.error && meta.touched}
          />
        </InputGroup>
        {meta.error && meta.touched && (
          <span
            style={{
              marginTop: "0.25rem",
              fontSize: "80%",
              color: "#fb6340",
            }}
          >
            {meta.error}
          </span>
        )}
      </FormGroup>
    );
  };

  handleOnSubmit = async () => {
    // console.log(values);
    await this.props
      .authUser({ username: "joarojas@gbm.net", password: "Lanchas2" })
      .then((res) => {
        if (res.payload.isAxiosError) {
          if (res.payload.response) {
            const {
              data: { payload },
            } = res.payload.response;
            this.notify("danger", "Falló", payload.message);
          } else {
            this.notify(
              "danger",
              "Falló",
              "No se logro establecer conexion con el servidor."
            );
          }
        } else {
          const { token } = this.props;
          // console.log(token);
          localStorage.setItem("token", token);
          this.props.history.push(`${this.props.match.url}admin`);
        }
      });
  };


  render() {


    // Define los estilos en línea
    const col1Style = {
      position: 'relative',
      backgroundImage: `url(${bgImage})`, // Utiliza la imagen importada
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh', // Ajusta la altura según sea necesario
      color: 'white' // Color del texto
    };


    const col2Style = {
      backgroundImage: 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1))',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh', // Ajusta la altura según sea necesario
      color: 'white', // Color del texto
      padding: '20px', // Ajusta el relleno si es necesario
    };

    // Estilo para el contenedor del texto
    const textContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Centra verticalmente
      alignItems: 'center', // Centra horizontalmente
      height: '100%', // Asegura que el contenedor ocupe toda la altura de la columna
      textAlign: 'center' // Centra el texto
    };



    // Estilo para el texto con efecto de difuminado
    const textStyle = {
      fontSize: '1.45rem',
      fontFamily: '"Plus Jakarta Display", "Helvetica", "Arial", sans-serif',
      background: 'linear-gradient(to right, white 85%, rgba(255, 255, 255, 0) 100%)', // Gradiente de blanco a transparente al final
      WebkitBackgroundClip: 'text', // Aplica el gradiente al texto
      WebkitTextFillColor: 'transparent', // Hace el texto transparente para mostrar el gradiente
      color: 'white', // Texto blanco en los navegadores que no soportan WebKit
      display: 'inline', // Asegura que el gradiente se aplique solo al texto
      margin: 0 // Ajusta márgenes si es necesario navegadores que no soportan WebKit
    };


    return (
      <>
        <Row>
          <Col xs="6" className="d-none d-md-block" style={col1Style} >
            <div style={textContainerStyle}>
              <h6 className="surtitle" style={textStyle}>WE BUILD YOUR DIGITAL FUTURE:</h6>
              <img  src={logoImage} alt="GBM Logo" style={{ width: '130px', height: 'auto', margin: '5px 0', marginTop: '0px' }} />
            </div>
          </Col>
          <Col xs="12" sm="6" className="" style={col2Style} >
            <div className="rna-wrapper">
              <NotificationAlert ref="notificationAlert" />
            </div>

            <div style={textContainerStyle}>
              <Row>
                <Col sm="12">
                  {<h5 className="h3 mb-4" style={{
                    fontSize: '1.50rem',
                    color: 'white',
                  }} >Bienvenido a Smart & Simple</h5>}

                </Col>

                <Col sm="12" className="mt-2">
                  <img src={logoImage} alt="GBM Logo" style={{ width: '160px', height: 'auto', margin: '5px 0', marginTop: '0px' }} />

                </Col>
                <Col sm="12" className="mt-5">
                  <div className="text-center text-muted mb-4" >
                    <h4 style={{ color: 'white' }}>Esperando información...</h4>
                  </div>

                  <div className="pl-3 pr-3">
                    <Progress animated color="default" value="100" />

                  </div>
                </Col>

              </Row>
            </div>
            <Row style={{
              alignItems: 'center', // Centra horizontalmente
            }}>
              <Col className=" mt--4" sm="12 " style={{
                display: 'flex',
                flexDirection: 'column', // Alinea los elementos en una columna
                justifyContent: 'flex-end', // Alinea los elementos al final (parte inferior)
                height: '90%', // Ocupa toda la altura de la vista
                alignItems: 'center', // Centra horizontalmente

              }}>

                <Nav className="copyright text-center text-xl-left text-muted">
                  <NavItem>
                    <NavLink
                      target="_blank">
                      © {new Date().getFullYear()}{" "}
                      GBM Digital
                    </NavLink>
                  </NavItem>
                </Nav>

              </Col>
            </Row>

          </Col>
        </Row>

      </>
    );
  }
}

LoginWaitDark.defaultProps = {
  token: "",
};

LoginWaitDark.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  token: getTokenAuth(state),
});

export default withRouter(connect(mapStateToProps, { getUUID })(LoginWaitDark));
