import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Row, Input, FormGroup } from "reactstrap";
import { Container } from "reactstrap";
import AdminHeader from "components/Shared/Header/AdminHeader.jsx";
import Tables from "components/UserCloudRequest/Tables";
import Modal from "components/UserCloudRequest/AdminUserCloudRequest/Modals.jsx";
// import Filters from "components/UserCloudRequest/Filters"
import { useDispatch } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  getAllTenant,
  createTenant,
  updateTenant,
  deleteTenant,
} from "actions/userCloudRequests.jsx";
import { Spin } from "antd";

const AdminTenant = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [modalConfirm, setModalConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [openModal, setModal] = useState({
    createModal: false,
    editModal: false,
  });
  const [itemInfo, setItemInfo] = useState({});
  const [newInfo, setNewInfo] = useState({});
  const [infoToModule, setInfoToModule] = useState({
    rows: [],
    allTenants: [],
    cloudVendor: [{ "label": "Azure", "value": 1 }, { "label": "AWS", "value": 2 }, { "label": "Google", "value": 3 }, { "label": "IBM", "value": 4 }],
    country: [{ "label": "Costa Rica", "value": 1 }, { "label": "El Salvador", "value": 2 }, { "label": "Colombia", "value": 3 }, { "label": "Corporation", "value": 4 }, { "label": "Guatemala", "value": 5 }, { "label": "Honduras", "value": 6 }, { "label": "Nicaragua", "value": 7 }, { "label": "Panamá", "value": 8 }, { "label": "BVI.", "value": 9 }, { "label": "Miami", "value": 10 }, { "label": "República Dominicana", "value": 11 }, { "label": "Venezuela", "value": 12 }]
  });

  const notifyEl = useRef(null);

  const jsonFilters = [
    { label: "Tenant ID" },
    { label: "Nombre compañia" },
    { label: "Relación" },
    { label: "País" },
    { label: "Proveedor" },
  ];
  const colums = [
    { label: "Tenant ID", value: "tenantId", type: "text" },
    { label: "Nombre compañia", value: "companyName", type: "text" },
    {
      label: "Nombre dominio principal",
      value: "primaryDomainName",
      type: "text",
    },
    { label: "País", value: "countryName", type: "text" },
    { label: "Proveedor", value: "cloudVendorName", type: "text" },
  ];

  //Campos del modal generico para esta vista
  const modalInformation = [
    {
      label: "Tenant ID",
      value: "tenantId",
      type: "input",
      colWidth: "12",
      required: true,
    },
    {
      label: "Nombre compañia",
      value: "companyName",
      type: "input",
      colWidth: "12",
      required: true,
    },
    {
      label: "Nombre dominio principal",
      value: "primaryDomainName",
      type: "input",
      colWidth: "12",
      required: true,
    },
    {
      label: "Relación",
      value: "relationship",
      type: "input",
      colWidth: "12",
      required: true,
    },
    {
      label: "Etiquetas",
      value: "tags",
      type: "input",
      colWidth: "12",
      required: true,
    },
    {
      label: "Proveedor de nube",
      value: "cloudVendorName",
      valueInsert: "cloudVendorId",
      type: "select",
      colWidth: "12",
      required: true,
      multi: false,
      options: infoToModule.cloudVendor,
    },
    {
      label: "País",
      value: "countryName",
      valueInsert: "countryId",
      type: "select",
      colWidth: "12",
      required: true,
      multi: false,
      options: infoToModule.country,
    },
  ];

  const dispatch = useDispatch();

  const aditionalsButtons = [
    {
      color: "success",
      icon: "fas fa-pencil-alt",
      function: (e) => handleOnOpenModalEdit(e),
      tool: "Editar",
      id: "edit",
    },
    {
      color: "danger",
      icon: "fas fa-trash-alt",
      function: (e) => deleteTenantById(e),
      tool: "Eliminar",
      id: "delete",
    },
  ];
  //Funcion para los filtros por columna
  const handleOnfilterDinamic = (constant, e) => {
    setFilters((prevState) => ({
      ...prevState,
      [constant]: e,
    }));
  };

  //Aplicar los filtros a la data de la tabla
  const renderRowsByFilters = (rows) => {
    if (Object.keys(filters).length === 0) {
      return rows;
    } else {
      const filterKeys = Object.keys(filters);
      for (const element of filterKeys) {
        const valueFiltered = filters[element].toString().toLowerCase();
        rows = rows.filter(
          (item) =>
            item[element].toString().toLowerCase().indexOf(valueFiltered) != -1
        );
      }
      return rows;
    }
  };

  //Funcion para hacer los cambios de informacion en los campos de input, select .etc
  const handleOnChangeInfo = (constant, value) => {
    setNewInfo((prevState) => ({
      ...prevState,
      [constant]: value,
    }));
  };
  //Funcion para cambiar la informacion que vamos a editar
  const handleOnChangeEditInfo = (constant, value) => {
    setItemInfo((prevState) => ({
      ...prevState,
      [constant]: value,
    }));
  };

  const closeModal = () => {
    setModalConfirm("");
  };

  const notify = (type, title, message) => {
    try {
      let options = {
        place: "br",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {title}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 7,
      };
      notifyEl.current.notificationAlert(options);
    } catch (error) { }
  };

  //Funcion que muestra los modales de confirmacion
  const showModalConfirmation = () => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea crear el nuevo proveedor?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => createNewTenant()}
        onCancel={() => closeModal()}
        confirmBtnText="Crear"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //Funcion que muestra los modales de confirmacion
  const showModalConfirmationEdit = () => {
    setModalConfirm(
      <ReactBSAlert
        custom
        showCancel
        title={"¿Seguro que desea editar el tenant?"}
        customIcon={
          <div
            className="swal2-icon swal2-question swal2-animate-question-icon"
            style={{ display: "flex" }}
          >
            <span className="swal2-icon-text">?</span>
          </div>
        }
        placeHolder="Write something"
        onConfirm={() => editTenant()}
        onCancel={() => closeModal()}
        confirmBtnText="Guardar"
        cancelBtnText="Cancelar"
        btnSize="md"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        openAnim={{ name: "showSweetAlert", duration: 1500 }}
        closeAnim={{ name: "hideSweetAlert", duration: 1000 }}
      ></ReactBSAlert>
    );
  };

  //Funcion para abrir el modal de crear nueva registro en la tabla principal
  const handleOnOpenModalCreate = (constant) => {
    setModal((prevState) => ({
      ...prevState,
      createModal: !openModal.createModal,
    }));
    if (openModal.createModal) {
      setNewInfo({});
    }
  };

  //Funcion para abrir el modal de editar registro en la tabla principal
  const handleOnOpenModalEdit = (item) => {
    setModal((prevState) => ({
      ...prevState,
      editModal: !openModal.editModal,
    }));
    setItemInfo(item);
    if (openModal.editModal) {
      setItemInfo({});
      setNewInfo({});
    }
  };

  //Funcion que elimina un registro de la tabla principal
  const deleteTenantById = (item) => {
    const {id} = item;
    dispatch(deleteTenant(id)).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        notify("success", "Éxito", "Se creó correctamente");
        setReload(!reload);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const getProvider = async () => {
      await handleOnGetTenant();
    };
    getProvider();
  }, [reload]);
  //Funcion que trae la data de los campos selects
  const handleOnGetTenant = async () => {
    setLoading(true);
    dispatch(getAllTenant()).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setInfoToModule((prevState) => ({
          ...prevState,
          rows: payload.data.data,
        }));
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
    setLoading(true);
  };

  //
  const createNewTenant = () => {
    setLoading(true);
    let body = {
      tenantId: newInfo.tenantId,
      companyName: newInfo.companyName,
      primaryDomainName: newInfo.primaryDomainName,
      relationship: newInfo.relationship,
      tags: newInfo.tags,
      cloudVendorId: newInfo.cloudVendorId,
      countryId: newInfo.countryId,
    };
    dispatch(createTenant(body)).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setNewInfo({});
        setModalConfirm("");
        setModal((prevState) => ({
          ...prevState,
          createModal: !openModal.createModal,
        }));
        notify("success", "Éxito", "Se creó correctamente");
        setReload(!reload);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
    setLoading(true);
  };

  //
  const editTenant = () => {
    setLoading(true);
    let id = itemInfo.id;
    let body = {
      tenantId: itemInfo.tenantId,
      companyName: itemInfo.companyName,
      primaryDomainName: itemInfo.primaryDomainName,
      relationship: itemInfo.relationship,
      tags: itemInfo.tags,
      cloudVendorId: itemInfo.cloudVendorId,
      countryId: itemInfo.countryId,
    };
    dispatch(updateTenant(id, body)).then((resp) => {
      const { payload } = resp;
      if (payload.status === 200) {
        setItemInfo({});
        setModalConfirm("");
        setModal((prevState) => ({
          ...prevState,
          editModal: !openModal.editModal,
        }));
        notify("success", "Éxito", "Se creó correctamente");
        setReload(!reload);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
    setLoading(true);
  };

  const validateInfo = (item) => {
    return !(item.tenantId) || !(item.companyName) || !(item.primaryDomainName) || !(item.relationship) || !(item.cloudVendorId) || !(item.countryId)
  }

  return (
    <div>
      {modalConfirm}
      <AdminHeader name="Solicitudes" parentName="Usuarios Cloud" />
      <Spin size="large" spinning={loading}>
        <Container className="mt--6" fluid>
          <Tables
            page={page}
            setPage={setPage}
            colums={colums}
            aditionalsButtons={aditionalsButtons}
            filters={handleOnfilterDinamic}
            rows={renderRowsByFilters(infoToModule.rows)}
            subtitle={"Tenants"}
            title={"Todos los tenants"}
            thText={"Acciones"}
            createButton={true}
            handleOnNewElement={handleOnOpenModalCreate}
          />
          <Modal
            size={12}
            openModal={openModal.createModal}
            saveButtonDisabled={validateInfo(newInfo)}
            handleOnOpenModal={handleOnOpenModalCreate}
            itemModal={modalInformation}
            text={"Nuevo proveedor de nube"}
            itemInfo={newInfo}
            handleOnChangeInfo={handleOnChangeInfo}
            saveButton={showModalConfirmation}
            nameButton1={"Cerrar"}
            nameButton2={"Guardar"}
          />
          <Modal
            size={12}
            openModal={openModal.editModal}
            saveButtonDisabled={validateInfo(itemInfo)}
            handleOnOpenModal={handleOnOpenModalEdit}
            itemModal={modalInformation}
            text={"Editar proveedor de nube"}
            itemInfo={itemInfo}
            handleOnChangeInfo={handleOnChangeEditInfo}
            saveButton={showModalConfirmationEdit}
            nameButton1={"Cerrar"}
            nameButton2={"Guardar"}
          />
        </Container>
      </Spin>
    </div>
  );
};

export default AdminTenant;
