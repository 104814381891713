import { createAction } from 'redux-actions';
import urls from "api/urls.jsx";
import * as constants from "constants/index.jsx";
import * as method from "api/index.jsx";


export const getEmployeeRequest = createAction(constants.GET_EMPLOYEE_REQUEST, (id) => method.get(`${urls.ITREQUESTS.getEmployeeRequest}/${id}`)());
export const getCountryUpdateList = createAction(constants.GET_COUNTRY_UPDATE_LIST, body => method.post(urls.ITREQUESTS.getCountryUpdateList, body)());
export const getIdRequest = createAction(constants.GET_ID_REQUEST, (id) => method.get(`${urls.ITREQUESTS.getIdRequest}/${id}`)());
export const getOptions = createAction(constants.GET_OPTIONS, method.get(urls.ITREQUESTS.getOptions));
export const getOpenRequests = createAction(constants.GET_OPEN_REQUESTS, body => method.post(urls.ITREQUESTS.getOpenRequests, body)());
export const getStepConfig = createAction(constants.GET_STEP_CONFIG, method.get(urls.ITREQUESTS.getStepConfig));
export const getSearchRequests = createAction(constants.GET_SEARCH_REQUESTS, body => method.post(urls.ITREQUESTS.getSearchRequests, body)());
export const newRequest = createAction(constants.REQUEST_DATA, body => method.post(urls.ITREQUESTS.requestData, body)());
export const getRoles = createAction(constants.GET_ROLES, method.get(urls.ITREQUESTS.getRoles));
export const getExceptions = createAction(constants.GET_EXCEPTIONS, method.get(urls.ITREQUESTS.getExceptions));
export const checkColabId = createAction(constants.CHECK_COLAB_ID, (id) => method.get(`${urls.ITREQUESTS.checkColabId}/${id}`)());
export const getSearchRequestFile = createAction(constants.GET_SEARCH_REQUEST_FILE, body => method.post(urls.ITREQUESTS.getSearchRequestFile, body)());
export const cancelRequest = createAction(constants.CANCEL_REQUEST, body => method.post(urls.ITREQUESTS.cancelRequest, body)());
export const getDistinctUsers = createAction(constants.GET_DISTINCT_USERS, body => method.post(urls.ITREQUESTS.getDistinctUsers, body)());
export const addMatrixRole = createAction(constants.ADD_MATRIX_ROLE, body => method.post(urls.ITREQUESTS.addMatrixRole, body)());
export const addMatrixException = createAction(constants.ADD_MATRIX_EXCEPTION, body => method.post(urls.ITREQUESTS.addMatrixException, body)());
export const deleteMatrixRole = createAction(constants.DELETE_MATRIX_ROLE, body => method.post(urls.ITREQUESTS.deleteMatrixRole, body)());
export const deleteMatrixException = createAction(constants.DELETE_MATRIX_EXCEPTION, body => method.post(urls.ITREQUESTS.deleteMatrixException, body)());