// export const urlBase = "http://localhost:43888";
// export const urlLogin = "https://vault-qa.gbm.net";
export const urlBase = "https://smartsimple.gbm.net:43888";
export const urlLogin = "https://vault.gbm.net";
export default {
  SIGNIN: {
    auth: `${urlBase}/auth`,
    protected: `${urlBase}/protected`,
    signOut: `${urlBase}/auth/sign-out`,
    generateSignature: `${urlBase}/auth/signature`,
    getSession: `${urlLogin}/vault/find-session-by-uuid`,
    getUrlCisco: `${urlLogin}/authenticate/ss`,
  },
  PLANNINGMRS: {
    getNodes: `${urlBase}/planning-mrs/find-nodes`,
    getResourses: `${urlBase}/planning-mrs/find-resourses`,
  },
  EXTRAHOURS: {
    getExtras: `${urlBase}/extra-hours/find-extras`,
    getDateExtras: `${urlBase}/extra-hours/find-date-extras`,
    getSumAllYearHoursWidget: `${urlBase}/extra-hours/widget-year-extras`,
    getSumAllYearHoursGraph: `${urlBase}/extra-hours/graph-year-extras`,
    getSumCountryYearHoursWidget: `${urlBase}/extra-hours/widget-country-extras`,
    getSumCountryYearHoursGraph: `${urlBase}/extra-hours/graph-country-extras`,
    getUsersByYear: `${urlBase}/extra-hours/get-user-year`,
    getHoursByUser: `${urlBase}/extra-hours/get-hours-user`,
    getMonthWidgets: `${urlBase}/extra-hours/get-hours-month`,
    getMonthWidgetsCountry: `${urlBase}/extra-hours/get-hours-month-country`,
    getUserRolesExtra: `${urlBase}/extra-hours/get-roles-extra`,
    getExtrasToSend: `${urlBase}/extra-hours/find-extras-send`,
    getUpdateExtrasSAP: `${urlBase}/extra-hours/update-extras-SAP`,
    updateExtraInfo: `${urlBase}/extra-hours/update-extra-info`,
    getYearsExtras: `${urlBase}/extra-hours/get-years-extra`,
    getUsersWithAccess: `${urlBase}/extra-hours/find-users-with-access`,
    deactivateUserRol: `${urlBase}/extra-hours/deactivate-user-role`,
    createUserRol: `${urlBase}/extra-hours/create-user-rol`,
    getExtraHourByCeo: `${urlBase}/extra-hours/find-extra-hour-by-ceo`,
    getHCWithAccess: `${urlBase}/extra-hours/find-hc-with-access`,
    createUserHCAccess: `${urlBase}/extra-hours/create-user-hc-access`,
    deactivatedHCWithAccess: `${urlBase}/extra-hours/deactivate-hc-with-access`,
    updateStatus: `${urlBase}/extra-hours/update-status`,
    getReasonForChange: `${urlBase}/extra-hours/get-reason-for-change`,
    getPreapproversData: `${urlBase}/extra-hours/get-preapprovers-data`,
    createPreapproverRole: `${urlBase}/extra-hours/create-approver-rol`,
    deletePreapproverRole: `${urlBase}/extra-hours/delete-preapprover-role`,
  },
  MIS: {
    getInactive: `${urlBase}/mis/inactive`,
    updateInactiveInfo: `${urlBase}/mis/update-inactive-info`,
    insertInactive: `${urlBase}/mis/insert-inactive`,
    getProjects: `${urlBase}/mis/projects`,
    getCountProjects: `${urlBase}/mis/count-projects`,
    updateProjectsInfo: `${urlBase}/mis/update-projects-info`,
    insertProjects: `${urlBase}/mis/insert-projects`,
    getMaintenance: `${urlBase}/mis/maintenance`,
    updateMaintenanceInfo: `${urlBase}/mis/update-maintenance-info`,
    insertMaintenance: `${urlBase}/mis/insert-maintenance`,
    getInternalTeams: `${urlBase}/mis/find-internal-teams`,
  },
  NEWPOSITION: {
    getPositions: `${urlBase}/new-position/find-positions`,
    getDataByPosition: `${urlBase}/new-position/find-data`,
    getDataUpdateByPosition: `${urlBase}/new-position/find-data-by-position`,
    getRelationDataByPosition: `${urlBase}/new-position/find-relation-data-by-position`,
    getTotalPositionsCreated: `${urlBase}/new-position/find-total-positions-created`,
    getUnpprovedVacantsRequest: `${urlBase}/new-position/find-unapproved-vacant-position`,
    getAllRequestByUsers: `${urlBase}/new-position/find-all-request-by-users`,
    getRequestByUser: `${urlBase}/new-position/find-request-by-user`,
    getVacantById: `${urlBase}/new-position/find-vacant-position-by-id`,
    createUnplannedPosition: `${urlBase}/new-position/create-unplanned-position`,
    updateStaffPosition: `${urlBase}/new-position/update-staff-position`,
    updateVacantPosition: `${urlBase}/new-position/update-vacant-position`,
    createRelationsByPosition: `${urlBase}/new-position/create-relations-position`,
    createStaffPosition: `${urlBase}/new-position/create-staff-position`,
    createVacantPosition: `${urlBase}/new-position/create-vacant-position`,
    approvedVacantPosition: `${urlBase}/new-position/approved-vacant-position`,
    unapprovedVacantPosition: `${urlBase}/new-position/unapproved-vacant-position`,
    getUsersWithAccess: `${urlBase}/new-position/find-users-with-access`,
    createUserWithAccess: `${urlBase}/new-position/create-user-with-access`,
    deactivatedUserWithAccess: `${urlBase}/new-position/deactivate-user-with-access`,
    updateNewCecoPosition: `${urlBase}/new-position/update-new-ceco`,
    // createRelationsByPosition: `${urlBase}/new-position/create-relations-position`,
    // updateVacantPosition: `${urlBase}/new-position/update-vacant-position`,
    getAllMasterData: `${urlBase}/new-position/find-all-master-data`,
    createMasterData: `${urlBase}/new-position/create-master-data`,
  },
  WMS: {
    transactionCount: `${urlBase}/wms/transactions/count`,
    loginTotals: `${urlBase}/wms/transactions/login/count`,
    mostExecutedTransaction: `${urlBase}/wms/transactions/most-executed`,
    executedTotals: `${urlBase}/wms/transactions/executed`,
    movementsTotals: `${urlBase}/wms/transactions/movements/totals`,
    locationsTotals: `${urlBase}/wms/transactions/country/count`,
    monthsTotals: `${urlBase}/wms/transactions/months`,
    daysTotals: `${urlBase}/wms/transactions/traffic`,
  },
  SO: {
    allOrders: `${urlBase}/so`,
    ordersByCountry: `${urlBase}/so/country/PA`,
    ordersByUser: `${urlBase}/so/user`,
    orderDetails: `${urlBase}/so/`,
  },
  COE: {
    getAvailableFilters: `${urlBase}/coe/find-available-filters`,
    getDataByCalendarMode: `${urlBase}/coe/find-data-by-calendar-mode`,
    getFiltersDataByFilter: `${urlBase}/coe/find-filter-data`,
    getHistoryDataByCalendarMode: `${urlBase}/coe/find-history-data-by-calendar-mode`,
    getIndicatorDataByCalendarMode: `${urlBase}/coe/find-indicator-data-by-calendar-mode`,
    getCollaboratorsReported: `${urlBase}/coe/find-collaborators-reported`,
    getHoursAccusedByDates: `${urlBase}/coe/find-hours-accused-by-dates`,
    getValuesMaintenanceUsers: `${urlBase}/coe/find-values-maintenance-users`,
    getActivitiesReportByDates: `${urlBase}/coe/find-activities-report-by-dates`,
    createUserCoe: `${urlBase}/coe/create-user-coe`,
    getAllEmaUsersActive: `${urlBase}/coe/find-all-ema-users-active`,
    updateUserCoe: `${urlBase}/coe/update-user-coe`,
    createMasterVariable: `${urlBase}/coe/create-master-variable`,
    updateMasterVariableByID: `${urlBase}/coe/update-master-variable-by-id`,
    deactivateMasterVariableByID: `${urlBase}/coe/delete-master-variable-by-id`,
  },
  DIGITALSIGNATURE: {
    getAllYearsSignatures: `${urlBase}/digital-signature/find-all-years-signatures`,
    getDataByDashboard: `${urlBase}/digital-signature/find-data-by-dashboard`,
    getDataDashboardByYear: `${urlBase}/digital-signature/find-data-by-countrys-dashboard`,
    getDataByRegisterSignature: `${urlBase}/digital-signature/find-data-register-form`,
    getSignaturesPendingBySupervisor: `${urlBase}/digital-signature/find-signatures-pending-by-supervisor`,
    getSignaturesPendingByCollaborator: `${urlBase}/digital-signature/find-signatures-pending-by-collaborator`,
    getFlowLogByIdFlow: `${urlBase}/digital-signature/find-flow-log-by-id`,
    getHomeOfficeInfoById: `${urlBase}/digital-signature/find-home-office-info-by-id`,
    createPolicySignature: `${urlBase}/digital-signature/create-policy-signature`,
    createPolicyUserSignature: `${urlBase}/digital-signature/create-policy-user-signature`,
    createExeptionUserSignature: `${urlBase}/digital-signature/create-exception-user-signature`,
    getSignaturesByUser: `${urlBase}/digital-signature/find-signatures-by-user`,
    sendAllDocumentsSigned: `${urlBase}/digital-signature/send-all-documents-signed`,
    getUsersWithAccess: `${urlBase}/digital-signature/find-users-with-access`,
    createUserAccess: `${urlBase}/digital-signature/create-user-access`,
    deactivatedUserWithAccess: `${urlBase}/digital-signature/deactivate-user-with-access`,
    updateStateSignatureInFlow: `${urlBase}/digital-signature/update-state-signature-in-flow`,
    updateSigntureByCollaborator: `${urlBase}/digital-signature/update-signature-by-collaborator`,
    relaunchSigntureByCollaborator: `${urlBase}/digital-signature/relaunch-signature-by-collaborator`,
    relaunchHomeOfficeSignature: `${urlBase}/digital-signature/relaunch-home-office-signature`,
  },
  NOTIFICATIONS: {
    getAllNotifications: `${urlBase}/notifications/find-notifications`,
  },
  CRITICALPARTS: {
    getAllRequerimentByUser: `${urlBase}/critical-parts/find-all-requeriments-by-user`,
    getValuesAndEquipmentsByRequirement: `${urlBase}/critical-parts/find-values-and-equipments-by-requirement`,
    createRequirement: `${urlBase}/critical-parts/create-requirement`,
    createIbmEquipment: `${urlBase}/critical-parts/create-ibm-equipment`,
    createCiscoEquipment: `${urlBase}/critical-parts/create-cisco-equipment`,
    getCriticalParts: `${urlBase}/critical-parts/find-critical-parts`,
    getFilteredModels: `${urlBase}/critical-parts/find-filtered-models`,
    createCriticalParts: `${urlBase}/critical-parts/create-critical-parts`,
    deleteCriticalPart: `${urlBase}/critical-parts/delete-critical-part`,
    updateCriticalPart: `${urlBase}/critical-parts/update-critical-part`,
    createModelFilter: `${urlBase}/critical-parts/create-filter-model`,
    deleteDebuggedModel: `${urlBase}/critical-parts/delete-debugged-model`,
    downloadCriticalParts: `${urlBase}/critical-parts/download-critical-parts`,
    findAllUsersMaintenance: `${urlBase}/critical-parts/find-all-users-maintenance`,
    validateUserInSystemByUsername: `${urlBase}/critical-parts/validate-user-in-system-by-username`,
    createUserInCriticalPartsAdminBySignID: `${urlBase}/critical-parts/create-user-in-critical-parts-admin-by-sign-id`,
    deactivateUserCriticalPartsByID: `${urlBase}/critical-parts/deactivate-user-critical-parts-by-id`,
  },
  TARGETLETTER: {
    getMyTargetsLetters: `${urlBase}/target-letter/find-my-targets-letters`,
    getAllTargetLetter: `${urlBase}/target-letter/find-targets-letters`,
    getTargetLetter: `${urlBase}/target-letter/find-target-letter-by-user`,
    getTargetsLetterHC: `${urlBase}/target-letter/find-targets-letter-hcm`,
    getTargetsLetterHeadShip: `${urlBase}/target-letter/find-target-letter-by-headship`,
    getTargetsLetterHCRegional: `${urlBase}/target-letter/find-target-letter-by-hcrm`,
    getTargetsLetterGeneralManager: `${urlBase}/target-letter/find-target-letter-by-general-manager`,
    getTargetLetterByManagementServicesDirector: `${urlBase}/target-letter/find-target-letter-by-management-service-director`,
    getAllDataDashboard: `${urlBase}/target-letter/find-all-data-dashboard`,
    updateTargetLetterFlow: `${urlBase}/target-letter/update-target-letter-flow`,
    deactivateTargetLetter: `${urlBase}/target-letter/delete-target-letter`,
    applyMeasureTargeLetter: `${urlBase}/target-letter/apply-measure-target-letter`,
    rejectTargeLetterById: `${urlBase}/target-letter/reject-target-letter-by-id`,
  },
  EVENTS: {
    userHideEvent: `${urlBase}/events/hide`,
    makeMonetaryDonation: `${urlBase}/events/donation/monetary`,
    makeDaysDonation: `${urlBase}/events/donation/days`,
  },
  PERFORMANCE: {
    getAllDataDashoard: `${urlBase}/performance/find-all-data-dashboard`,
    getAllSignInDashoard: `${urlBase}/performance/find-all-signin-dashboard`,
    getAllTransactionsDashoard: `${urlBase}/performance/find-all-transactions-dashboard`,
  },
  SALARY: {
    getApprovers: `${urlBase}/salary/approvers`,
    getOtherApprovers: `${urlBase}/salary/approvers/others`,
    createRequest: `${urlBase}/salary`,
    getRequest: `${urlBase}/salary/status`,
    getPendingRequests: `${urlBase}/salary/pending`,
    getCompleteRequests: `${urlBase}/salary/complete`,
    getCancelledRequests: `${urlBase}/salary/cancelled`,
    verifySession: `${urlBase}/salary/session/check`,
    showFullDocument: `${urlBase}/salary/document`,
    cancelRequest: `${urlBase}/salary/cancel`,
    approverAction: `${urlBase}/salary/action`,
    bypassAction: `${urlBase}/salary/action/bypass`,
    reminder: `${urlBase}/salary/reminder`,
    notifications: `${urlBase}/salary/notifications`,
    documentDownload: `${urlBase}/salary/action/download`,
    documentUpload: `${urlBase}/salary/action/upload`,
    documentDelete: `${urlBase}/salary/action/delete`,
    otherApproversInsert: `${urlBase}/salary/other-approvers/insert`,
    otherApproversDelete: `${urlBase}/salary/other-approvers/delete`,
    getUserSign: `${urlBase}/salary/other-approvers/get-users`,
  },
  DIGITALREQUEST: {
    getFormValuesRequestOpportunity: `${urlBase}/digital-request/find-form-values-request-opportunity`,
    getFormValuesEquipmentsRequest: `${urlBase}/digital-request/find-form-values-equipments-request`,
    getRequerimentsByUser: `${urlBase}/digital-request/find-requeriments-by-user`,
    getActivityFlowRequest: `${urlBase}/digital-request/find-activity-flow-request`,
    getEquipmentsByRequest: `${urlBase}/digital-request/find-equipments-by-request`,
    getEquipmentsCreatedByRequest: `${urlBase}/digital-request/find-equipments-ibm-created-by-request`,
    getReferencesByRequest: `${urlBase}/digital-request/find-references-by-request`,
    getReferencesSpareByRequest: `${urlBase}/digital-request/find-references-spare-by-request`,
    getJustificationByRequest: `${urlBase}/digital-request/find-justifications-by-request`,
    getConfigurationByRequest: `${urlBase}/digital-request/find-configurations-by-request`,
    getResumeOffersRequestById: `${urlBase}/digital-request/find-resume-offers-request-by-id`,
    getLastVersionByRequest: `${urlBase}/digital-request/find-last-version-by-request`,
    getOptionsRequestToVersion: `${urlBase}/digital-request/find-options-request-to-version`,
    createRequirement: `${urlBase}/digital-request/create-requirement`,
    createOneEquipment: `${urlBase}/digital-request/create-one-equipment`,
    createManyEquipments: `${urlBase}/digital-request/create-many-equipments`,
    createJustifyRequest: `${urlBase}/digital-request/create-justify-by-request`,
    createCommentaryRequest: `${urlBase}/digital-request/create-commentary-by-request`,
    createServiceOrderRequest: `${urlBase}/digital-request/create-service-order-by-request`,
    createAjustOfferRequest: `${urlBase}/digital-request/create-ajust-offer-by-request`,
    createNewVersionByRequest: `${urlBase}/digital-request/create-new-version-by-request`,
    deactivateReferences: `${urlBase}/digital-request/deactivate-references`,
    deactivateConfigurations: `${urlBase}/digital-request/deactivate-configurations`,
    updateEquipmentById: `${urlBase}/digital-request/update-equipment-by-id`,
    updateActivityFlow: `${urlBase}/digital-request/update-activity-flow`,
    updateStateRequest: `${urlBase}/digital-request/update-state-request`,
    updateEquipmentSpareById: `${urlBase}/digital-request/update-equipment-spare-by-id`,
    createEquipmentSpare: `${urlBase}/digital-request/create-equipment-spare`,
    deactivateReferencesSpare: `${urlBase}/digital-request/deactivate-references-spare`,
    deactivateEquipmentSpareById: `${urlBase}/digital-request/deactivate-equipment-spare-by-id`,
    deactivateReferencesAjustOffer: `${urlBase}/digital-request/deactivate-references-ajust-offer`,
    getEquipmentsSpareByRequest: `${urlBase}/digital-request/find-equipments-spare-by-request`,
    deactivateEquipmentById: `${urlBase}/digital-request/deactivate-equipment-by-id`,
    sendEmailOfferWonRequest: `${urlBase}/digital-request/send-email-offer-won-request`,
    updateAmountEquipmentsByRequest: `${urlBase}/digital-request/update-amount-equipments-by-request`,
    createNewVersionByRejectRequest: `${urlBase}/digital-request/create-new-version-by-reject-request`,
  },
  PLANNERS: {
    getRequestsPendingAssignation: `${urlBase}/planners-flow/find-requests-pending-assignation`,
    getAllRequestsAssignment: `${urlBase}/planners-flow/find-all-requests-assignment`,
    getEquipmentsAssignmentByRequest: `${urlBase}/planners-flow/find-equipments-assignment-by-request`,
    createUserAssignmentByPlanner: `${urlBase}/planners-flow/create-user-assignment-by-planner`,
    validateGbmCollaborator: `${urlBase}/planners-flow/validate-gbm-collaborator`,
    createUserAssignmentWithEquipmentsByPlanner: `${urlBase}/planners-flow/create-user-assignment-with-equipments-by-planner`,
    getUserAssignmentByRequest: `${urlBase}/planners-flow/find-user-assignment-by-request`,
    getAllPartsEquipmentsByRequest: `${urlBase}/planners-flow/find-all-parts-equipments-by-request`,
  },
  ENGINEERS: {
    getRequestsAssignationUser: `${urlBase}/engineers-flow/find-requests-assignation-user`,
    getCriticalPartsKit: `${urlBase}/engineers-flow/find-critical-parts-kit`,
    getEquipmentsSelectedParts: `${urlBase}/engineers-flow/find-equipments-selected-parts`,
    getEquipmentsPendingParts: `${urlBase}/engineers-flow/find-equipments-pending-parts`,
    getEquipmentsIBMRequest: `${urlBase}/engineers-flow/find-equipments-ibm-by-request`,
    getPartsEquipmentsRequest: `${urlBase}/engineers-flow/find-parts-equipments-by-request`,
    getSelectedPartsByEquipment: `${urlBase}/engineers-flow/find-selected-parts-by-equipment`,
    getHistoryPartsEquipmentsRequest: `${urlBase}/engineers-flow/find-history-parts-equipments-by-request`,
    createSelectedPartsEquipment: `${urlBase}/engineers-flow/create-selected-parts-equipment`,
    createPendingPartsEquipment: `${urlBase}/engineers-flow/create-pending-parts-equipment`,
    createCommentaryToJTR: `${urlBase}/engineers-flow/create-comment-to-jtr`,
    updateStateAssignationUser: `${urlBase}/engineers-flow/update-state-assignation-user`,
    updateJTRAssignationUser: `${urlBase}/engineers-flow/update-jtr-assignation-user`,
    updateFrusAndAmountsSelectedParts: `${urlBase}/engineers-flow/update-frus-and-amounts-selected-parts`,
    updateAssignationUserReturnPlanning: `${urlBase}/engineers-flow/update-assignation-user-return-planning`,
  },
  INVENTORIES: {
    getRequestsInQuotes: `${urlBase}/inventories/find-requests-in-quotes`,
    getInfoRequestInQuote: `${urlBase}/inventories/find-info-request-in-quote`,
    getFilesRequestInQuote: `${urlBase}/inventories/find-files-request-in-quote`,
    getCommentsRequestInQuote: `${urlBase}/inventories/find-comments-request-in-quote`,
    downloadPartsByModelInQuote: `${urlBase}/inventories/download-parts-by-model-in-quote`,
    updateSustituteCostParts: `${urlBase}/inventories/update-sustitutes-and-costs-parts`,
    deactivateReferences: `${urlBase}/inventories/deactivate-references`,
  },
  PRICING: {
    getVariablesMasterData: `${urlBase}/pricing/find-variables-master-data`,
    getLogsMasterDataById: `${urlBase}/pricing/find-logs-master-data-by-id`,
    getOffersRequestById: `${urlBase}/pricing/find-offers-request-by-id`,
    getAjustOfferWithLogById: `${urlBase}/pricing/find-ajust-offer-with-log-by-id`,
    updateVariableMasterDataById: `${urlBase}/pricing/update-variable-master-data-by-id`,
    updateOfferCalcRequestByPricer: `${urlBase}/pricing/update-offer-calc-request-by-pricer`,
    updateManualOfferCalcRequestByPricer: `${urlBase}/pricing/update-manual-offer-calc-request-by-pricer`,
    updateStatePricerListById: `${urlBase}/pricing/update-state-pricer-list-by-id`,
    getRequestOffersInAjustment: `${urlBase}/pricing/find-request-offers-in-ajustment`,
    downloadCalsOfferByType: `${urlBase}/pricing/download-calc-offer-by-type`,
  },
  SUPPORT: {
    getMasterVariablesByMasterTable: `${urlBase}/support/find-master-variables-by-table-master`,
    getAllUserEscalationTss: `${urlBase}/support/find-all-user-escalation-tss`,
    createMasterVariablesByMasterTable: `${urlBase}/support/create-master-variables-by-table-master`,
    createUserEscalationTSS: `${urlBase}/support/create-user-escalation-tss`,
    updateMasterVariablesByMasterTable: `${urlBase}/support/update-master-variables-by-table-master`,
    deactivatedMasterVariablesByMasterTable: `${urlBase}/support/deactivate-user-escalation-tss`,
    deactivatedUserEscalationTss: `${urlBase}/support/deactivate-user-escalation-tss`,
  },
  MANAGERS: {
    getAllInformationDigitalRequest: `${urlBase}/managers/find-all-information-digital-request`,
    findAllDataByDigitalRequest: `${urlBase}/managers/find-all-data-by-digital-request`,
    getAllInformationCardsChart: `${urlBase}/managers/find-all-data-dashboard-digital-request`,
    getInformationYearAndCountries: `${urlBase}/managers/find-years-and-countries-digital-request`,
  },
  MEDICALRECORDS: {
    medicalRecord: `${urlBase}/MR`,
    offices: `${urlBase}/MR/offices`,
    medication: `${urlBase}/MR/medication`,
    deleteMedication: `${urlBase}/MR/delete-medication`,
    medications: `${urlBase}/MR/medications`,
    contact: `${urlBase}/MR/contact`,
    deleteContact: `${urlBase}/MR/delete-contact`,
    contacts: `${urlBase}/MR/contacts`,
    allergies: `${urlBase}/MR/allergies`,
    allergy: `${urlBase}/MR/allergy`,
    deleteAllergy: `${urlBase}/MR/delete-allergy`,
    diseases: `${urlBase}/MR/diseases`,
    disease: `${urlBase}/MR/disease`,
    deleteDisease: `${urlBase}/MR/delete-disease`,
    interventions: `${urlBase}/MR/interventions`,
    intervention: `${urlBase}/MR/intervention`,
    deleteIntervention: `${urlBase}/MR/delete-intervention`,
    findRecordsByRol: `${urlBase}/MR/country`,
    findAllDataAdminDashboard: `${urlBase}/MR/admin-dashboard`,
    othersTypes: `${urlBase}/MR/others/types`,
    others: `${urlBase}/MR/others`,
    other: `${urlBase}/MR/other`,
    deleteOther: `${urlBase}/MR/delete-other`,
    downloadVaccineDataLocation: `${urlBase}/MR/download-vaccine-data-location`,
    getUserWithAccess: `${urlBase}/MR/maintenance/find-user-with-access`,
    createUserAccess: `${urlBase}/MR/maintenance/create-user-access`,
    hide: `${urlBase}/MR/hide`,
    deleteUserAccess: `${urlBase}/MR/maintenance/delete-user-access`,
    attachment: `${urlBase}/MR/attachment`,
    downloadMedicalRecordsAdmin: `${urlBase}/MR/download-medical-records-admin`,
    downloadAttachmentsRecordsAdmin: `${urlBase}/MR/download-attachments-records-admin`,
  },
  FF: {
    getFieldTypes: `${urlBase}/financial/field-types`,
    getDocumentAdministration: `${urlBase}/financial/documents`,
    getCategories: `${urlBase}/financial/categories`,
    createDocument: `${urlBase}/financial/documents`,
    createFieldType: `${urlBase}/financial/field-types`,
    deleteFieldType: `${urlBase}/financial/field-types/delete`,
    getBusinessUnits: `${urlBase}/financial/business-units`,
    getDocumentTemplatesByCategoryBU: `${urlBase}/financial/templates/category/`,
    getDocumentsByCategory: `${urlBase}/financial/documents/category/`,
    createDocumentTemplate: `${urlBase}/financial/templates`,
    getDocumentIDTemplate: `${urlBase}/financial/templates/document/`,
    createRequest: `${urlBase}/financial/requests`,
    updateRequest: `${urlBase}/financial/request`,
    getRequests: `${urlBase}/financial/requests`,
    getRequest: `${urlBase}/financial/request/`,
    positions: `${urlBase}/financial/positions`,
    approvalAction: `${urlBase}/financial/request/approval`,
    templateQuery: `${urlBase}/financial/templates/query`,
    downloadAttachment: `${urlBase}/financial/files`,
    addAttachment: `${urlBase}/financial/files`,
    getUserInformation: `${urlBase}/financial/positions/user`,
    notifications: `${urlBase}/financial/requests/notifications`,
    reminder: `${urlBase}/financial/reminder`,
    away: `${urlBase}/financial/position/away`,
    preventRelaunch: `${urlBase}/financial/relaunch/false`,
    relaunch: `${urlBase}/financial/relaunch/true`,
    removeFile: `${urlBase}/financial/files/remove`,
    getProfile: `${urlBase}/financial/profiles`,
    createProfile: `${urlBase}/financial/profile`,
    updateProfile: `${urlBase}/financial/profile`,
    toggleProfile: `${urlBase}/financial/profile/toggle`,
    getState: `${urlBase}/financial/states`,
    toggleTemplate: `${urlBase}/financial/templates`,
    getMyRequests: `${urlBase}/financial/requests/user`,
    RRAction: `${urlBase}/financial/request/RR`,
    disablePosition: `${urlBase}/financial/position/disable`,
    deleteTemplate: `${urlBase}/financial/templates/delete`,
  },
  FINANCIALFLOWS: {
    getOptions: `${urlBase}/financial/financeFlowsData`,
    getAllUnitType: `${urlBase}/financial/getAllUnitType`,
    insertUnitType: `${urlBase}/financial/addUnitType`,
    updateUnitType: `${urlBase}/financial/updateUnitType`,
    deleteUnitType: `${urlBase}/financial/deleteUnitType`,
    getAllDocument: `${urlBase}/financial/getAllDocument`,
    getDocumentById: `${urlBase}/financial/getDocumentById`,
    insertDocument: `${urlBase}/financial/addDocument`,
    updateDocument: `${urlBase}/financial/updateDocument`,
    getAllProfile: `${urlBase}/financial/getAllProfile`,
    getProfileById: `${urlBase}/financial/getProfileById`,
    updateProfile: `${urlBase}/financial/updateProfile`,
    insertProfile: `${urlBase}/financial/addProfile`,
    deleteProfile: `${urlBase}/financial/deleteProfile`,
    getAllTemplatePosition: `${urlBase}/finance-flows/getAllTemplatePosition`,
    isAway: `${urlBase}/finance-flows/isAway`,
    getUserSign: `${urlBase}/finance-flows/getUserSign`,
    deleteTemplatePosition: `${urlBase}/finance-flows/deleteTemplatePosition`,
    addTemplatePosition: `${urlBase}/finance-flows/addTemplatePosition`,
    getAllProfile: `${urlBase}/finance-flows/getAllProfile`,
    updateTemplatePosition: `${urlBase}/finance-flows/updateTemplatePosition`,
    getProfilesByPositionId: `${urlBase}/finance-flows/getProfilesByPositionId`,
    getAllCategories: `${urlBase}/finance-flows/getAllCategories`,
    getAllDocumentApprovalTemplateByCategoryId: `${urlBase}/finance-flows/getAllDocumentApprovalTemplateByCategoryId`,
    getUnitTypeByDocumentId: `${urlBase}/finance-flows/getUnitTypeByDocumentId`,
    addDocumentApprovalTemplate: `${urlBase}/finance-flows/addDocumentApprovalTemplate`,
    updateDocumentApprovalTemplate: `${urlBase}/finance-flows/updateDocumentApprovalTemplate`,
    deleteDocumentApprovalTemplate: `${urlBase}/finance-flows/deleteDocumentApprovalTemplate`,
    getDocumentField: `${urlBase}/finance-flows/getDocumentFieldById`,
    getRulesAndApproverByTemplate: `${urlBase}/finance-flows/getRulesAndApproverByTemplate`,
    getRequestByUser: `${urlBase}/finance-flows/getRequestByUser`,
    createRequest: `${urlBase}/finance-flows/createRequest`,
    addFiles: `${urlBase}/finance-flows/add-files`,
    deleteFile: `${urlBase}/finance-flows/delete-file`,
    getRequestMyFlow: `${urlBase}/finance-flows/get-request-my-flows`,
    approveRequest: `${urlBase}/financial/approve-request`,
    cancelRequest: `${urlBase}/financial/cancel-request`,
    relaunchRequest: `${urlBase}/financial/relaunch-request`,
    downloadAttachmentByPath: `${urlBase}/financial/download-file`,
    getAllRequest: `${urlBase}/financial/get-all-request`,
    getAccessRequestByUser: `${urlBase}/financial/get-access-request`,
    deleteFileRequest: `${urlBase}/financial/delete-file-request`,
    deleteDocumentById: `${urlBase}/financial/delete-document`,
    getRequestId: `${urlBase}/financial/get-request`,
    getRequests: `${urlBase}/finance-flows/requests`,
    getCategories: `${urlBase}/finance-flows/categories`,
    addAttachment: `${urlBase}/finance-flows/files`,
    approvalAction: `${urlBase}/finance-flows/request/approval`,
    downloadAttachment: `${urlBase}/finance-flows/files`,
    getRequest: `${urlBase}/finance-flows/request`,
    preventRelaunch: `${urlBase}/finance-flows/relaunch/false`,
    relaunch: `${urlBase}/finance-flows/relaunch/true`,
    reminder: `${urlBase}/finance-flows/reminder`,
    removeFile: `${urlBase}/finance-flows/files/remove`,
    RRAction: `${urlBase}/finance-flows/request/RR`,
    getBusinessUnitByUser: `${urlBase}/finance-flows/get-businessUnit-by-user`,
    getAllRequestByRangeDate: `${urlBase}/finance-flows/get-all-request-range-date`,
    updateRevenueStatus: `${urlBase}/finance-flows/update-revenue-status`,
  },
  CONTACTS: {
    getCustomers: `${urlBase}/gbm-contacts/get-customers`,
    getContacts: `${urlBase}/gbm-contacts/get-contacts`,
    getFunctions: `${urlBase}/gbm-contacts/get-function`,
    getDepartaments: `${urlBase}/gbm-contacts/get-departament`,
    getCountrys: `${urlBase}/gbm-contacts/get-country`,
    getHistory: `${urlBase}/gbm-contacts/get-history`,
    updatedContact: `${urlBase}/gbm-contacts/update-contact`,
    createContact: `${urlBase}/gbm-contacts/create-new-contact`,
    confirmContact: `${urlBase}/gbm-contacts/confirm-contact`,
    removeConfirmContact: `${urlBase}/gbm-contacts/delete-confirm-contact`,
    lockContact: `${urlBase}/gbm-contacts/lock-contact`,
    getFileUpdate: `${urlBase}/gbm-contacts/get-file-update`,
    downloadExcel: `${urlBase}/gbm-contacts/download-excel`,
    getStats: `${urlBase}/gbm-contacts/get-user-stats`,
    getManagerStats: `${urlBase}/gbm-contacts/get-manager-stats`,
    getCustomerInfo: `${urlBase}/gbm-contacts/get-customer-stats`,
    reportHistory: `${urlBase}/gbm-contacts/report-history`,
    getConfirmRows: `${urlBase}/gbm-contacts/get-confirm-rows`,
  },
  INCIDENTREPORTS: {
    getServices: `${urlBase}/incidents-reports/get-services`,
    getProducts: `${urlBase}/incidents-reports/get-products`,
    getPlatform: `${urlBase}/incidents-reports/get-platforms`,
    insertData: `${urlBase}/incidents-reports/insert-data`,
    getData: `${urlBase}/incidents-reports/get-data`,
    removeItem: `${urlBase}/incidents-reports/delete-data`,
  },
  DOCUMENTSY: {
    getRows: `${urlBase}/document-system/request-rows`,
    getOptions: `${urlBase}/document-system/options`,
    getLog: `${urlBase}/document-system/logs`,
    newRequest: `${urlBase}/document-system/new-request`,
    updateRequest: `${urlBase}/document-system/update-request`,
    downLoadAllFiles: `${urlBase}/document-system/download-all-files`,
    getCustomers: `${urlBase}/document-system/get-customers`,
    deleteFile: `${urlBase}/document-system/delete-file`,
    deleteAllFiles: `${urlBase}/document-system/delete-all-files`,
    getFilesByCustomer: `${urlBase}/document-system/get-files-customer`,
    reporting: `${urlBase}/document-system/get-report-rows`,
    getReportColumns: `${urlBase}/document-system/get-report-columns`,
    getReports: `${urlBase}/document-system/get-reports`,
    deleteReport: `${urlBase}/document-system/delete-report`,
    saveReport: `${urlBase}/document-system/save-report`,
  },
  SECOH: {
    getAllContractsOnHold: `${urlBase}/secoh/find-all-contracts-on-hold`,
    getAllDataMasterByStatus: `${urlBase}/secoh/find-all-data-master-by-status`,
    getAllStatusAvailable: `${urlBase}/secoh/find-all-status-available`,
    getActivityLogsByContractID: `${urlBase}/secoh/find-activity-logs-by-contract-id`,
    getUsersNotificationMatrix: `${urlBase}/secoh/find-users-notification-matrix`,
    getUsersEscalationMatrix: `${urlBase}/secoh/find-users-escalation-matrix`,
    getDashboardContractsOnHoldByType: `${urlBase}/secoh/find-dashboard-contracts-on-hold-by-type`,
    getContractGraphDetail: `${urlBase}/secoh/find-contract-graph-detail`,
    getContractGraphDetailByCountry: `${urlBase}/secoh/find-contract-graph-detail-by-country`,
    createActivityLogByContractOnHoldID: `${urlBase}/secoh/create-activity-log-by-contract-on-hold-id`,
    createUserNotification: `${urlBase}/secoh/create-user-notification`,
    createUserEscalation: `${urlBase}/secoh/create-user-escalation`,
    createStatusContractOnHold: `${urlBase}/secoh/create-status-contract-on-hold`,
    createDataMaster: `${urlBase}/secoh/create-data-master`,
    updateStatusContractOnHoldByID: `${urlBase}/secoh/update-status-contract-on-hold-by-id`,
    updateTargetStartDateContractOnHoldByID: `${urlBase}/secoh/update-target-start-date-contract-on-hold-by-id`,
    updateUserEscalationNotification: `${urlBase}/secoh/update-user-escalation-notification-by-id`,
    updateUserEscalationByID: `${urlBase}/secoh/update-user-escalation-by-id`,
    updateStatusByID: `${urlBase}/secoh/update-status-by-id`,
    deactivateUserEscalationNotificationByID: `${urlBase}/secoh/deactivate-user-escalation-notification-by-id`,
    deactivateUserEscalationByID: `${urlBase}/secoh/deactivate-user-escalation-by-id`,
    deactivateStatusByID: `${urlBase}/secoh/deactivate-status-by-id`,
    updateDataMasterById: `${urlBase}/secoh/update-data-master-by-id`,
    updateStatusDataMasterByID: `${urlBase}/secoh/update-status-data-master-by-id`,
    validateGbmCollaborator: `${urlBase}/secoh/validate-gbm-collaborator`,
    downloadContractsOnHold: `${urlBase}/secoh/download-contracts-on-hold`,
    getAllUsersFMandPM: `${urlBase}/secoh/find-all-users-fm-and-pm`,
    createUserFMPMAssign: `${urlBase}/secoh/create-user-fm-pm-assign`,
    getAllUsersAccessOnHold: `${urlBase}/secoh/find-all-users-access-on-hold`,
    deactivateAccessUserOnHoldByID: `${urlBase}/secoh/deactivate-access-user-on-hold-by-id`,
    createAccessUserOnHold: `${urlBase}/secoh/create-access-user-on-hold`,
    editAccessUserOnHold: `${urlBase}/secoh/edit-access-user-on-hold`,
    createContractException: `${urlBase}/secoh/create-contract-exception`,
  },
  HCMHIRING: {
    getOptions: `${urlBase}/hcm-hiring/options`,
    getRows: `${urlBase}/hcm-hiring/request-rows`,
    newRequest: `${urlBase}/hcm-hiring/new-request`,
    updateRequest: `${urlBase}/hcm-hiring/update-request`,
    deleteFile: `${urlBase}/hcm-hiring/delete-file`,
    deleteAllFiles: `${urlBase}/hcm-hiring/delete-all-files`,
    getCandidateInfo: `${urlBase}/hcm-hiring/get-candidate-info`,
    getPositionInfo: `${urlBase}/hcm-hiring/get-position-info`,
    changeStatusBot: `${urlBase}/hcm-hiring/change-status-bot`,
  },
  PANAMABIDS: {
    getMasterData: `${urlBase}/panama-bids/get-data`,
    getEntities: `${urlBase}/panama-bids/get-entities`,
    createNewEntity: `${urlBase}/panama-bids/insert-entity`,
    updateEntity: `${urlBase}/panama-bids/update-entity`,
    getContacts: `${urlBase}/panama-bids/get-contacts`,
    getPurchaseOrderMacro: `${urlBase}/panama-bids/get-purchaseOrderMacro`,
    getPurchaseOrderProduct: `${urlBase}/panama-bids/get-purchaseOrderMacro-products`,
    getProductsPB: `${urlBase}/panama-bids/get-products`,
    getColumsPurchaseOrderProduct: `${urlBase}/panama-bids/get-colums-purchaseOrderMacro`,
    getDataEntities: `${urlBase}/panama-bids/get-data-entities`,
    updatePurchaseOrderMacro: `${urlBase}/panama-bids/update-purchaseOrderMacro`,
    getPurchaseOrderCompetition: `${urlBase}/panama-bids/get-competitions`,
    getCompetitionProducts: `${urlBase}/panama-bids/get-competitions-products`,
    getColumsCompetition: `${urlBase}/panama-bids/get-colums-competition`,
    getAllDeliveryMethod: `${urlBase}/panama-bids/get-deliveryMethod`,
    getAllFastCotyzationReport: `${urlBase}/panama-bids/get-fastCotyzationReport`,
    getAllProductsFastCotyzation: `${urlBase}/panama-bids/get-productsFastCotyzation`,
    getColumsFastCotyzationReport: `${urlBase}/panama-bids/get-colums-fastCotyzationReport`,
    updateFastCotyzationReport: `${urlBase}/panama-bids/update-fastCotyzationReport`,
    deleteEntity: `${urlBase}/panama-bids/delete-entity`,
    updateGBMStatusFastCotyzation: `${urlBase}/panama-bids/update-fastCotyzationReport-status`,
    getFilesById: `${urlBase}/panama-bids/get-all-files`,
    getDataReport: `${urlBase}/panama-bids/data-report`,
    getDataCompetitionReport: `${urlBase}/panama-bids/data-competition-report`,
  },
  COSTARICABIDS: {
    getMasterData: `${urlBase}/costa-rica-bids/get-data`,
    getPurchaseOrder: `${urlBase}/costa-rica-bids/get-purchase-order`,
    updatePurchaseOrder: `${urlBase}/costa-rica-bids/update-purchase-order`,
    getContacts: `${urlBase}/costa-rica-bids/get-contacts`,
    deleteFile: `${urlBase}/costa-rica-bids/delete-file`,
    deleteAllFiles: `${urlBase}/costa-rica-bids/delete-all-files`,
    getProducts: `${urlBase}/costa-rica-bids/get-products`,
    getFilesByPurchaseOrder: `${urlBase}/costa-rica-bids/get-files-purchase-order`,
    getExcelReport: `${urlBase}/costa-rica-bids/get-excel-report`,
    deleteSalesTeam: `${urlBase}/costa-rica-bids/insert-salesTeam`,
    insertSalesTeam: `${urlBase}/costa-rica-bids/delete-salesTeam`,
  },
  EXITINTERVIEW: {
    getMasterData: `${urlBase}/exit-interview/get-data`,
    getInterview: `${urlBase}/exit-interview/get-interviews`,
    getInterviewComplete: `${urlBase}/exit-interview/get-interviews-complete`,
    getDraftInterview: `${urlBase}/exit-interview/get-draft-interviews`,
    getDraftInterviewStatus: `${urlBase}/exit-interview/get-draft-interviews-status`,
    insertExitType: `${urlBase}/exit-interview/insert-exitType`,
    insertExitReason: `${urlBase}/exit-interview/insert-exitReason`,
    changeStatusExitType: `${urlBase}/exit-interview/change-status-exitType`,
    changeStatusExitReason: `${urlBase}/exit-interview/change-status-exitReason`,
    getDataChartCountry: `${urlBase}/exit-interview/get-data-chart-contry`,
    getDataChartExitType: `${urlBase}/exit-interview/get-data-charts-exitType`,
    getDataChatExitReasons: `${urlBase}/exit-interview/get-data-charts-exitReason`,
    insertInterview: `${urlBase}/exit-interview/insert-interview`,
    // insertDraftInterview: `${urlBase}/exit-interview/insert-draftInterview`,
    getDataChartWords: `${urlBase}/exit-interview/get-data-chart-words`,
    // updateDraftInterview: `${urlBase}/exit-interview/update-DraftInterview`,
    deleteDraftInterview: `${urlBase}/exit-interview/delete-DraftInterview`,
    draftInterviewToInterview: `${urlBase}/exit-interview/draftInterview-to-interview`,
    getInfoByUser: `${urlBase}/exit-interview/get-info-userID`,
    sendEmailToUser: `${urlBase}/exit-interview/send-email-user`,
    sendEmailToHCM: `${urlBase}/exit-interview/send-email-hcm`,
    updateInterview: `${urlBase}/exit-interview/update-Interview`,
    getCountriesByHCM: `${urlBase}/exit-interview/get-countries-hcm`,
    inserUserRole: `${urlBase}/exit-interview/insert-user-role`,
    inactiveRole: `${urlBase}/exit-interview/inactive-user-role`,
    updateUserRole: `${urlBase}/exit-interview/update-user-role`,
    getDataCardsHistory: `${urlBase}/exit-interview/get-cards-history`,
    getDataChartDraft: `${urlBase}/exit-interview/get-chart-draft`,
  },
  MASTERDATA: {
    getOptions: `${urlBase}/master-data/options`,
    getRows: `${urlBase}/master-data/request-rows`,
    getLinealAndMasiveData: `${urlBase}/master-data/lineal-masive-request`,
    downloadTemplates: `${urlBase}/master-data/download-template`,
    downloadDocument: `${urlBase}/master-data/download-document`,
    getApprovalsRows: `${urlBase}/master-data/request-approvals-rows`,
    approvalRejectRequest: `${urlBase}/master-data/approval-reject-request`,
    uploadFiles: `${urlBase}/master-data/upload-files`,
    deleteFile: `${urlBase}/master-data/delete-file`,
    deleteFileByTempId: `${urlBase}/master-data/delete-file-tempId`,
    getEmployees: `${urlBase}/master-data/get-employees`,
    existMasterDataByKey: `${urlBase}/master-data/exist-masterdata`,
    newRequest: `${urlBase}/master-data/new-request`,
    getAllRows: `${urlBase}/master-data/request-all-rows`,
    getQuantityAllRows: `${urlBase}/master-data/get-quantity-all-rows`,
    updateStatus: `${urlBase}/master-data/update-status`,
    getApprovers: `${urlBase}/master-data/approvers`,
    getMotherTables: `${urlBase}/master-data/get-mother-tables`,
    deleteApprovers: `${urlBase}/master-data/delete-approvers`,
    uploadTemplate: `${urlBase}/master-data/upload-template`,
    deleteTemplate: `${urlBase}/master-data/delete-template`,
    saveTemplate: `${urlBase}/master-data/save-template`,
    getMaintenanceTableInfo: `${urlBase}/master-data/get-maintenance-info`,
    maintenanceAction: `${urlBase}/master-data/maintenance-action`,
    getFormFields: `${urlBase}/master-data/get-form-fields`,
    newApprovers: `${urlBase}/master-data/new-approvers`,
    getOptionsMaintenanceView: `${urlBase}/master-data/options-maintenance-view`,
    getGeneralInfo: `${urlBase}/master-data/get-general-info`,
  },
  AUTOPPLDRS: {
    getOptions: `${urlBase}/autopp-ldrs/options`,
    getRows: `${urlBase}/autopp-ldrs/request-rows`,
    getBAWRows: `${urlBase}/autopp-ldrs/requestBAW-rows`,
    getLdrsOptions: `${urlBase}/autopp-ldrs/ldrs-data`,
    getLdrFields: `${urlBase}/autopp-ldrs/ldrs-fields`,
    getContacts: `${urlBase}/autopp-ldrs/get-contacts`,
    getCostumers: `${urlBase}/autopp-ldrs/get-costumers`,
    getEmployees: `${urlBase}/autopp-ldrs/get-employees`,
    newRequest: `${urlBase}/autopp-ldrs/new-request`,
    newBawDevolutionReq: `${urlBase}/autopp-ldrs/new-BawDevolutionReq`,
    uploadFiles: `${urlBase}/autopp-ldrs/upload-files`,
    deleteFile: `${urlBase}/autopp-ldrs/delete-file`,
    downloadBOM: `${urlBase}/autopp-ldrs/download-bom`,
    deleteFolderBOM: `${urlBase}/autopp-ldrs/delete-folder-bom`,

    //AutoppCare
    getAutoppCareOptions: `${urlBase}/autopp-ldrs/autoppcare-options`,
    newLDRForm: `${urlBase}/autopp-ldrs/new-ldr-form`,
    deleteLDR: `${urlBase}/autopp-ldrs/delete-ldr`,
    getLdrForms: `${urlBase}/autopp-ldrs/ldrs-forms`,
    deleteBrand: `${urlBase}/autopp-ldrs/delete-brand`,
    updateBrand: `${urlBase}/autopp-ldrs/update-brand`,
    newBrandLDR: `${urlBase}/autopp-ldrs/new-brand-ldr`,
  },
  DATABOT: {
    getDatabotOptions: `${urlBase}/databot/options`,
    getDatabotAreas: `${urlBase}/databot/get-areas`,
    getBots: `${urlBase}/databot/get-bots`,
    getBotDetails: `${urlBase}/databot/get-bot-details`,
    getBotLogOrDetailsServerSide: `${urlBase}/databot/get-bot-log-details-svs`,
    getMandantes: `${urlBase}/databot/get-mandantes`,
    turnBot: `${urlBase}/databot/turn-bot`,
    turnMand: `${urlBase}/databot/turn-mand`,
    getGraphsData: `${urlBase}/databot/get-graphs`,
    newBot: `${urlBase}/databot/new-bot`,
    getTopSavingBots: `${urlBase}/databot/get-top-saving-bots`,
    activateBot: `${urlBase}/databot/activate-bot`,
    deleteBot: `${urlBase}/databot/delete-bot`,
  },
  KNOWEMPLOYEE: {
    getMaritalStatus: `${urlBase}/know-employee/find-marital-status`,
    getRelationships: `${urlBase}/know-employee/find-relationships`,
    getPersonalInfoByEmployee: `${urlBase}/know-employee/find-personal-info-by-employee`,
    getPersonalInfoCompleteByEmployee: `${urlBase}/know-employee/find-personal-info-complete-by-employee`,
    getFamilyGroupByEmployee: `${urlBase}/know-employee/find-family-group-by-employee`,
    getEmergencyContactsByEmployee: `${urlBase}/know-employee/find-emergency-contacts-by-employee`,
    getAllPersonalInfoEmployeeForms: `${urlBase}/know-employee/find-all-personal-info-employee-forms`,
    loadEmergencyContactsByEmployee: `${urlBase}/know-employee/load-emergency-contacts-by-employee`,
    createFamilyGroupByEmployee: `${urlBase}/know-employee/create-family-group-by-employee`,
    createEmergencyContactByEmployee: `${urlBase}/know-employee/create-emergency-contact-by-employee`,
    completePersonalInfoByEmployee: `${urlBase}/know-employee/complete-personal-info-by-employee`,
    deactivatedFamilyGroupByID: `${urlBase}/know-employee/deactivated-family-group-by-id`,
    deactivatedEmergencyContactByID: `${urlBase}/know-employee/deactivated-emergency-contact-by-id`,
  },
  CONFLICTINTERESTS: {
    getConflictInterestsPendingInfo: `${urlBase}/conflict-interests/find-conflict-interests-pending-info`,
    getAllConflictInterestsInfo: `${urlBase}/conflict-interests/find-all-conflict-interests-info`,
    createConflictInterestsInfoByID: `${urlBase}/conflict-interests/create-conflict-interests-info-by-id`,
    getConflictInterestsInfo: `${urlBase}/conflict-interests/find-conflict-interests-info`,
    createSupplierConflictInterestsByID: `${urlBase}/conflict-interests/create-supplier-conflict-interests-by-id`,
  },
  FREELANCE: {
    getOptions: `${urlBase}/freelance/options`,
    getRows: `${urlBase}/freelance/rows`,
    getPoInfo: `${urlBase}/freelance/get-po-info`,
    newPoAssignation: `${urlBase}/freelance/new-po-assignation`,
    newResponsible: `${urlBase}/freelance/new-responsible`,
    inactivePo: `${urlBase}/freelance/inactive-po`,
    reportHoursActions: `${urlBase}/freelance/report-actions`,
    approveMass: `${urlBase}/freelance/approve-mass`,
    hesActions: `${urlBase}/freelance/hes-actions`,
    downloadFile: `${urlBase}/freelance/download-file`,
    downloadFileByName: `${urlBase}/freelance/download-file-by-name`,
    billsActions: `${urlBase}/freelance/bills-actions`,
    getFinanceEmails: `${urlBase}/freelance/get-finance-emails`,
    blockAccess: `${urlBase}/freelance/block-access`,
    deleteAccess: `${urlBase}/freelance/delete-access`,
    newPass: `${urlBase}/freelance/new-pass`,
    updateAccessFreelance: `${urlBase}/freelance/update-access-freelance`,
    newProject: `${urlBase}/freelance/new-project`,
    blockProject: `${urlBase}/freelance/block-project`,
    newApprover: `${urlBase}/freelance/new-approver`,
    blockApprover: `${urlBase}/freelance/block-approver`,
    updateEmailBilling: `${urlBase}/freelance/update-email-billing`,
    getInfoReport: `${urlBase}/freelance/get-info-report`,
    deleteFile: `${urlBase}/freelance/delete-file`,
    deleteFileActa: `${urlBase}/freelance/delete-file-acta`,
    deleteAllFiles: `${urlBase}/freelance/delete-all-files`,
    newActa: `${urlBase}/freelance/new-acta`,
    getActaFile: `${urlBase}/freelance/get-acta`,
    uploadFile: `${urlBase}/freelance/upload-files`,
    getReportStatus: `${urlBase}/freelance/get-report-status`,
    getSubcontractInfo: `${urlBase}/freelance/get-subcontract`,
    approvedAltaRequest: `${urlBase}/freelance/approved-alta-request`,
    sendAltaRequest: `${urlBase}/freelance/send-alta-request`,
    deleteAltaFile: `${urlBase}/freelance/delete-alta-file`,
    uploadAltaFile: `${urlBase}/freelance/upload-alta-file`,
    downloadAltaFile: `${urlBase}/freelance/download-alta-file`,
    downloadFileTemplate: `${urlBase}/freelance/download-alta-template`,
  },
  BUSINESSSYSTEM: {
    getData: `${urlBase}/business-system/get-data`,
    getOrdersAssign: `${urlBase}/business-system/get-order-assign`,
    getReportUS: `${urlBase}/business-system/get-report-us`,
    getReportFlow: `${urlBase}/business-system/get-flow`,
    updateRequest: `${urlBase}/business-system/update-request`,
    insertRequest: `${urlBase}/business-system/insert-request`,
    getCountriesByHCM: `${urlBase}/business-system/get-countries-hcm`,
    inserUserRole: `${urlBase}/business-system/insert-user-role`,
    inactiveRole: `${urlBase}/business-system/inactive-user-role`,
    updateUserRole: `${urlBase}/business-system/update-user-role`,
  },
  ITREQUESTS: {
    getEmployeeRequest: `${urlBase}/itrequests/get-employee`,
    getCountryUpdateList: `${urlBase}/itrequests/get-country-update-list`,
    getIdRequest: `${urlBase}/itrequests/get-id-request`,
    getOptions: `${urlBase}/itrequests/get-options`,
    getOpenRequests: `${urlBase}/itrequests/get-open-requests`,
    getStepConfig: `${urlBase}/itrequests/get-steps`,
    getSearchRequests: `${urlBase}/itrequests/get-search-requests`,
    requestData: `${urlBase}/itrequests/insert-request`,
    getRoles: `${urlBase}/itrequests/get-roles`,
    getExceptions: `${urlBase}/itrequests/get-exceptions`,
    checkColabId: `${urlBase}/itrequests/get-check-colab-id`,
    getSearchRequestFile: `${urlBase}/itrequests/get-search-request-file`,
    cancelRequest: `${urlBase}/itrequests/update-cancel-request`,
    getDistinctUsers: `${urlBase}/itrequests/get-distinct-users`,
    addMatrixRole: `${urlBase}/itrequests/insert-matrix-role`,
    addMatrixException: `${urlBase}/itrequests/insert-matrix-exception`,
    deleteMatrixRole: `${urlBase}/itrequests/delete-matrix-role`,
    deleteMatrixException: `${urlBase}/itrequests/delete-matrix-exception`,
  },
  HOURSREPORT: {
    getCatsTypes: `${urlBase}/cats-reports/find-task-types`,
    createCats: `${urlBase}/cats-reports/create-cats-record`,
    getCats: `${urlBase}/cats-reports/find-cats-records`,
    getOSNetworks: `${urlBase}/os-networks-reports/find-all-reports`,
    deleteOSNetworks: `${urlBase}/os-networks-reports/delete-report`,
    getOS: `${urlBase}/os-networks-reports/find-os-reports-byDate`,
    createOSNetworks: `${urlBase}/os-networks-reports/create-hours-report`,
    checkOSNetworks: `${urlBase}/os-networks-reports/find-report-verification`,
    getAllNoMrsReports: `${urlBase}/os-networks-reports/find-no-mrs-reports`,
    getExtraHours: `${urlBase}/extra-hours-request/find-extra`,
    updateVisibilityExtraHours: `${urlBase}/extra-hours-request/update-visibility`,
    createExtraHours: `${urlBase}/extra-hours-request/create-extra`,
    getJustifications: `${urlBase}/extra-hours-request/find-justifications`,
    getSchedule: `${urlBase}/extra-hours-request/find-user-schedule`,
    verifyApprover: `${urlBase}/extra-hours-request/find-pre-approver`,
    validateTimeExtra: `${urlBase}/extra-hours-request/validate-time-report`,
    getRequestPendingForApproval: `${urlBase}/extra-hours-request/find-reports-pending`,
    setExtraPreApprover: `${urlBase}/extra-hours-request/update-pre-approver-status`,
    setExtraCEO: `${urlBase}/extra-hours-request/update-request-status`,
    getPreapprovers: `${urlBase}/extra-hours-request/find-preapprovers`,
  },
  USERCLOUDREQUEST: {
    getAllUserMatrix: `${urlBase}/user-cloud-request/get-all-user-matrix`,
    createRequest: `${urlBase}/user-cloud-request/create-request`,
    deleteFile: `${urlBase}/user-cloud-request/delete-file`,
    deleteApproveFile: `${urlBase}/user-cloud-request/delete-approve-file`,
    approveRequest: `${urlBase}/user-cloud-request/approve-cloud-request`,
    getAllRequest: `${urlBase}/user-cloud-request/get-all-request`,
    getDeclineRequest: `${urlBase}/user-cloud-request/decline-cloud-request`,
    getReturnRequest: `${urlBase}/user-cloud-request/return-cloud-request`,
    getAllTenant: `${urlBase}/user-cloud-request/get-all-tenant`,
    createAccessMatriz: `${urlBase}/user-cloud-request/create-access-matriz`,
    deleteAccessMatriz: `${urlBase}/user-cloud-request/delete-access-matriz`,
    updateAccessMatriz: `${urlBase}/user-cloud-request/update-access-matriz`,
    getAccessMAtrizHistory: `${urlBase}/user-cloud-request/get-access-matriz-hisotry`,
    downloadVersionMatriz: `${urlBase}/user-cloud-request/download-version-matriz`,
    getCountries: `${urlBase}/user-cloud-request/get-countries`,
    getAllCloudVendor: `${urlBase}/user-cloud-request/get-all-cloud-vendor`,
    createCloudVendor: `${urlBase}/user-cloud-request/create-cloud-provider`,
    updateCloudVendor: `${urlBase}/user-cloud-request/update-cloud-provider`,
    deleteCloudVendor: `${urlBase}/user-cloud-request/delete-cloud-provider`,
    createTenant: `${urlBase}/user-cloud-request/create-tenant`,
    updateTenant: `${urlBase}/user-cloud-request/update-tenant`,
    deleteTenant: `${urlBase}/user-cloud-request/delete-tenant`,
    getMyRequest: `${urlBase}/user-cloud-request/get-my-request`,
    getTenantsByCloud: `${urlBase}/user-cloud-request/get-tenants-by-cloud`,
    downloadFileRequest: `${urlBase}/user-cloud-request/download-file-request`,
    updateRequest: `${urlBase}/user-cloud-request/update-request`,
  },
};
